import { CartProductSchema, ExchangeRateEntity } from '@/graphql/generated'

import { CurrencyType } from '@/types/baseTypes'

import { newPriceWithExchangeRates } from '@/utils/newPriceWithExchangeRates'

export const getResultTotalCart = (
  products: CartProductSchema[],
  currentCurrency: CurrencyType,
  exchangeRatesData: ExchangeRateEntity[],
): string | number => {
  return products
    .reduce((acc, curV) => {
      return (
        newPriceWithExchangeRates(
          curV.total / curV.quantity,
          curV.product.baseCurrency.name as CurrencyType,
          currentCurrency,
          exchangeRatesData,
        ) *
          curV.quantity +
        acc
      )
    }, 0)
    .toFixed(2)
}
