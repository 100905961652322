import SvgIcon from '@mui/material/SvgIcon'
import React from 'react'

import { palette } from '@/theme/palette'

import { IconsTypes } from '@/types/baseTypes'

export const ElectronicsIcon = ({ color = palette.ui.grey[600] }: IconsTypes) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 1.25C9.41421 1.25 9.75 1.58579 9.75 2V3.25H14.25V2C14.25 1.58579 14.5858 1.25 15 1.25C15.4142 1.25 15.75 1.58579 15.75 2V3.25H18C19.5188 3.25 20.75 4.48122 20.75 6V8.25H22C22.4142 8.25 22.75 8.58579 22.75 9C22.75 9.41421 22.4142 9.75 22 9.75H20.75V13.25H22C22.4142 13.25 22.75 13.5858 22.75 14C22.75 14.4142 22.4142 14.75 22 14.75H20.75V18C20.75 19.5188 19.5188 20.75 18 20.75H15.75V22C15.75 22.4142 15.4142 22.75 15 22.75C14.5858 22.75 14.25 22.4142 14.25 22V20.75H9.70732C9.73496 20.8282 9.75 20.9123 9.75 21V22C9.75 22.4142 9.41421 22.75 9 22.75C8.58579 22.75 8.25 22.4142 8.25 22V21C8.25 20.9123 8.26504 20.8282 8.29268 20.75H6C4.48122 20.75 3.25 19.5188 3.25 18V14.75H2C1.58579 14.75 1.25 14.4142 1.25 14C1.25 13.5858 1.58579 13.25 2 13.25H3.25V9.75H2C1.58579 9.75 1.25 9.41421 1.25 9C1.25 8.58579 1.58579 8.25 2 8.25H3.25V6C3.25 4.48122 4.48122 3.25 6 3.25H8.25V2C8.25 1.58579 8.58579 1.25 9 1.25ZM6 4.75C5.30964 4.75 4.75 5.30964 4.75 6V18C4.75 18.6904 5.30964 19.25 6 19.25H18C18.6904 19.25 19.25 18.6904 19.25 18V6C19.25 5.30964 18.6904 4.75 18 4.75H6ZM8.25 9C8.25 8.58579 8.58579 8.25 9 8.25H15C15.4142 8.25 15.75 8.58579 15.75 9V15C15.75 15.4142 15.4142 15.75 15 15.75H9C8.58579 15.75 8.25 15.4142 8.25 15V9ZM9.75 9.75V14.25H14.25V9.75H9.75Z"
        fill={color}
      />
    </SvgIcon>
  )
}
