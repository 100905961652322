export const messages = {
  INVALID_MAIL: {
    EN: 'The email address is incorrect',
    AR: 'عنوان البريد الإلكتروني غير صحيح',
  },
  REQUIRED: {
    EN: 'This field is required',
    AR: 'هذه الخانة مطلوبه',
  },
  PASSWORD_MATCH: {
    EN: 'Password does not match',
    AR: 'كلمة السر غير متطابقة',
  },
}
