import React from 'react'

import * as S from './Badge.styled'

interface Props {
  value: string | number
}

export const Badge = ({ value = '0' }: Props) => {
  if (!value) return null
  return (
    <S.BadgeWrapper>
      <S.Badge>{value.toString()}</S.Badge>
    </S.BadgeWrapper>
  )
}
