import { DefaultTFuncReturn } from 'i18next'
import React, { useState } from 'react'

import { Snackbar } from '@/components'

type Props = {
  children: React.ReactNode
}

type ContentT = {
  title?: string | DefaultTFuncReturn
  subTitle?: string | DefaultTFuncReturn
  error?: boolean
}

type ValueT = {
  content: ContentT
  open: boolean
  onOpenAlert: (values: ContentT) => void
  onCloseAlert: () => void
}

export const AlertContext = React.createContext<ValueT>({
  open: false,
  content: {
    title: '',
    subTitle: '',
    error: false,
  },
  onOpenAlert: () => {},
  onCloseAlert: () => {},
})

export const useAlertContext = () => {
  const alertContext = React.useContext(AlertContext)

  if (!alertContext) {
    throw new Error('useAlertContext must be used within a AlertProvider')
  }
  return alertContext
}

export const AlertProvider = ({ children }: Props) => {
  const [open, setOpen] = useState(false)
  const [content, setContent] = useState<ContentT>({
    title: '',
    subTitle: '',
    error: false,
  })

  const onOpenAlert = (values: object) => {
    setOpen(true)
    setContent(values)
  }

  const onCloseAlert = () => {
    setOpen(false)
  }

  const value = {
    open,
    content,
    onOpenAlert,
    onCloseAlert,
  }

  return (
    <AlertContext.Provider value={value}>
      {children}
      <Snackbar />
    </AlertContext.Provider>
  )
}
