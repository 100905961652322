import { SvgIcon } from '@mui/material'
import React from 'react'

import { palette } from '@/theme/palette'

import { IconsTypes } from '@/types/baseTypes'

export const MinusIcon = ({ size = '14' }: IconsTypes) => {
  return (
    <SvgIcon width={size} height={size} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.91699 7H11.0837"
        stroke={palette.ui.grey[600]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}
