import { styled } from '@mui/material'
import Box from '@mui/material/Box'

export const ProfileContentHeader = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

export const TypographyLogo = styled(Box)(() => ({
  cursor: 'pointer',
  display: 'inline-flex',
  gap: '7px',
  alignItems: 'center',
}))
