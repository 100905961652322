import { Divider, IconButton, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import React, { useState } from 'react'

import { BASE_URL } from '@/constants/env'

import { useGetVoucherCodeLazyQuery } from '@/graphql/generated/index'

import { useCopyText, useLaptop } from '@/hooks'

import { ClipboardCopyIcon, EyeOffIcon } from '@/icons'

import * as S from './MyOrdersRow.styled'

interface Props {
  name: string
  description: string
  date: string
  voucherCode?: string
  imgToken: string | null | undefined
  isVisibleRefund?: boolean
  orderSkuId?: number
  orderId?: number
  handleToggleCodeDisplay?: (orderSkuId: number, orderId: number) => void
  onClick: () => void
  status?: string
  voucherCodeId?: string
}

export const MyOrdersRow = ({
  name,
  description,
  date,
  imgToken,
  isVisibleRefund,
  orderSkuId,
  orderId,
  handleToggleCodeDisplay,
  onClick,
  status,
  voucherCodeId,
}: Props) => {
  const { isOverLaptop } = useLaptop()
  const { copyText } = useCopyText()
  const { t } = useTranslation()

  const theme = useTheme()

  const isRefund = status === 'REFUND_REQUESTED'

  // console.log('status', status);
  // console.log(isVisibleRefund);

  const [getVoucherCodeLQ] = useGetVoucherCodeLazyQuery({
    onCompleted: data => {
      setCode(data.getVoucherCode.code)
      handleToggleCodeDisplay && handleToggleCodeDisplay(Number(orderSkuId), Number(orderId))
    },
  })

  // ! imitation to show code
  const [code, setCode] = useState('')

  const showCode = () => {
    if (isRefund) return
    getVoucherCodeLQ({
      variables: {
        getVoucherCodeId: String(voucherCodeId),
      },
    })
  }
  // ! to show code

  const copyToClipboard = async () => {
    return copyText(code, t`app.profilePage.text_copied`)
  }

  return (
    <S.ProductRow>
      <S.ProductCell>
        <S.ProductImageBlock>
          {imgToken && (
            <Image
              alt=""
              src={`${BASE_URL}/uploads/images/products/${imgToken}`}
              width={1}
              height={1}
              priority={true}
              unoptimized={true}
            />
          )}
        </S.ProductImageBlock>
        <S.DescriptionBlock>
          <Typography variant="body1">{name}</Typography>
          <Typography color={theme.palette.ui.grey[600]} variant="body2">
            {description}
          </Typography>
        </S.DescriptionBlock>
      </S.ProductCell>
      <S.DateCell>
        {!isOverLaptop ? (
          <Typography component="span" variant="body2" color={theme.palette.ui.grey[600]}>
            {t('app.cart.date')}
          </Typography>
        ) : null}
        <S.TypographyDate variant="body2">
          {date}
          {/* {!isOverLaptop && !isRefund ? (
            <RefundLink onClick={onClick}>
              {t('app.profilePage.refund')}
            </RefundLink>
          ) : null} */}
        </S.TypographyDate>
      </S.DateCell>
      <S.VoucherCodeWrapper>
        <S.VoucherCodeField>
          <Typography variant="body2" color={theme.palette.ui.grey[600]}>
            {code || '•••• - •••• - ••••'}
          </Typography>
          <IconButton
            size="small"
            onClick={!code ? showCode : copyToClipboard}
            sx={{
              padding: '5px',
              '&:hover': {
                background: 'rgba(150,160,181,0.2)',
                transition: '.3s',
              },
            }}
          >
            {code ? <ClipboardCopyIcon /> : <EyeOffIcon />}
          </IconButton>
        </S.VoucherCodeField>
      </S.VoucherCodeWrapper>
      {!isOverLaptop && <Divider sx={{ margin: '8px 0' }} />}

      <S.RefundCell visibility={isVisibleRefund && !isRefund ? 'visible' : 'hidden'}>
        <S.RefundLink onClick={onClick}>{t('app.profilePage.refund')}</S.RefundLink>
      </S.RefundCell>
    </S.ProductRow>
  )
}
