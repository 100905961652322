import SvgIcon from '@mui/material/SvgIcon'
import React from 'react'

import { palette } from '@/theme/palette'

import { IconsTypes } from '@/types/baseTypes'

export const SearchIcon = ({ color = palette.ui.grey[600] }: IconsTypes) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 3.75C6.99594 3.75 3.75 6.99594 3.75 11C3.75 15.0041 6.99594 18.25 11 18.25C12.9606 18.25 14.7395 17.4717 16.0445 16.2073C16.0669 16.1767 16.092 16.1474 16.1197 16.1197C16.1474 16.092 16.1767 16.0669 16.2073 16.0445C17.4717 14.7395 18.25 12.9606 18.25 11C18.25 6.99594 15.0041 3.75 11 3.75ZM17.6949 16.6342C18.9773 15.112 19.75 13.1462 19.75 11C19.75 6.16751 15.8325 2.25 11 2.25C6.16751 2.25 2.25 6.16751 2.25 11C2.25 15.8325 6.16751 19.75 11 19.75C13.1462 19.75 15.112 18.9773 16.6342 17.6949L20.4697 21.5303C20.7626 21.8232 21.2374 21.8232 21.5303 21.5303C21.8232 21.2374 21.8232 20.7626 21.5303 20.4697L17.6949 16.6342Z"
        fill={color}
      />
    </SvgIcon>
  )
}
