import * as React from 'react'
import { FormLabel, MenuItem, Select, SxProps, Typography, useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import { useField, useFormikContext } from 'formik'
import { DefaultTFuncReturn } from 'i18next'
import Image from 'next/image'

import { ChevronDownIcon } from '@/icons'

import * as S from './CountrySelect.styled'
import { ErrorMessage } from '../TextFieldFormik/TextFieldFormik.styled'

interface Props {
  fullWidth?: boolean
  options: any[]
  name: string
  sx?: SxProps
  label?: string | DefaultTFuncReturn
  placeholder?: string | DefaultTFuncReturn
}

export const CountrySelectFormik = ({ fullWidth = true, options = [], name, sx, label, placeholder }: Props) => {
  const [field, meta] = useField(name)
  const { handleChange } = useFormikContext()
  const theme = useTheme()
  const hasError = Boolean(meta.error && meta.touched)

  return (
    <S.CountrySelectFormik haserror={hasError ? 'true' : 'false'}>
      {label && (
        <FormLabel htmlFor={name} sx={{ marginBottom: '6px' }}>
          <Typography variant="body2">{label}</Typography>
        </FormLabel>
      )}
      <Select
        displayEmpty
        id={name}
        size="small"
        name={name}
        renderValue={
          field.value !== ''
            ? undefined
            : () => (
                <Typography variant="body2" color={theme.palette.ui.grey[600]}>
                  {placeholder}
                </Typography>
              )
        }
        IconComponent={ChevronDownIcon}
        value={field.value}
        fullWidth={fullWidth}
        onChange={handleChange}
        sx={{ ...sx }}
        MenuProps={{
          anchorOrigin: { vertical: 50, horizontal: 'right' },
          transformOrigin: { vertical: 'top', horizontal: 'right' },
          PaperProps: {
            sx: {
              background: theme.palette.background.paper,
              boxShadow: '0px 20px 64px -10px rgba(55, 55, 72, 0.3)',
              maxHeight: '350px',
              overflowY: 'auto',
              overflowX: 'hidden',
              '&::-webkit-scrollbar': {
                borderRadius: '2px',
                width: '15px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: theme.palette.ui.grey[600],
                borderRadius: '10px',
                border: `5px solid ${theme.palette.background.paper}`,
              },
            },
          },
        }}
      >
        {options?.map((option, index) => {
          const src = `https://flagcdn.com/w20/${option.code.toLowerCase()}.png`
          return (
            <MenuItem key={index} value={option.code}>
              <Box display="flex">
                <S.ImageBlock>
                  <Image width={20} height={20} src={src} loader={() => src} alt="" unoptimized />
                </S.ImageBlock>
                <Box display="flex" fontWeight={300}>
                  {option.label}
                </Box>
              </Box>
            </MenuItem>
          )
        })}
      </Select>
      {hasError && <ErrorMessage>{meta.error || ''}</ErrorMessage>}
    </S.CountrySelectFormik>
  )
}
