import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import { useEffect, useState } from 'react'

dayjs.extend(relativeTime)
dayjs.extend(duration)

export const useCountdown = () => {
  const [startTime, setStartTime] = useState<dayjs.Dayjs | null>(null)
  const [remainingTime, setRemainingTime] = useState('')
  const [countdownMinutes, setCountdownMinutes] = useState(0)

  const formatTime = (milliseconds: number) => {
    const duration = dayjs.duration(milliseconds)
    const minutes = Math.floor(duration.asMinutes()).toString().padStart(2, '0')
    const seconds = Math.floor(duration.seconds()).toString().padStart(2, '0')
    return `${minutes}:${seconds}`
  }

  useEffect(() => {
    if (!startTime || countdownMinutes === 0) return

    const endTime = startTime.add(countdownMinutes, 'minute')

    const initialDiff = endTime.diff(dayjs())
    setRemainingTime(formatTime(initialDiff))

    const interval: NodeJS.Timeout = setInterval(() => {
      const now = dayjs()
      const diff = endTime.diff(now)
      setRemainingTime(formatTime(diff))

      if (diff <= 1000) {
        clearInterval(interval)
        setRemainingTime('00:00')
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [startTime, countdownMinutes])

  const isNearEnd = (thresholdSeconds = 30) => {
    const [minutes, seconds] = remainingTime.split(':').map(Number)
    const totalSeconds = minutes * 60 + seconds

    return totalSeconds <= thresholdSeconds
  }

  return {
    countdown: remainingTime,
    startCountdown: (minutes: number) => {
      const now = dayjs()
      setStartTime(now)
      setCountdownMinutes(minutes)
    },
    isFinished: remainingTime === '00:00',
    isNearEnd,
  }
}
