import { Box, ButtonBase, styled } from '@mui/material'

export const AmountBtnGroup = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: '6px',
  border: `1px solid ${theme.palette.ui.grey[800]}`,
  userSelect: 'none',
}))
export const AmountBtn = styled(ButtonBase)(({ theme }) => ({
  width: '100%',
  height: '100%',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('lg')]: {
    width: '28px',
  },
}))

export const AmountCountField = styled(Box)(({ theme }) => ({
  padding: '0 5px',
  minWidth: '43px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  borderInlineEnd: `1px solid ${theme.palette.ui.grey[800]}`,
  borderInlineStart: `1px solid ${theme.palette.ui.grey[800]}`,
  [theme.breakpoints.down('lg')]: {
    minWidth: '37px',
  },
}))
