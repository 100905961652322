import { gql } from '@apollo/client'

export const GET_SITES = gql`
  query GetSites {
    getSites {
      id
      domain
    }
  }
`
