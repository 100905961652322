import { useTranslation } from 'next-i18next'
import React from 'react'

import { BinancePayIcon } from '@/icons'

import * as S from './Tabs.styled'

interface Props {
  tabNum: string
}

export const TabsCustom = ({ tabNum = '1' }: Props) => {
  const { t } = useTranslation()

  return (
    <S.Tabs>
      <S.TabBtn target={tabNum === '1' ? 'true' : 'false'}>{t('app.cart.default_wallet')}</S.TabBtn>
      <S.TabBtn target={tabNum === '2' ? 'true' : 'false'}>
        <BinancePayIcon />
      </S.TabBtn>
    </S.Tabs>
  )
}
