import { Box, styled } from '@mui/material'

export const NotFoundProduct = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  alignSelf: 'center',
  margin: '46px auto',
  [theme.breakpoints.down('lg')]: {
    maxWidth: '220px',
    marginTop: '24px',
  },
}))
