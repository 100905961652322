import { ApolloQueryResult } from '@apollo/client/core/types'
import { useRouter } from 'next/router'
import { useState } from 'react'

import { MODAL_NAME } from '@/constants/modalName'
import { AUTH_PATHS, PATHS } from '@/constants/routes'

import { useAlertContext, useAuthContext, useModalContext } from '@/context'
import { useSiteIdContext } from '@/context/SiteIdContext'

import {
  CreateOrderErrorSchema,
  CurrencyEnum,
  ExchangeRateEntity,
  GetAllCurrencyQuery,
  UserLanguagesEnum,
  useCreateFiatOrderMutation,
  usePayBinanceOrderLazyQuery,
} from '@/graphql/generated'

import { useCurrentLanguage } from '@/hooks/useCurrentLanguage'
import { useLaptop } from '@/hooks/useLaptop'

import { useIsDisabledProductRowStore } from '@/store'

import { CurrencyType } from '@/types/baseTypes'

import { getClientTime, getDataCartToCreateFiatOrder, getErrorName, getIdCurrency } from '@/utils'

interface Props {
  fiatAllCurrency: ApolloQueryResult<GetAllCurrencyQuery>
  currentCurrency: CurrencyType
  productData: any
  exchangeRatesData: ExchangeRateEntity[]
}

export const useCheckoutForBinanceCart = ({ fiatAllCurrency, currentCurrency, productData }: Props) => {
  const router = useRouter()
  const { onOpenModal } = useModalContext()
  const { onOpenAlert } = useAlertContext()
  const { isAuth } = useAuthContext()
  const { isOverLaptop } = useLaptop()
  const { isENLang } = useCurrentLanguage()
  const { siteId } = useSiteIdContext()

  const [errorPreOrder, setErrorPreOrder] = useState<CreateOrderErrorSchema | undefined>()

  const [dataIds, setDataIds] = useIsDisabledProductRowStore(state => [state.dataIds, state.setDataIds])

  const [createFiatOrder, { loading: createFiatOrderLoading, data: createFiatOrderData }] = useCreateFiatOrderMutation()
  // const [
  //   getBinanceLink,
  //   { loading: binanceLinkLoading, data: binanceLinkData },
  // ] = useGetBinanceCheckoutLinkLazyQuery();
  const [payBinanceOrderLQ, { loading: payBinanceOrderLQLoading, data: payBinanceOrderLQData }] =
    usePayBinanceOrderLazyQuery()

  const currencyId = getIdCurrency(fiatAllCurrency.data.getAllCurrency, currentCurrency as CurrencyType)

  // const total = getResultTotalCart(
  //   transformDataToTotal(productData as CartProductSchema[]),
  //   currentCurrency as CurrencyType,
  //   exchangeRatesData,
  //   isAuth,
  // );

  const doCheckout = async () => {
    if (!isAuth) {
      if (isOverLaptop) {
        return onOpenModal(MODAL_NAME.signIn)
      } else {
        return router.push(
          {
            pathname: AUTH_PATHS.signIn,
            query: { from: PATHS.cart },
          },
          AUTH_PATHS.signIn,
        )
      }
    }
    if (createFiatOrderLoading || payBinanceOrderLQLoading) return

    await createFiatOrder({
      variables: {
        input: {
          carts: getDataCartToCreateFiatOrder(productData),
          clientTime: getClientTime(),
          currencyId: currencyId,
          currencyIso: currentCurrency as CurrencyEnum,
          languageIso: isENLang ? UserLanguagesEnum.En : UserLanguagesEnum.Ar,
        },
      },
      onError: error => {
        onOpenAlert({ error: true, subTitle: getErrorName(error.message) })
      },
      onCompleted: data => {
        const orderId = data.createFiatOrder.order ? +data.createFiatOrder.order.id : 0
        const errorTarget = data.createFiatOrder.data.find(i => i.error)

        if (errorTarget?.error) {
          setDataIds(String(errorTarget?.skuId))
        }

        if (errorTarget?.error) return
        if (data.createFiatOrder.error) {
          return setErrorPreOrder(data.createFiatOrder as CreateOrderErrorSchema)
        }

        payBinanceOrderLQ({
          variables: {
            input: {
              id: orderId,
              languageIso: isENLang ? UserLanguagesEnum.En : UserLanguagesEnum.Ar,
              siteId,
            },
          },
          onCompleted: data => {
            if (typeof window !== 'undefined') {
              // window.location.href = data.getBinanceCheckoutLink.checkoutUrl;
            }
          },
          onError: error => {
            onOpenAlert({ error: true, subTitle: getErrorName(error.message) })
          },
        })
      },
    })
  }
  return {
    doCheckout,
    createFiatOrderLoading,
    payBinanceOrderLQLoading,
    dataIds,
    errorPreOrder,
    payBinanceOrderLQData,
    orderId: createFiatOrderData?.createFiatOrder.order?.id,
  }
}
