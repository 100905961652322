import React, { useState } from 'react'

import { ModalContent } from '@/components'

interface Props {
  children: React.ReactNode
}

type OpenModalType = {
  name: string
  content: any
  open: boolean
}

type ContextType = {
  onOpenModal: (name: string, content?: any) => void
  onCloseModal: () => void
  openModal: OpenModalType
}

export const ModalContext = React.createContext<ContextType>({
  onOpenModal: () => {},
  onCloseModal: () => {},
  openModal: {
    name: '',
    content: {},
    open: false,
  },
})

export const useModalContext = () => {
  const modalContext = React.useContext(ModalContext)

  if (!modalContext) {
    throw new Error('useModalContext must be used within a ModalContextProvider')
  }
  return modalContext
}

export const ModalProvider = ({ children }: Props) => {
  const [openModal, setOpenModal] = useState({
    name: '',
    content: {},
    open: false,
  })

  const onOpenModal = (name: string, content?: any) => {
    setOpenModal({ name, content, open: true })
  }

  const onCloseModal = () => {
    setOpenModal({ name: '', content: {}, open: false })
  }

  const value = {
    openModal,
    onOpenModal,
    onCloseModal,
  }

  return (
    <ModalContext.Provider value={value as any}>
      {children}
      <ModalContent />
    </ModalContext.Provider>
  )
}
