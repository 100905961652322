const TOKEN_NAME = 'token.market'

export const getAuthToken = (): string | null => {
  if (typeof window !== 'undefined') {
    return localStorage.getItem(TOKEN_NAME) || sessionStorage.getItem(TOKEN_NAME)
  }

  return null
}

export const hasAuthToken = () => !!getAuthToken()

export const setAuthToken = (token: string, storage = window.localStorage) => {
  storage.setItem(TOKEN_NAME, token)
}

export const removeAuthToken = () => {
  localStorage.removeItem(TOKEN_NAME)
  sessionStorage.removeItem(TOKEN_NAME)
}
