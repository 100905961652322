import { DefaultTFuncReturn } from 'i18next'

import { useAlertContext } from '@/context'

export const useCopyText = () => {
  const { onOpenAlert } = useAlertContext()
  const copyText = async (copyText: string | DefaultTFuncReturn, alertText: string | DefaultTFuncReturn = '') => {
    if (typeof window !== 'undefined') {
      navigator.clipboard.writeText(copyText as string).then(() => onOpenAlert({ subTitle: alertText }))
    }
  }

  return { copyText }
}
