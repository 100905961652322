import { styled } from '@mui/material'
import Box from '@mui/material/Box'

export const EmptyCart = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: 'fit-content',
  alignItems: 'center',
  margin: 'auto',
  [theme.breakpoints.down('lg')]: {},
}))
