import { useTheme } from '@mui/material'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
import React from 'react'

import { IconsTypes } from '@/types/baseTypes'

export const AllProductsIcon = ({ ...props }: IconsTypes & SvgIconProps) => {
  const theme = useTheme()

  return (
    <SvgIcon {...props} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 7.99976C3 5.23833 5.23858 2.99976 8 2.99976H16C18.7614 2.99976 21 5.23833 21 7.99976V15.9998C21 18.7612 18.7614 20.9998 16 20.9998H8C5.23858 20.9998 3 18.7612 3 15.9998V7.99976ZM8 4.99976C6.34315 4.99976 5 6.3429 5 7.99976V15.9998C5 17.6566 6.34315 18.9998 8 18.9998H16C17.6569 18.9998 19 17.6566 19 15.9998V7.99976C19 6.3429 17.6569 4.99976 16 4.99976H8Z"
        fill={theme.palette.ui.primary.main}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36 2.99976C36.5523 2.99976 37 3.44747 37 3.99976V10.9998H44C44.5523 10.9998 45 11.4475 45 11.9998C45 12.552 44.5523 12.9998 44 12.9998H37V19.9998C37 20.552 36.5523 20.9998 36 20.9998C35.4477 20.9998 35 20.552 35 19.9998V12.9998H28C27.4477 12.9998 27 12.552 27 11.9998C27 11.4475 27.4477 10.9998 28 10.9998H35V3.99976C35 3.44747 35.4477 2.99976 36 2.99976Z"
        fill={theme.palette.ui.primary.main}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27 31.9998C27 29.2383 29.2386 26.9998 32 26.9998H40C42.7614 26.9998 45 29.2383 45 31.9998V39.9998C45 42.7612 42.7614 44.9998 40 44.9998H32C29.2386 44.9998 27 42.7612 27 39.9998V31.9998ZM32 28.9998C30.3431 28.9998 29 30.3429 29 31.9998V39.9998C29 41.6566 30.3431 42.9998 32 42.9998H40C41.6569 42.9998 43 41.6566 43 39.9998V31.9998C43 30.3429 41.6569 28.9998 40 28.9998H32Z"
        fill={theme.palette.ui.primary.main}
      />
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 31.9998C3 29.2383 5.23858 26.9998 8 26.9998H16C18.7614 26.9998 21 29.2383 21 31.9998V39.9998C21 42.7612 18.7614 44.9998 16 44.9998H8C5.23858 44.9998 3 42.7612 3 39.9998V31.9998ZM8 28.9998C6.34315 28.9998 5 30.3429 5 31.9998V39.9998C5 41.6566 6.34315 42.9998 8 42.9998H16C17.6569 42.9998 19 41.6566 19 39.9998V31.9998C19 30.3429 17.6569 28.9998 16 28.9998H8Z"
        fill={theme.palette.ui.primary.main}
      />
    </SvgIcon>
  )
}
