import { ComponentsOverrides, ComponentsProps, ComponentsVariants } from '@mui/material'

import { palette } from '@/theme/palette'

export default {
  styleOverrides: {
    root: {},
    container: {},
    paper: {
      borderRadius: '8px',
      boxShadow: `0px 32px 64px -20px ${palette.ui.grey[700]}`,
      background: palette.base.white,
      width: '100%',
      maxWidth: '560px',
      '&::-webkit-scrollbar': {
        borderRadius: '2px',
        width: '15px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: palette.ui.grey[600],
        borderRadius: '10px',
        border: `5px solid ${palette.base.white}`,
      },
    },
  },
} as {
  defaultProps?: ComponentsProps['MuiDialog']
  styleOverrides?: ComponentsOverrides<unknown>['MuiDialog']
  variants?: ComponentsVariants['MuiDialog']
}
