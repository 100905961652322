import React, { useContext } from 'react'

import { CartProductSchema, useGetAllProductFromCartQuery } from '@/graphql/generated'

import { useAuthContext } from './AuthContext'

interface Props {
  children: React.ReactNode
}

type ContextType = {
  cartLength: number
  productData: CartProductSchema[]
  loadingCartData: boolean
  refetching: boolean
  refetchCartData: () => void
}

export const CartContext = React.createContext<ContextType>({
  cartLength: 0,
  productData: [],
  loadingCartData: false,
  refetchCartData: () => {},
  refetching: false,
})

export const CartProvider = ({ children }: Props) => {
  const { isAuth } = useAuthContext()

  const {
    data: dataAllProduct,
    networkStatus,
    refetch,
  } = useGetAllProductFromCartQuery({
    fetchPolicy: 'network-only',
    skip: !isAuth,
    notifyOnNetworkStatusChange: true,
  })
  const allProductsCollection = dataAllProduct?.getAllProductFromCart.products as CartProductSchema[]

  const value = {
    cartLength: allProductsCollection?.length,
    refetching: networkStatus === 4,
    productData: allProductsCollection,
    loadingCartData: networkStatus === 1,
    refetchCartData: refetch,
  }

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>
}

export const useCartContext = () => {
  const modalContext = useContext(CartContext)

  if (!modalContext) {
    throw new Error('useCartContext must be used within a ModalContextProvider')
  }
  return modalContext
}
