import { Box, Button, IconButton } from '@mui/material'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'

import { NavigationComponent } from '@/components'
import { ProfIcon } from '@/components/icons/ProfIcon'
import { Badge, Select } from '@/components/uiKit'
import { Menu } from '@/components/uiKit/Menu/Menu'

import { arabianCountries } from '@/constants/constants'
import { MODAL_NAME } from '@/constants/modalName'
import { AUTH_PATHS, PATHS } from '@/constants/routes'

import { useAuthContext, useCartContext, useModalContext } from '@/context'
import { useIntlContext } from '@/context/IntlContext'
import { useSiteIdContext } from '@/context/SiteIdContext'

import { useCurrentLanguage, useLaptop } from '@/hooks'

import {
  BurgerIcon,
  CartIcon,
  CloseIcon,
  CoinsIcon,
  GlobeIcon,
  LogoImage,
  LogoutIcon,
  MyOrdersIcon,
  ProfileIcon,
} from '@/icons'

import { palette } from '@/theme/palette'

import { optionsMenuType } from '@/types/optionsTypes'

import { getMyLocationInfo } from '@/utils/getMyLocationInfo'

import * as S from './Header.styled'

const optionLang = [
  { value: 'en', label: 'English (EN)' },
  { value: 'ar', label: 'Arab (AE)' },
]
const optionCoins = [
  { value: 'USD', label: 'USD' },
  // { value: 'EUR', label: 'EUR' },
  // { value: 'AED', label: 'AED' },
]

const optionMenuValueConst = {
  general: 'General',
  myOrders: 'My orders',
  logout: 'Logout',
}

const optionsMenu: optionsMenuType[] = [
  {
    value: optionMenuValueConst.general,
    label: optionMenuValueConst.general,
    icon: <ProfileIcon size="20" color={palette.ui.grey[700]} />,
  },
  {
    value: optionMenuValueConst.myOrders,
    label: optionMenuValueConst.myOrders,
    icon: <MyOrdersIcon size="20" color={palette.ui.grey[700]} />,
  },
  {
    value: optionMenuValueConst.logout,
    label: optionMenuValueConst.logout,
    icon: <LogoutIcon size="20" color={palette.ui.grey[700]} />,
  },
]
export const Header = () => {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)
  const [openDrawer, setOpenDrawer] = useState(false)
  const router = useRouter()
  const { handleCurrentCurrency, currentCurrency } = useIntlContext()
  const { isDefaultWallet } = useSiteIdContext()
  const { isOverLaptop } = useLaptop()

  const { isAuth, onLogout } = useAuthContext()
  const { onOpenModal } = useModalContext()
  const { cartLength } = useCartContext()
  const { isENLang } = useCurrentLanguage()
  const { t, i18n } = useTranslation()
  // const currentLanguage = i18n.language

  // const { userCountry } = getMyLocationInfo()
  // const isArabianCountry = arabianCountries.includes(userCountry)

  let lngF = ''
  if (typeof window !== 'undefined') {
    lngF = localStorage.getItem('lngF') || ''
  }
  const onResize = () => {
    const customWidth = document.documentElement.clientWidth

    if (customWidth >= 1100) {
      setOpenDrawer(false)
    }
  }

  const handleCurrentLang = (str: string) => {
    // router.push(router.asPath, router.asPath, { locale: str })
    // if (typeof window !== 'undefined') {
    //   localStorage.setItem('lngF', str)
    // }
    // return changeLanguage(str)
  }

  // ! sign in

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = (e: React.MouseEvent) => {
    if (e.currentTarget.id === optionMenuValueConst.general) {
      router.push(PATHS.profile)
    } else if (e.currentTarget.id === optionMenuValueConst.myOrders) {
      router.push(PATHS.profileMyOrders)
    } else if (e.currentTarget.id === optionMenuValueConst.logout) {
      onLogout()
    }
    setAnchorElUser(null)
  }
  // ! sign in

  const handleSignIn = () => {
    // * if mobile and cart is empty
    if (!isOverLaptop) {
      router.push({ pathname: AUTH_PATHS.signIn, query: { from: router.asPath } }, AUTH_PATHS.signIn)
    } else {
      onOpenModal(MODAL_NAME.signIn)
    }
  }

  useEffect(() => {
    // if (isArabianCountry && !lngF) {
    //   changeLanguage('ar')
    //   router.push(router.asPath, router.asPath, { locale: 'ar' })
    // }
  }, [])

  useEffect(() => {
    window.addEventListener('resize', onResize)
    onResize()

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  return (
    <S.Header>
      <S.InnerWrapper>
        <Box display="flex" onClick={() => router.push('/')} sx={{ cursor: 'pointer' }}>
          <LogoImage style={{ marginInlineEnd: '31px' }} />
        </Box>
        {isOverLaptop && (
          <>
            <S.VerticalDivider />
            {/* <NavigationComponent /> */}
          </>
        )}
        {!openDrawer && (
          <>
            <Box display="flex" sx={{ marginInlineStart: 'auto' }}>
              {isOverLaptop ? (
                <>
                  {/* <Box sx={{ marginInlineEnd: '16px' }}>
                    <Select
                      option={optionLang}
                      icon={<GlobeIcon />}
                      value={currentLanguage}
                      setValue={handleCurrentLang}
                    />
                  </Box> */}
                  {/* {isDefaultWallet && (
                    <Box sx={{ marginInlineEnd: '25px' }}>
                      <Select
                        option={optionCoins}
                        icon={<CoinsIcon />}
                        value={currentCurrency}
                        setValue={handleCurrentCurrency}
                        sx={{ width: '110px' }}
                      />
                    </Box>
                  )} */}
                </>
              ) : null}
              {isAuth && (
                <S.CartBtnWrap>
                  <IconButton onClick={() => router.push(PATHS.cart)}>
                    {!!cartLength && <Badge value={cartLength} />}
                    <CartIcon />
                  </IconButton>
                </S.CartBtnWrap>
              )}
              <Box>
                {isOverLaptop ? (
                  <Button
                    size="medium"
                    variant="outlined"
                    startIcon={<ProfIcon />}
                    onClick={isAuth ? handleOpenUserMenu : handleSignIn}
                  >
                    {isAuth ? t('app.profile') : t('app.sign_in')}
                  </Button>
                ) : (
                  <IconButton onClick={isAuth ? handleOpenUserMenu : handleSignIn} sx={{ marginInlineEnd: '18px' }}>
                    <ProfIcon color={palette.ui.grey[600]} />
                  </IconButton>
                )}
                <Menu anchorElUser={anchorElUser} handleCloseUserMenu={handleCloseUserMenu} options={optionsMenu} />
              </Box>
              {/* {!isOverLaptop ? (
                <IconButton onClick={() => setOpenDrawer(true)}>
                  <BurgerIcon />
                </IconButton>
              ) : null} */}
            </Box>
          </>
        )}
        {/* <S.SwipeableDrawer
          anchor={isENLang ? 'left' : 'right'}
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
          onOpen={() => setOpenDrawer(true)}
        >
          <NavigationComponent
            // currentLang={currentLanguage}
            currentCurrency={currentCurrency}
            setCurrentCurrency={handleCurrentCurrency}
            setCurrentLang={handleCurrentLang}
            optionLang={optionLang}
            optionCoins={optionCoins}
          />
        </S.SwipeableDrawer> */}
        {/* {openDrawer && <CloseIcon />} */}
      </S.InnerWrapper>
    </S.Header>
  )
}
