import { createTheme } from '@mui/material'
import React from 'react'

import { components } from './components'
import { breakpoints } from './foundations/breakpoints'
import { typography } from './foundations/typography'
import { palette } from './palette'

export const theme = createTheme({
  palette: palette,
  components,
  breakpoints,
  typography,
  mixins: {
    centerXY: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    centerY: {
      display: 'flex',
      alignItems: 'center',
    },
  },
})

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    back: true
    tab: true
  }
}

declare module '@mui/material/styles/createMixins' {
  interface Mixins {
    centerXY?: React.CSSProperties
    centerY?: React.CSSProperties
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    purple: Palette['primary']
  }

  interface PaletteOptions {
    purple: PaletteOptions['primary']
  }
}
