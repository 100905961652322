import { Box, BoxProps, styled } from '@mui/material'

import { AliasBoolTypes } from '@/types/baseTypes'

export const Tabs = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '40px',
  borderRadius: '6px',
  padding: '3px',
  background: theme.palette.ui.grey[200],
  [theme.breakpoints.down('lg')]: {
    height: '38px',
  },
}))

interface TabBtnProps extends BoxProps {
  target?: AliasBoolTypes
}

export const TabBtn = styled(Box)<TabBtnProps>(({ theme, target }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '34px',
  width: '129px',
  fontWeight: 300,
  fontSize: '13px',
  lineHeight: '20px',
  color: theme.palette.ui.grey[700],
  userSelect: 'none',
  background: target === 'true' ? theme.palette.background.paper : theme.palette.ui.grey[200],
  [theme.breakpoints.down('lg')]: {
    width: '100%',
    height: '32px',
  },
}))
