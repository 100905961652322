import { ComponentsOverrides, ComponentsProps, ComponentsVariants } from '@mui/material'

import { palette } from '../palette'

export default {
  variants: [
    {
      props: {
        variant: 'elevation',
      },
      style: {
        borderRadius: '8px',
        boxShadow: `0px 16px 30px -10px ${palette.ui.grey[600]}50`,
        '&:before': {
          display: 'none',
        },
        '&:not(:last-child)': {
          borderBottom: 0,
        },
      },
    },
  ],
} as {
  defaultProps?: ComponentsProps['MuiAccordion']
  styleOverrides?: ComponentsOverrides<unknown>['MuiAccordion']
  variants?: ComponentsVariants['MuiAccordion']
}
