import { gql } from '@apollo/client'

export const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    getCurrentUser {
      id
      email
      role
      language
      kycLevel
      country
      name
      address
      phoneNumber
      phoneIsVerified
      googleId
      siteId
      currencyId
      isVerified
      createdAt
      updatedAt
      total
    }
  }
`
