import SvgIcon from '@mui/material/SvgIcon'
import React from 'react'

import { palette } from '@/theme/palette'

import { IconsTypes } from '@/types/baseTypes'

export const EcommerceIcon = ({ color = palette.ui.grey[600] }: IconsTypes) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.25 6.5C1.25 3.87665 3.37665 1.75 6 1.75H18C20.6234 1.75 22.75 3.87665 22.75 6.5V17.5C22.75 20.1234 20.6234 22.25 18 22.25H6C3.37665 22.25 1.25 20.1234 1.25 17.5V6.5ZM6 3.25C4.20507 3.25 2.75 4.70507 2.75 6.5V17.5C2.75 19.2949 4.20507 20.75 6 20.75H18C19.7949 20.75 21.25 19.2949 21.25 17.5V15.4167H17.4253C15.5383 15.4167 14.0086 13.887 14.0086 12C14.0086 10.113 15.5383 8.58333 17.4253 8.58333H21.25V6.5C21.25 4.70507 19.7949 3.25 18 3.25H6ZM21.25 10.0833H17.4253C16.3667 10.0833 15.5086 10.9415 15.5086 12C15.5086 13.0585 16.3667 13.9167 17.4253 13.9167H21.25V10.0833ZM5.5431 7C5.5431 6.58579 5.87889 6.25 6.2931 6.25H12.5C12.9142 6.25 13.25 6.58579 13.25 7C13.25 7.41421 12.9142 7.75 12.5 7.75H6.2931C5.87889 7.75 5.5431 7.41421 5.5431 7Z"
        fill={color}
      />
      <path
        d="M19 12C19 12.5523 18.5523 13 18 13C17.4477 13 17 12.5523 17 12C17 11.4477 17.4477 11 18 11C18.5523 11 19 11.4477 19 12Z"
        fill={color}
      />
    </SvgIcon>
  )
}
