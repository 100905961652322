import { parsePhoneNumber } from 'awesome-phonenumber'
import { useTranslation } from 'next-i18next'
import React, { useEffect, useRef, useState } from 'react'
import AuthCode, { AuthCodeRef } from 'react-auth-code-input'

import * as S from '@/components/Form/SignInForm.styled'
import { Alert, CircularProgress } from '@/components'

import { MODAL_NAME } from '@/constants/modalName'

import { useModalContext } from '@/context'

import { useErrorsStore } from '@/graphql/client'
import { useSendOtpMutation, useVerifyOtpMutation } from '@/graphql/generated'
import { GET_CURRENT_USER } from '@/graphql/queries'

import { useCount, useLaptop } from '@/hooks'

import { ArrowIcon } from '@/icons'

import { getConfirmPasswordInfoText, getErrorName } from '@/utils'

import { FormConfirmHeader } from '../FormConfirmHeader/FormConfirmHeader'

export const PhoneVerificationForm = () => {
  const { isOverLaptop } = useLaptop()
  const [code, setCode] = useState('')
  const [showInfo, setShowInfo] = useState(false)
  const [showTimer, setShowTimer] = useState(0)
  const { openModal, onOpenModal } = useModalContext()
  const authRef = useRef<AuthCodeRef>(null)
  const pn = parsePhoneNumber(`+${openModal?.content?.phoneNumber}`)

  const error = useErrorsStore()

  const [sendOtpMutation] = useSendOtpMutation()

  const [verifyOtpMutation, { loading }] = useVerifyOtpMutation()

  const { t } = useTranslation()
  const { countDown, setStart, start, setCountDown } = useCount()

  const handleSubmit = () => {
    verifyOtpMutation({
      variables: {
        phoneNumber: openModal?.content?.phoneNumber,
        code: code.toUpperCase(),
      },
      refetchQueries: [GET_CURRENT_USER],
      onCompleted: () => {
        onOpenModal(MODAL_NAME.donePhone)
      },
      onError: () => {
        authRef.current?.clear()
        setShowInfo(false)
        setStart(false)
        setCountDown(0)
      },
    })
  }

  const handleReSend = () => {
    if (error) {
      useErrorsStore.setState({
        hasError: false,
      })
    }

    setShowTimer(prevState => prevState + 1)
    if (start && countDown <= 30 && countDown !== 0) return
    setStart(true)
    setCountDown(30)

    sendOtpMutation({
      variables: {
        phoneNumber: openModal?.content?.phoneNumber,
      },
      onCompleted: () => {
        setShowInfo(true)
        authRef.current?.clear()
      },
    })
  }

  useEffect(() => {
    useErrorsStore.setState({ hasError: false })
  }, [])

  return (
    <S.FormWrapper>
      <FormConfirmHeader
        sxSubTitle={{ padding: 0 }}
        title={t('app.profilePage.phone_verification')}
        subTitle={
          <>
            {t('app.profilePage.pe_code')}
            {!isOverLaptop ? <br /> : <></>}
            <span>{pn.number?.international || openModal?.content?.phoneNumber}</span>
          </>
        }
      />
      <S.AuthCodeWrapper>
        <AuthCode
          onChange={value => setCode(value)}
          length={4}
          containerClassName="containerClass"
          inputClassName="inputClass"
          ref={authRef}
        />
        <S.ButtonFW
          sx={{ marginBottom: '24px' }}
          onClick={handleSubmit}
          disabled={!(code.length === 4)}
          endIcon={loading ? <CircularProgress loading={true} color="#fff" /> : <ArrowIcon />}
          size="medium"
        >
          {t('app.profilePage.confirm')}
        </S.ButtonFW>
      </S.AuthCodeWrapper>

      <S.NavLink align="center" onClick={handleReSend}>
        {t`app.re_send_the_code`}
      </S.NavLink>
      {error.hasError || showInfo ? (
        <Alert type={showInfo ? 'information' : 'error'} mt="20px">
          {showInfo
            ? getConfirmPasswordInfoText(showInfo, countDown, showTimer)
            : getErrorName(error.error?.extensions?.exception?.name)}
        </Alert>
      ) : null}
    </S.FormWrapper>
  )
}
