import create from 'zustand'
import { persist } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

export type useAuthVariablesStoreProps = {
  email: string
  code: string
  setEmail: (newEmail: string) => void
  setCode: (newCode: string) => void
  clearEmail: () => void
  clearCode: () => void
}

export const useAuthVariablesStore = create<useAuthVariablesStoreProps>()(
  immer(
    persist(
      setState => ({
        email: '',
        code: '',
        setEmail: newEmail => {
          setState(state => {
            state.email = newEmail
          })
        },
        setCode: newCode => {
          setState(state => {
            state.code = newCode
          })
        },
        clearEmail: () => {
          setState(state => {
            state.email = ''
          })
        },
        clearCode: () => {
          setState(state => {
            state.code = ''
          })
        },
      }),
      {
        name: 'authVariables',
      },
    ),
  ),
)
