import { Components } from '@mui/material'

import MuiAccordion from './components/MuiAccordion'
import MuiAccordionDetails from './components/MuiAccordionDetails'
import MuiAccordionSummary from './components/MuiAccordionSummary'
import MuiButton from './components/MuiButton'
import MuiDialog from './components/MuiDialog'
import MuiDivider from './components/MuiDivider'
import MuiFormControlLabel from './components/MuiFormControlLabel'
import MuiLinearProgress from './components/MuiLinearProgress'
import MuiPagination from './components/MuiPagination'
import MuiSelect from './components/MuiSelect'
import MuiTextField from './components/MuiTextField'

export const components: Components = {
  MuiDialog,
  MuiTextField,
  MuiButton,
  MuiDivider,
  MuiSelect,
  MuiAccordion,
  MuiAccordionSummary,
  MuiAccordionDetails,
  MuiLinearProgress,
  MuiPagination,
  MuiFormControlLabel,
}
