import { Box, styled } from '@mui/material'

export const MultiSearch = styled(Box)(({ border, theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: '8px',
  border: '1px solid',
  borderColor: border ? theme.palette.ui.grey[600] : theme.palette.ui.grey[800],
  height: '56px',
  '&:hover': {
    border: `1px solid ${theme.palette.ui.grey[600]}`,
  },
}))

export const Divider = styled(Box)(({ theme }) => ({
  height: '24px',
  display: 'flex',
  background: theme.palette.ui.grey[600],
  width: '1px',
  marginInlineEnd: '12px',
  marginInlineStart: '20px',
}))
export const SelectBlock = styled(Box)({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  minWidth: '320px',
})
export const SelectBlockMobile = styled(Box)({
  display: 'flex',
  position: 'relative',
  marginBottom: '12px',
  height: '42px',
})

export const SelectClose = styled(Box)({
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  top: '50%',
  transform: 'translate(0, -50%)',
  right: '30px',
  width: '30px',
  height: '30px',
  cursor: 'pointer',
})
