import React from 'react'

import { palette } from '@/theme/palette'

export const GeneralProfileMobileIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2008_20833999999)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.825684 6.01033C0.825684 3.14696 3.1469 0.825745 6.01027 0.825745H13.9895C16.8529 0.825745 19.1741 3.14696 19.1741 6.01033V13.9896C19.1741 16.853 16.8529 19.1742 13.9895 19.1742H6.01027C3.1469 19.1742 0.825684 16.853 0.825684 13.9896V6.01033ZM6.01027 2.07574C3.83726 2.07574 2.07568 3.83732 2.07568 6.01033V13.9896C2.07568 16.1626 3.83726 17.9242 6.01027 17.9242H13.9895C16.1626 17.9242 17.9241 16.1626 17.9241 13.9896V6.01033C17.9241 3.83732 16.1626 2.07574 13.9895 2.07574H6.01027ZM9.99991 5.49544C8.77122 5.49544 7.77517 6.49149 7.77517 7.72018C7.77517 8.94887 8.77122 9.94492 9.99991 9.94492C11.2286 9.94492 12.2247 8.94887 12.2247 7.72018C12.2247 6.49149 11.2286 5.49544 9.99991 5.49544ZM6.52517 7.72018C6.52517 5.80113 8.08086 4.24544 9.99991 4.24544C11.919 4.24544 13.4747 5.80113 13.4747 7.72018C13.4747 9.63922 11.919 11.1949 9.99991 11.1949C8.08086 11.1949 6.52517 9.63922 6.52517 7.72018ZM10.0035 13.661C7.71962 13.661 6.80054 14.1176 6.34709 14.6423C6.1214 14.9034 5.72672 14.9322 5.46555 14.7065C5.20438 14.4808 5.17562 14.0861 5.40131 13.825C6.22123 12.8762 7.61641 12.411 10.0035 12.411C12.4163 12.411 13.7294 12.9009 14.6065 13.7963C14.8481 14.0428 14.844 14.4385 14.5974 14.6801C14.3509 14.9216 13.9551 14.9176 13.7136 14.671C13.1737 14.1198 12.2615 13.661 10.0035 13.661Z"
          fill={palette.ui.grey[600]}
        />
      </g>
      <defs>
        <clipPath id="clip0_2008_20833999999">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
