import { ComponentsOverrides, ComponentsProps, ComponentsVariants } from '@mui/material'

import { palette } from '../palette'

export default {
  defaultProps: {
    size: 'medium',
  },
  styleOverrides: {
    root: {
      '& [placeholder]::placeholder': {
        color: palette.ui.grey[600],
        fontWeight: 300,
        fontSize: '15px',
        lineHeight: '22px',
        opacity: 1,
      },
      '.MuiInputBase-root': {
        borderRadius: '6px',
        '.MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${palette.ui.border.default}`,
        },
        ':hover .MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${palette.ui.grey[600]}`,
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${palette.ui.grey[600]}`,
        },
        '&.Mui-error .MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${palette.ui.error.main}`,
        },
        '& .MuiInputAdornment-root': {
          marginRight: 0,
        },
        paddingInlineStart: '20px',
        svg: {
          // Add SVG styles if needed
        },
        '&.Mui-disabled': {
          borderColor: `rgba(150, 160, 181, 0.4)`,
          background: palette.ui.background.default,
        },
      },
    },
    // Add more style overrides if needed
  },
  variants: [
    {
      props: { size: 'medium' },
      style: {
        input: {
          fontWeight: 300,
          fontSize: '15px',
          lineHeight: '22px',
          color: palette.ui.grey[700],
          padding: 0,
        },
        '.MuiInputBase-root': {
          paddingInlineStart: '16px',
          paddingInlineEnd: '14px',
          height: '48px',
          '&.MuiInputBase-adornedStart svg': {
            marginInlineEnd: '16px',
          },
        },
      },
    },
    {
      props: { size: 'small' },
      style: {
        input: {
          fontWeight: 300,
          fontSize: '14px',
          lineHeight: '21px',
          color: palette.ui.grey[700],
          padding: 0,
        },
        '.MuiInputBase-root': {
          paddingInlineStart: '16px',
          height: '42px',
          padding: '0 14px',
          '&.MuiInputBase-adornedStart svg': {
            marginInlineEnd: '10px',
          },
        },
      },
    },
  ],
} as {
  defaultProps?: ComponentsProps['MuiTextField']
  styleOverrides?: ComponentsOverrides<unknown>['MuiTextField']
  variants?: ComponentsVariants['MuiTextField']
}
