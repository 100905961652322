import { SxProps, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import React from 'react'

interface Props {
  title: string
  subTitle: string | any
  sxTitle?: SxProps
  sxSubTitle?: SxProps
}

export const FormConfirmHeader = ({ title = '', subTitle = '', sxTitle, sxSubTitle }: Props) => {
  const theme = useTheme()
  return (
    <>
      <Typography variant="h3" textAlign="center" mb="8px" sx={sxTitle}>
        {title}
      </Typography>
      <Typography
        textAlign="center"
        variant="body2"
        color={theme.palette.ui.grey[600]}
        mb="20px"
        padding="0 35px"
        sx={sxSubTitle}
      >
        {subTitle}
      </Typography>
    </>
  )
}
