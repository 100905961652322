import { useTranslation } from 'next-i18next'
import React from 'react'

import * as S from './VerifyBadge.styled'

export const VerifyBadge = ({ verify = true }) => {
  const { t } = useTranslation()

  return (
    <S.VerifyBadge verify={verify ? 'true' : 'false'}>
      {verify ? t('app.verified') : t('app.not_verified')}
    </S.VerifyBadge>
  )
}
