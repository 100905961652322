import { Typography } from '@mui/material'
import { DefaultTFuncReturn } from 'i18next'
import React from 'react'

import { LogoutIcon } from '@/icons'

import * as S from './ProfileContentHeader.styled'

interface Props {
  isOverLaptop: boolean
  onClick: () => void
  title: string | DefaultTFuncReturn
  titleBtn: string | DefaultTFuncReturn
}

export const ProfileContentHeader = ({ isOverLaptop, onClick, title = '', titleBtn = '' }: Props) => {
  return (
    <S.ProfileContentHeader mb="20px">
      <Typography variant="body1">{title}</Typography>
      {!isOverLaptop ? (
        <S.TypographyLogo onClick={onClick}>
          <LogoutIcon size="20" /> {titleBtn}
        </S.TypographyLogo>
      ) : null}
    </S.ProfileContentHeader>
  )
}
