import SvgIcon from '@mui/material/SvgIcon'
import React from 'react'

import { palette } from '@/theme/palette'

export const ArrowBackIcon = () => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19 12H5M5 12L9 16M5 12L9 8"
        stroke={palette.ui.grey[600]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}
