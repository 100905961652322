import { ComponentsOverrides, ComponentsProps, ComponentsVariants } from '@mui/material'

export default {
  defaultProps: {
    variant: 'fullWidth',
  },
  variants: [
    {
      props: { variant: 'fullWidth' },
      style: {
        height: 'auto',
        flexShrink: 1,
      },
    },
  ],
} as {
  defaultProps?: ComponentsProps['MuiDivider']
  styleOverrides?: ComponentsOverrides<unknown>['MuiDivider']
  variants?: ComponentsVariants['MuiDivider']
}
