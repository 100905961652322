import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useState } from 'react'

import { useAlertContext } from '@/context'

import { useAddProductToCartMutation } from '@/graphql/generated'
import { GET_ALL_PRODUCT_FROM_CART } from '@/graphql/queries'

import { ErrorMessage } from '@/types/baseTypes'

import { getErrorName } from '@/utils'

export const useAddToCart = () => {
  const router = useRouter()
  const [skuId, setSkuId] = useState('')
  const { onOpenAlert } = useAlertContext()
  const { t } = useTranslation()
  const [addProductToCartMutation, { loading }] = useAddProductToCartMutation()

  const doAddToCart = async () => {
    const id = router.query.id
    if (!skuId || !id) {
      return onOpenAlert({
        subTitle: t`app.please_select_a_product`,
        error: true,
      })
    }

    await addProductToCartMutation({
      variables: { input: { quantity: 1, skuId: +skuId } },
      refetchQueries: [GET_ALL_PRODUCT_FROM_CART],
      onError: error => {
        onOpenAlert({
          subTitle: getErrorName((error?.graphQLErrors[0]?.extensions?.exception as ErrorMessage)?.name),
          error: true,
        })
      },
      onCompleted: () => {
        onOpenAlert({ subTitle: t`app.cart.product_added_to_cart` })
      },
    })
  }
  return { doAddToCart, setSkuId, skuId, loading }
}
