import { BoxProps, styled } from '@mui/material'
import Box from '@mui/material/Box'

interface Props extends BoxProps {
  verify?: string
}

export const VerifyBadge = styled(Box)<Props>(({ theme, verify }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '2.2px 8px 1.8px 9px',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '18px',
  color: verify === 'true' ? theme.palette.ui.success.main : theme.palette.ui.error.main,
  border: '1px solid ',
  borderColor: verify === 'true' ? 'rgba(0,144,69,0.4)' : 'rgba(240,55,88,0.4)',
  borderRadius: '4px',
  width: 'fit-content',
  [theme.breakpoints.down('lg')]: {
    padding: '2.2px 6px 1.8px 7px',
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '15px',
  },
}))
