import { TypographyOptions } from '@mui/material/styles/createTypography'

import { palette } from '../palette'

//TODO: add responsive styles in order to get rid of ternary operators

export const typography: TypographyOptions = {
  fontFamily: 'Poppins, sans-serif',
  allVariants: {
    color: palette.ui.grey[700],
    WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
  },
  body1: {
    fontFamily: 'Poppins, sans-serif',
  },
}
