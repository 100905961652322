import { Button } from '@mui/material'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

import { CircularProgress } from '@/components'

import { ArrowBackIcon, ArrowIcon } from '@/icons'

import * as S from './BottomCartNavigate.styled'

interface Props {
  loading: boolean
  onClickCheckout: () => void
  disabledCheckoutBtn?: boolean
}

export const BottomCartNavigate = ({ loading, onClickCheckout, disabledCheckoutBtn = false }: Props) => {
  const { t } = useTranslation()
  const router = useRouter()

  return (
    <S.BottomCartNavigate>
      <S.InnerWrapper>
        <S.ButtonCart onClick={() => router.back()} startIcon={<ArrowBackIcon />} variant="back">
          {t('app.cart.continue_shopping')}
        </S.ButtonCart>
        <Button
          onClick={onClickCheckout}
          disabled={disabledCheckoutBtn}
          endIcon={loading ? <CircularProgress loading color="#fff" /> : <ArrowIcon />}
          size="small"
        >
          {t('app.cart.checkout')}
        </Button>
      </S.InnerWrapper>
    </S.BottomCartNavigate>
  )
}
