import React from 'react'

import { Header } from '@/components'

import { useLaptop } from '@/hooks'

import * as S from './Layout.styled'

interface Props {
  children: React.ReactNode
}

export const AuthLayout = ({ children }: Props) => {
  const { isOverLaptop } = useLaptop()
  return (
    <S.AuthLayout>
      {!isOverLaptop ? <Header /> : null}
      <S.AuthFormBlock>{children}</S.AuthFormBlock>
      <S.AuthImageBlock />
    </S.AuthLayout>
  )
}
