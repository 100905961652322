import { Box, styled } from '@mui/material'

export const OrDivider = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  marginBottom: '14px',
  [theme.breakpoints.down('lg')]: {
    marginBottom: '12px',
  },
}))
