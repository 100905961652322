import { DefaultTFuncReturn } from 'i18next'
import Head from 'next/head'
import React from 'react'

interface Props {
  children: React.ReactNode
  title?: string | DefaultTFuncReturn
  description?: string
  keywords?: string
}

export const MetaLayout = ({ children, title = '', description = '', keywords = '' }: Props) => {
  return (
    <>
      <>
        <Head>
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta name="keywords" content={keywords} />
          <meta charSet="utf-8" />
        </Head>
      </>
      <>{children}</>
    </>
  )
}
