import { Box, BoxProps, styled } from '@mui/material'

import { AliasBoolTypes } from '@/types/baseTypes'

interface Props extends BoxProps {
  haserror?: AliasBoolTypes
}

export const CountrySelectFormik = styled(Box)<Props>(({ theme, haserror }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: haserror === 'true' ? '10px' : '24px',
    '.MuiInputBase-root': {
      borderColor: haserror === 'true' && theme.palette.ui.error.main,
    },
    [theme.breakpoints.down('lg')]: {
      marginBottom: haserror === 'true' ? '10px' : '16px',
    },
  }
})

export const ImageBlock = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '24px',
  height: '24px',
  borderRadius: '6px',
  overflow: 'hidden',
  marginInlineEnd: '10px',
  maxWidth: 'fit-content',
}))
