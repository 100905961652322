import { Typography } from '@mui/material'
import { DefaultTFuncReturn } from 'i18next'
import React from 'react'

import * as S from './PurchaseCodeCardRow.styled'

interface Props {
  title: string | DefaultTFuncReturn
  subTitle: string | DefaultTFuncReturn
}

export const PurchaseCodeCardRow = ({ title, subTitle }: Props) => {
  return (
    <S.PurchaseCodeCardRow>
      <Typography variant="h4">{title}</Typography>
      <Typography variant="body2">{subTitle}</Typography>
    </S.PurchaseCodeCardRow>
  )
}
