import * as React from 'react'
import { Box, Checkbox, SxProps, Typography } from '@mui/material'
import { useField, useFormikContext } from 'formik'

import { CheckedCheckBoxIcon, NoCheckedCheckBoxIcon } from '@/icons'

import * as S from './CheckBoxFormik.styled'

interface Props {
  label?: React.ReactNode
  name: string
  nativeLabel?: string
  sx?: SxProps
  link?: any
}

export const CheckboxFormik = ({ label, name, sx }: Props) => {
  const [field, meta] = useField(name)
  const { handleChange, setFieldValue } = useFormikContext()
  const hasError = Boolean(meta.error && meta.touched)

  const handle = (e: any) => {
    if (e.target.tagName === 'SPAN') {
      return
    }
    setFieldValue(name, !field.value)
  }

  return (
    <>
      <S.CheckboxFormik sx={sx} haserror={hasError ? 'true' : 'false'}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
          <Checkbox
            sx={{ padding: 0 }}
            id={name}
            name={name}
            checked={field.value}
            onChange={handleChange}
            checkedIcon={<CheckedCheckBoxIcon />}
            icon={<NoCheckedCheckBoxIcon />}
          />
          {label ? (
            <>
              <Typography sx={{ cursor: 'pointer' }} onClick={handle}>
                {label}
              </Typography>
            </>
          ) : null}
        </Box>
        {hasError && <S.ErrorMessage>{meta.error || ''}</S.ErrorMessage>}
      </S.CheckboxFormik>
    </>
  )
}
