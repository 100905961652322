import * as yup from 'yup'

import { messages as M } from '@/constants/message'

import { getEnding } from '@/utils/getEnding'

export const newPasswordSchema = (isENLang: boolean) =>
  yup.object().shape({
    password: yup.string().required(M.REQUIRED[getEnding(isENLang)]),
    repeatPassword: yup
      .string()
      .oneOf([yup.ref('password')], M.PASSWORD_MATCH[getEnding(isENLang)])
      .required(M.REQUIRED[getEnding(isENLang)]),
  })
