import { ApolloQueryResult } from '@apollo/client/core/types'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Link, Stack, Typography } from '@mui/material'
import { Trans, useTranslation } from 'next-i18next'
import Image from 'next/image'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'

import {
  Alert,
  BottomCartNavigate,
  CartProductRow,
  EmptyCart,
  RowForCartTableHead,
  TotalValueToCart,
} from '@/components'
import { LoaderWrapper } from '@/components/LoaderWrapper/LoaderWrapper'

import { useCartContext } from '@/context'
import { useIntlContext } from '@/context/IntlContext'

import {
  CartProductSchema,
  GetAllCurrencyQuery,
  GetExchangeRatesQuery,
  OrderStatusEnum,
  useGetOrderLazyQuery,
} from '@/graphql/generated'

import { useLaptop } from '@/hooks'
import { useCheckoutForBinanceCart } from '@/hooks/useCheckoutForBinanceCart'
import { useCountdown } from '@/hooks/useCountdown'
import { useCurrentLanguage } from '@/hooks/useCurrentLanguage'

import { CurrencyType } from '@/types/baseTypes'

import { getHasNotAvailable } from '@/utils'
import { newPriceWithExchangeRates } from '@/utils/newPriceWithExchangeRates'

import * as S from './BinanceCart.styled'

interface Props {
  exchangeRates: ApolloQueryResult<GetExchangeRatesQuery>
  fiatAllCurrency: ApolloQueryResult<GetAllCurrencyQuery>
}

export const BinanceCart = ({ exchangeRates, fiatAllCurrency }: Props) => {
  const { isOverLaptop } = useLaptop()
  const { isENLang } = useCurrentLanguage()
  const { t } = useTranslation()
  const { currentCurrency } = useIntlContext()
  const { productData: cartData, loadingCartData, refetching: refetchingCartData, refetchCartData } = useCartContext()
  const { push } = useRouter()

  const [loadingsUpdateQuantity, setLoadingsUpdateQuantity] = useState(0)
  const loadingUpdateQuantity = loadingsUpdateQuantity > 0

  const exchangeRatesData = exchangeRates.data.getExchangeRates
  const { countdown, startCountdown, isFinished: isCountdownFinished, isNearEnd } = useCountdown()

  const hasNotAvailable = getHasNotAvailable(cartData as CartProductSchema[])

  const { doCheckout, createFiatOrderLoading, payBinanceOrderLQLoading, dataIds, payBinanceOrderLQData, orderId } =
    useCheckoutForBinanceCart({
      productData: cartData,
      fiatAllCurrency: fiatAllCurrency,
      currentCurrency: currentCurrency as CurrencyType,
      exchangeRatesData: exchangeRatesData,
    })

  const [, { data: orderData, refetch: refetchOrder }] = useGetOrderLazyQuery()

  const showBottomCartNavigate = () => {
    if (!isOverLaptop) {
      return true
    } else {
      return !!cartData?.length
    }
  }

  const [isCheckoutModal, setCheckoutModal] = useState(false)

  useEffect(() => {
    if (payBinanceOrderLQData?.payBinanceOrder) {
      startCountdown(15)
      setCheckoutModal(true)
    }
  }, [payBinanceOrderLQData])

  useEffect(() => {
    let interval: NodeJS.Timeout
    if (orderId) {
      interval = setInterval(() => {
        refetchOrder({ getOrderId: orderId })
      }, 7000)
      if (orderData?.getOrder?.status === OrderStatusEnum.Paid) {
        clearInterval(interval)
        push('/profile/my-orders')
        refetchCartData()
      }
    }
    return () => clearInterval(interval)
  }, [orderId, orderData?.getOrder?.status])

  useEffect(() => {
    if (isCountdownFinished) setCheckoutModal(false)
  }, [isCountdownFinished])

  return (
    <S.Cart>
      <S.FlexRow>
        <Typography variant="h3">{t('app.cart.cart')}</Typography>
      </S.FlexRow>

      {hasNotAvailable || dataIds.length ? (
        <Alert type="error" mb="20px">
          <span>{t`app.cart.unfortunately`}</span>
        </Alert>
      ) : null}

      <LoaderWrapper loading={loadingCartData}>
        {cartData?.length ? (
          <S.Content>
            <RowForCartTableHead />
            {cartData.map((cartData, index) => {
              return (
                <CartProductRow
                  key={index}
                  {...{
                    setLoadingsUpdateQuantity: setLoadingsUpdateQuantity,
                    refetchingCartData: refetchingCartData,
                    name: cartData?.sku?.[isENLang ? 'nameEN' : 'nameAR'],
                    description: cartData?.product?.shortDescriptionEN,
                    id: cartData.skuId,
                    currency: currentCurrency,
                    imgSrc: cartData?.product?.image,
                    quantity: cartData?.quantity,
                    pricePerUnit: newPriceWithExchangeRates(
                      cartData?.sku?.value,
                      cartData?.product?.baseCurrency?.name as CurrencyType,
                      currentCurrency as CurrencyType,
                      exchangeRatesData,
                    ),
                  }}
                />
              )
            })}

            <TotalValueToCart
              loading={loadingUpdateQuantity}
              currency={currentCurrency}
              exchangeRatesData={exchangeRatesData}
              productData={cartData}
            />
          </S.Content>
        ) : (
          <EmptyCart />
        )}
      </LoaderWrapper>

      <Dialog open={isCheckoutModal}>
        <DialogTitle textAlign="center">
          <Trans i18nKey="app.cart.orderId" />
          <Typography sx={{ fontWeight: 600 }} variant="h5">
            #{orderId}
          </Typography>
        </DialogTitle>

        <DialogContent>
          <Stack alignItems="center" justifyContent="center" textAlign="center">
            <Typography variant="subtitle1">
              <Trans
                i18nKey="app.cart.payment_timer_info"
                values={{ countdown }}
                components={{
                  countdown: (
                    <Typography component="span" fontWeight={600} color={isNearEnd(60) ? 'error' : 'primary'} />
                  ),
                }}
              />
            </Typography>

            <Image
              width={256}
              height={256}
              src={payBinanceOrderLQData?.payBinanceOrder?.qrcodeLink || ''}
              alt="qrCode"
            />

            <Typography variant="subtitle1">{t`app.cart.qrCode`}</Typography>
            <Link
              variant="body2"
              href={payBinanceOrderLQData?.payBinanceOrder?.checkoutUrl}
              sx={{
                wordBreak: 'break-all',
              }}
            >
              {payBinanceOrderLQData?.payBinanceOrder?.checkoutUrl}
            </Link>
            <Typography mt={4} variant="caption">
              {t`app.cart.helper_text`}
            </Typography>
            <Typography variant="caption" fontWeight={600}>
              <Link href="mailto:support@recharge.games">support@recharge.games</Link>
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button size="small" variant="outlined" onClick={() => setCheckoutModal(false)}>
            {t`app.cart.cancel`}
          </Button>
        </DialogActions>
      </Dialog>
      {showBottomCartNavigate() && (
        <BottomCartNavigate
          loading={createFiatOrderLoading || payBinanceOrderLQLoading}
          onClickCheckout={doCheckout}
          disabledCheckoutBtn={!cartData?.length || hasNotAvailable || loadingUpdateQuantity}
        />
      )}
    </S.Cart>
  )
}
