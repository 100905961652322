import { PaletteOptions } from '@mui/material/styles'

export const palette: PaletteOptions = {
  base: {
    white: '#FFFFFF',
    black: '#060A0F',
  },
  ui: {
    primary: {
      main: '#4663F4',
    },
    secondary: {
      main: '#30D05A',
    },
    grey: {
      900: '#96a0b526', // rgba(150, 160, 181, 0.15)
      800: '#96A0B566', // rgba(150, 160, 181, 0.4)
      700: '#373748', // rgba(55, 55, 72, 1)
      600: '#96A0B5', // rgba(150, 160, 181, 1)
      500: '#EFEFF7', // rgba(239, 239, 247, 1)
      300: '#F7F9FC', // rgba(247, 249, 252, 1)
      200: '#ECEFF4', // rgba(236, 239, 244, 1)
    },
    purple: {
      500: '#D0C1EC',
    },
    error: {
      main: '#F03758',
      light: '#F037580D',
      dark: '#F037581A',
    },
    success: {
      main: '#009045',
      light: '#B0EF8F66',
      dark: '#B0EF8F33',
    },
    information: {
      main: '#4663F4',
      light: '#4663F40D',
      dark: '#4663F41A',
    },
    background: {
      default: '#F7F9FC',
      paper: '#FFFFFF',
    },
    border: {
      error: '#F0375811',
      information: '#4663F419',
      success: '#B0EF8F66',
      default: '#ECEFF4',
    },
  },
}
