import { t } from 'i18next'

export const getConfirmPasswordInfoText = (showInfo: boolean, count: number, showTimer: number) => {
  if (showInfo && showTimer < 2) {
    return t('app.code_has_been_sent')
  } else if (showInfo && showTimer >= 2) {
    return t('app.you_can_try_again_with', {
      value: `00:${count < 10 ? '0' : ''}${count}`,
    })
  } else {
    return ''
  }
}
