import { Box, LinearProgress, SxProps, Typography } from '@mui/material'
import { useTranslation } from 'next-i18next'
import { useEffect, useState } from 'react'

import { useIntlContext } from '@/context/IntlContext'
import { useSiteIdContext } from '@/context/SiteIdContext'

import {
  useConvertCurrencyMutation,
  useGetAllCurrencyQuery,
  useGetCurrentUserQuery,
  useGetKycLimitsQuery,
} from '@/graphql/generated'

import { CurrencyType } from '@/types/baseTypes'

import { getAllValue, getIdCurrency, getPercent } from '@/utils'

import * as S from './KycLevelBlock.styled'

interface Props {
  sx?: SxProps
}

export const KycLevelBlock = ({ sx }: Props) => {
  const { t } = useTranslation()
  const { siteId } = useSiteIdContext()
  const { currentCurrency } = useIntlContext()
  const { data: fiatAllCurrency } = useGetAllCurrencyQuery()

  const currencyId = getIdCurrency(fiatAllCurrency?.getAllCurrency as any, currentCurrency as CurrencyType)

  const { data } = useGetKycLimitsQuery({
    variables: {
      getKycLimitsId: siteId.toString(),
    },
  })

  const { data: userData, loading } = useGetCurrentUserQuery()
  const kycLevel = userData?.getCurrentUser.kycLevel
  const valueSpent = userData?.getCurrentUser.total

  const valueAll = getAllValue(kycLevel, data?.getKycLimits)
  const percentage = getPercent(valueSpent || 0, +valueAll)

  const [convertCurrency] = useConvertCurrencyMutation()

  const [value, setValue] = useState<Record<string, string | number | undefined>>({
    value1: 0,
    value2: 0,
  })

  useEffect(() => {
    if (!valueSpent || !valueAll) return
    Promise.all([
      convertCurrency({
        variables: {
          input: {
            currencyFromId: 1, // USD
            currencyToId: currencyId,
            value: Number(valueSpent),
          },
        },
      }),
      convertCurrency({
        variables: {
          input: {
            currencyFromId: 1, // USD
            currencyToId: currencyId,
            value: +valueAll,
          },
        },
      }),
    ]).then(res =>
      setValue({
        value1: res[0].data?.convertCurrency,
        value2: res[1].data?.convertCurrency,
      }),
    )
  }, [currencyId, valueAll, valueSpent, convertCurrency])

  if (loading) return <Box />

  return (
    <S.KycLevelBlock sx={sx}>
      <Typography component="span" variant="body1" sx={{ typography: { lg: 'h6' } }}>
        {t('app.profilePage.kyc_Level')}:{' '}
        <Typography component="span" variant="body1" sx={{ typography: { lg: 'h5' }, fontWeight: 'bold' }}>
          {kycLevel}
        </Typography>
      </Typography>
      <LinearProgress variant="determinate" value={percentage} sx={{ margin: { xs: '10px 0', lg: '12px 0' } }} />
      <S.Flex>
        <Typography variant="body2" sx={{ typography: { lg: 'body1' } }}>
          {t('app.profilePage.your_limit')}:
        </Typography>
        <Typography
          component="span"
          variant="body2"
          sx={{
            typography: { lg: 'body1' },
            fontWeight: 'medium',
            maxWidth: { lg: '140px' },
            overflow: 'hidden',
          }}
        >
          {Number(value.value1 || valueSpent).toFixed()} /
          <Typography component="span" variant="body2" sx={{ typography: { lg: 'body1' } }}>
            {' '}
            {Number(value.value2 || valueAll).toFixed()} {currentCurrency}
          </Typography>
        </Typography>
      </S.Flex>
    </S.KycLevelBlock>
  )
}
