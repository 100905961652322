import SvgIcon from '@mui/material/SvgIcon'
import React from 'react'

import { palette } from '@/theme/palette'

export const EyeOffIcon = () => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.22706 6.18365C7.4625 6.52444 7.3771 6.99157 7.03631 7.22702C6.16293 7.83042 5.37064 8.54348 4.6789 9.34869L4.66823 9.36084C3.94941 10.162 3.3345 11.0499 2.83766 12.0036C3.7671 13.827 5.11604 15.4059 6.77554 16.6094C8.30519 17.6822 10.1294 18.2553 11.9979 18.25L12.0041 18.2499V18.25C13.7718 18.2597 15.5024 17.7428 16.9752 16.7651C17.3203 16.536 17.7858 16.6301 18.0149 16.9752C18.2439 17.3203 18.1499 17.7857 17.8048 18.0148C16.0847 19.1567 14.0636 19.7607 11.999 19.75C9.81986 19.7555 7.69239 19.0861 5.90897 17.8337L5.89991 17.8274L5.89995 17.8273C3.93674 16.4049 2.36268 14.5116 1.32254 12.3217C1.22485 12.1161 1.22588 11.8772 1.32533 11.6724C1.9082 10.472 2.65576 9.3588 3.54638 8.3651C4.32056 7.46499 5.20687 6.66777 6.18369 5.99291C6.52448 5.75746 6.99161 5.84286 7.22706 6.18365Z"
        fill={palette.ui.grey[600]}
      />
      <path
        d="M11.9987 4.25L12.0001 4.25C15.7762 4.25 18.4614 6.14461 20.1747 7.98966C21.0315 8.91243 21.6558 9.83284 22.0664 10.5226C22.2721 10.8682 22.4254 11.1579 22.5283 11.3638C22.5798 11.4668 22.6188 11.549 22.6455 11.6069L22.6894 11.7046C22.7736 11.9009 22.77 12.1239 22.6796 12.3174C22.207 13.3291 21.6144 14.2802 20.9144 15.1501C20.6548 15.4729 20.1826 15.524 19.8599 15.2643C19.5372 15.0047 19.4861 14.5326 19.7457 14.2099C20.297 13.5246 20.7738 12.783 21.168 11.9975C21.0795 11.8225 20.9498 11.5794 20.7775 11.2899C20.4068 10.6672 19.8436 9.83757 19.0755 9.01034C17.5388 7.35552 15.2243 5.75023 12.0008 5.75C11.607 5.75077 11.2136 5.77556 10.8228 5.82425C10.4117 5.87546 10.037 5.58376 9.98581 5.17273C9.9346 4.76169 10.2263 4.38697 10.6373 4.33576C11.0889 4.27949 11.5436 4.25085 11.9987 4.25Z"
        fill={palette.ui.grey[600]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4286 9.36872C10.711 9.67176 10.6942 10.1463 10.3912 10.4287C10.1701 10.6347 9.99281 10.8831 9.86983 11.1591C9.74686 11.4351 9.68073 11.733 9.6754 12.0351C9.67007 12.3373 9.72564 12.6373 9.83881 12.9175C9.95197 13.1977 10.1204 13.4522 10.3341 13.6658C10.5477 13.8795 10.8022 14.0479 11.0824 14.1611C11.3625 14.2742 11.6626 14.3298 11.9647 14.3245C12.2668 14.3192 12.5648 14.253 12.8408 14.1301C13.1168 14.0071 13.3652 13.8298 13.5712 13.6087C13.8536 13.3057 14.3281 13.2889 14.6312 13.5713C14.9342 13.8537 14.951 14.3283 14.6686 14.6313C14.3253 14.9997 13.9113 15.2952 13.4513 15.5002C12.9913 15.7052 12.4947 15.8154 11.9912 15.8243C11.4877 15.8331 10.9875 15.7405 10.5206 15.5519C10.0537 15.3633 9.62949 15.0826 9.2734 14.7265C8.9173 14.3704 8.63658 13.9462 8.44798 13.4793C8.25937 13.0123 8.16675 12.5122 8.17563 12.0087C8.18452 11.5052 8.29473 11.0086 8.49969 10.5486C8.70465 10.0886 9.00016 9.67461 9.36859 9.3313C9.67163 9.04892 10.1462 9.06567 10.4286 9.36872Z"
        fill={palette.ui.grey[600]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.46967 1.46967C1.76256 1.17678 2.23744 1.17678 2.53033 1.46967L22.5303 21.4697C22.8232 21.7626 22.8232 22.2374 22.5303 22.5303C22.2374 22.8232 21.7626 22.8232 21.4697 22.5303L1.46967 2.53033C1.17678 2.23744 1.17678 1.76256 1.46967 1.46967Z"
        fill={palette.ui.grey[600]}
      />
    </SvgIcon>
  )
}
