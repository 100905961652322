export const MODAL_NAME = {
  signIn: 'SignInForm',
  signUp: 'SignUpForm',
  confirmEmail: 'ConfirmEmailForm',
  success: 'SuccessForm',
  forgotPassword: 'ForgotPasswordForm',
  confirmPasswordForm: 'ConfirmPasswordForm',
  newPassword: 'NewPasswordForm',
  successPassword: 'SuccessPasswordForm',
  phoneVerification: 'PhoneVerificationForm',
  donePhone: 'DonePhoneForm',
}
