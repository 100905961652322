import SvgIcon from '@mui/material/SvgIcon'
import React from 'react'

import { palette } from '@/theme/palette'

import { IconsTypes } from '@/types/baseTypes'

export const FoodIcon = ({ color = palette.ui.grey[600] }: IconsTypes) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_452_3383)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.5037 0.746094C13.9179 0.746094 14.2537 1.08188 14.2537 1.49609V9.0374C14.2537 10.2524 15.2386 11.2374 16.4537 11.2374H16.5077C16.9219 11.2374 17.2577 11.5732 17.2577 11.9874V20.9922C17.2577 21.4043 17.5917 21.7383 18.0037 21.7383C18.4158 21.7383 18.7498 21.4043 18.7498 20.9922V11.9874C18.7498 11.5732 19.0856 11.2374 19.4998 11.2374H19.5495C20.7645 11.2374 21.7495 10.2524 21.7495 9.0374V1.49609C21.7495 1.08188 22.0853 0.746094 22.4995 0.746094C22.9137 0.746094 23.2495 1.08188 23.2495 1.49609V9.0374C23.2495 10.8414 21.9584 12.3439 20.2498 12.6712V20.9922C20.2498 22.2327 19.2442 23.2383 18.0037 23.2383C16.7633 23.2383 15.7577 22.2327 15.7577 20.9922V12.672C14.047 12.3464 12.7537 10.843 12.7537 9.0374V1.49609C12.7537 1.08188 13.0894 0.746094 13.5037 0.746094ZM16.5077 0.746094C16.9219 0.746094 17.2577 1.08188 17.2577 1.49609V8.99599C17.2577 9.41021 16.9219 9.74599 16.5077 9.74599C16.0935 9.74599 15.7577 9.41021 15.7577 8.99599V1.49609C15.7577 1.08188 16.0935 0.746094 16.5077 0.746094ZM19.5077 0.746094C19.9219 0.746094 20.2577 1.08188 20.2577 1.49609V8.99599C20.2577 9.41021 19.9219 9.74599 19.5077 9.74599C19.0935 9.74599 18.7577 9.41021 18.7577 8.99599V1.49609C18.7577 1.08188 19.0935 0.746094 19.5077 0.746094Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.01144 2.26025C4.04006 2.26025 2.25446 4.1641 2.25446 6.76221C2.25446 8.78955 3.36394 10.4312 4.79228 11.0208C5.07297 11.1367 5.25609 11.4104 5.25609 11.7141V20.9922C5.25609 21.4043 5.59011 21.7383 6.00213 21.7383C6.41416 21.7383 6.74817 21.4043 6.74817 20.9922V11.7217C6.74817 11.4167 6.9329 11.142 7.21542 11.027C8.65103 10.4427 9.76843 8.79695 9.76843 6.76221C9.76843 4.1641 7.98283 2.26025 6.01144 2.26025ZM0.754456 6.76221C0.754456 3.55917 3.00455 0.760254 6.01144 0.760254C9.01834 0.760254 11.2684 3.55917 11.2684 6.76221C11.2684 9.11852 10.0702 11.2211 8.24817 12.1968V20.9922C8.24817 22.2327 7.24259 23.2383 6.00213 23.2383C4.76168 23.2383 3.75609 22.2327 3.75609 20.9922V12.1867C1.94455 11.2067 0.754456 9.11068 0.754456 6.76221Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_452_3383">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}
