import SvgIcon from '@mui/material/SvgIcon'

import { palette } from '@/theme/palette'

import { IconsTypes } from '@/types/baseTypes'

export const AllProdIcon = ({ color = palette.ui.grey[600], ...props }: IconsTypes) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.25 4.5C1.25 2.70507 2.70507 1.25 4.5 1.25H7.5C9.29493 1.25 10.75 2.70507 10.75 4.5V7.5C10.75 9.29493 9.29493 10.75 7.5 10.75H4.5C2.70507 10.75 1.25 9.29493 1.25 7.5V4.5ZM4.5 2.75C3.5335 2.75 2.75 3.5335 2.75 4.5V7.5C2.75 8.4665 3.5335 9.25 4.5 9.25H7.5C8.4665 9.25 9.25 8.4665 9.25 7.5V4.5C9.25 3.5335 8.4665 2.75 7.5 2.75H4.5Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.25 4.5C13.25 2.70507 14.7051 1.25 16.5 1.25H19.5C21.2949 1.25 22.75 2.70507 22.75 4.5V7.5C22.75 9.29493 21.2949 10.75 19.5 10.75H16.5C14.7051 10.75 13.25 9.29493 13.25 7.5V4.5ZM16.5 2.75C15.5335 2.75 14.75 3.5335 14.75 4.5V7.5C14.75 8.4665 15.5335 9.25 16.5 9.25H19.5C20.4665 9.25 21.25 8.4665 21.25 7.5V4.5C21.25 3.5335 20.4665 2.75 19.5 2.75H16.5Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.25 16.5C13.25 14.7051 14.7051 13.25 16.5 13.25H19.5C21.2949 13.25 22.75 14.7051 22.75 16.5V19.5C22.75 21.2949 21.2949 22.75 19.5 22.75H16.5C14.7051 22.75 13.25 21.2949 13.25 19.5V16.5ZM16.5 14.75C15.5335 14.75 14.75 15.5335 14.75 16.5V19.5C14.75 20.4665 15.5335 21.25 16.5 21.25H19.5C20.4665 21.25 21.25 20.4665 21.25 19.5V16.5C21.25 15.5335 20.4665 14.75 19.5 14.75H16.5Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.25 16.5C1.25 14.7051 2.70507 13.25 4.5 13.25H7.5C9.29493 13.25 10.75 14.7051 10.75 16.5V19.5C10.75 21.2949 9.29493 22.75 7.5 22.75H4.5C2.70507 22.75 1.25 21.2949 1.25 19.5V16.5ZM4.5 14.75C3.5335 14.75 2.75 15.5335 2.75 16.5V19.5C2.75 20.4665 3.5335 21.25 4.5 21.25H7.5C8.4665 21.25 9.25 20.4665 9.25 19.5V16.5C9.25 15.5335 8.4665 14.75 7.5 14.75H4.5Z"
        fill={color}
      />
    </SvgIcon>
  )
}
