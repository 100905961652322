import { styled } from '@mui/material'
import Box from '@mui/material/Box'
import PhoneInputBase from 'react-phone-input-2'

export const PhoneInput = styled(PhoneInputBase)(({ theme, localization, disabled }) => {
  const isEn = Object.keys(localization || {}).length === 0
  // TODO: potential bug remove all !important
  return {
    input: {
      width: '100% !important',
      paddingTop: '12px !important',
      paddingBottom: '12px !important',
      paddingInlineStart: isEn ? '80px !important' : '14px !important',
      paddingInlineEnd: isEn ? '14px !important' : '80px !important',
      borderColor: disabled ? '#BDBDBD !important' : `${theme.palette.ui.grey[800]} !important`,
      fontWeight: '300 !important',
      fontSize: '15px !important',
      lineHeight: '22px !important',
      color: theme.palette.ui.grey[700],
      direction: 'ltr',
      textAlign: isEn ? 'left' : 'end',
      background: disabled ? theme.palette.background.default : 'initial',
      cursor: 'auto !important',
    },
    'input:focus, input:hover': {
      borderColor: theme.palette.ui.grey[600],
      boxShadow: 'none !important',
    },
    '.flag-dropdown': {
      width: '65px',
      borderInlineEnd: '1px solid',
      borderColor: theme.palette.ui.grey[600],
      boxShadow: `0 0 0 1 ${theme.palette.ui.grey[600]}`,
    },
    '.selected-flag': {
      padding: '0 !important',
      paddingInlineStart: '20px !important',
    },
    '.arrow': {
      insetInlineStart: '29px !important',
    },
    [theme.breakpoints.down('lg')]: {
      input: {
        paddingTop: '9.5px !important',
        paddingBottom: '9.5px !important',
        paddingInlineStart: isEn ? '68px !important' : '14px !important',
        paddingInlineEnd: isEn ? '14px !important' : '68px !important',
        fontWeight: '300 !important',
        fontSize: '14px !important',
        lineHeight: '21px !important',
      },
      '.selected-flag': {
        padding: '0 !important',
        paddingInlineStart: '14px !important',
      },
      '.flag-dropdown': {
        width: '58px',
      },
    },
  }
})

export const BadgeWrapper = styled(Box)(() => ({
  position: 'absolute',
  insetInlineEnd: 0,
  top: 0,
}))
