import SvgIcon from '@mui/material/SvgIcon'
import React from 'react'

import { palette } from '@/theme/palette'

export const GlobeIcon = () => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.77997 11.25H7.28229C7.48347 8.23408 8.53193 5.34326 10.296 2.90662C6.2409 3.66174 3.11597 7.06245 2.77997 11.25ZM12 3.14873C10.1266 5.45651 9.00431 8.28426 8.78597 11.25H15.214C14.9957 8.28426 13.8734 5.45651 12 3.14873ZM15.214 12.75C14.9957 15.7157 13.8734 18.5435 12 20.8513C10.1266 18.5435 9.00431 15.7157 8.78597 12.75H15.214ZM7.28229 12.75H2.77997C3.11597 16.9376 6.2409 20.3383 10.296 21.0934C8.53193 18.6567 7.48347 15.7659 7.28229 12.75ZM13.704 21.0934C15.4681 18.6567 16.5165 15.7659 16.7177 12.75H21.22C20.884 16.9376 17.7591 20.3383 13.704 21.0934ZM21.22 11.25H16.7177C16.5165 8.23408 15.4681 5.34326 13.704 2.90662C17.7591 3.66174 20.884 7.06245 21.22 11.25ZM1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12Z"
        fill={palette.ui.grey[600]}
      />
    </SvgIcon>
  )
}
