import { SvgIcon, useTheme } from '@mui/material'
import React from 'react'

export const CartEmptyIcon = () => {
  const theme = useTheme()

  return (
    <SvgIcon width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1704_23003)">
        <path
          d="M9.25556 31.1471L5.68759 12.8955C5.49025 11.8337 6.19045 10.8127 7.25218 10.6146L30.1811 6.51929L33.8833 25.9961L9.25556 31.1471Z"
          fill={theme.palette.ui.grey[300]}
        />
        <path
          d="M23.0986 29.0544L27.3544 1.65363C27.5258 0.582724 28.533 -0.146451 29.6039 0.0249779L50.9787 3.44679C52.0496 3.61822 52.7788 4.62538 52.6074 5.69629L49.819 24.0644L23.0986 29.0544Z"
          fill={theme.palette.ui.primary.main}
        />
        <path
          d="M50.9784 3.44678L46.2354 2.6875C46.6288 3.1147 46.8308 3.71028 46.732 4.32806L43.4521 25.2533L49.8187 24.0644L52.6071 5.69643C52.7785 4.62537 52.0493 3.61837 50.9784 3.44678Z"
          fill="#2F4EE7"
        />
        <path
          d="M17.0918 29.1791V17.5243C17.0918 16.4397 17.971 15.5605 19.0556 15.5605H34.832C35.9166 15.5605 36.7958 16.4397 36.7958 17.5243V25.4057L17.0918 29.1791Z"
          fill="#FFC600"
        />
        <path
          d="M34.832 15.5605H28.3934C29.478 15.5605 30.3571 16.4397 30.3571 17.5243V25.4057L17.0918 27.9461V29.1791L36.7958 25.4057V17.5243C36.7958 16.4397 35.9166 15.5605 34.832 15.5605Z"
          fill="#FFB300"
        />
        <path
          d="M56.877 57.3721L46.0992 66.3902C45.0804 67.2431 43.7941 67.7106 42.4654 67.7109H6.51062C4.98869 67.7109 3.75488 68.9486 3.75488 70.4752C3.75488 72.0019 4.98869 73.2394 6.51062 73.2394L54.326 73.3085L56.877 57.3721Z"
          fill="url(#paint0_linear_1704_23003)"
        />
        <path
          d="M62.7774 19.1599L2.82166 30.3907C0.949632 30.6752 -0.30574 32.4708 0.0649638 34.3334L3.73498 52.7725C4.2673 55.4469 6.60774 57.3722 9.32645 57.3722H56.8769L62.7774 19.1599Z"
          fill="url(#paint1_linear_1704_23003)"
        />
        <path
          d="M55.1463 69.406C54.972 69.4062 54.7997 69.3687 54.6412 69.296C54.4828 69.2233 54.342 69.1171 54.2285 68.9848C54.115 68.8525 54.0315 68.6972 53.9837 68.5295C53.9359 68.3619 53.925 68.1859 53.9518 68.0136L62.2806 14.3497C62.7965 11.0232 65.608 8.60889 68.9659 8.60889C69.286 8.60889 69.5931 8.73608 69.8195 8.96248C70.0459 9.18888 70.1731 9.49595 70.1731 9.81613C70.1731 10.1363 70.0459 10.4434 69.8195 10.6698C69.5931 10.8962 69.286 11.0234 68.9659 11.0234C66.8068 11.0234 64.9987 12.578 64.6666 14.7198L56.3378 68.3835C56.2936 68.668 56.1493 68.9273 55.9308 69.1148C55.7124 69.3022 55.4342 69.4055 55.1463 69.406Z"
          fill="#AFC4E1"
        />
        <path
          d="M18.0604 80C21.7445 80 24.7311 77.0041 24.7311 73.3084C24.7311 69.6128 21.7445 66.6169 18.0604 66.6169C14.3762 66.6169 11.3896 69.6128 11.3896 73.3084C11.3896 77.0041 14.3762 80 18.0604 80Z"
          fill={theme.palette.ui.primary.main}
        />
        <path
          d="M55.4041 80C59.0883 80 62.0749 77.0041 62.0749 73.3084C62.0749 69.6128 59.0883 66.6169 55.4041 66.6169C51.72 66.6169 48.7334 69.6128 48.7334 73.3084C48.7334 77.0041 51.72 80 55.4041 80Z"
          fill={theme.palette.ui.primary.main}
        />
        <path
          d="M18.0598 74.5158C19.6134 74.5158 19.616 72.1013 18.0598 72.1013C16.5061 72.1013 16.5035 74.5158 18.0598 74.5158ZM55.4041 74.5158C56.9578 74.5158 56.9604 72.1013 55.4041 72.1013C53.8505 72.1013 53.8479 74.5158 55.4041 74.5158Z"
          fill="white"
        />
        <path
          d="M68.9658 8.60889H77.244C78.7659 8.60889 79.9997 9.84655 79.9997 11.3732C79.9997 12.8999 78.7659 14.1374 77.244 14.1374H71.643C70.1644 14.1374 68.9658 12.935 68.9658 11.4519V8.60889Z"
          fill={theme.palette.ui.primary.main}
        />
        <path
          d="M51.1065 50.8653H10.2212C9.90102 50.8653 9.59395 50.7381 9.36755 50.5117C9.14115 50.2853 9.01395 49.9783 9.01395 49.6581C9.01395 49.3379 9.14115 49.0308 9.36755 48.8044C9.59395 48.578 9.90102 48.4508 10.2212 48.4508H51.1065C51.4267 48.4508 51.7338 48.578 51.9602 48.8044C52.1866 49.0308 52.3138 49.3379 52.3138 49.6581C52.3138 49.9783 52.1866 50.2853 51.9602 50.5117C51.7338 50.7381 51.4267 50.8653 51.1065 50.8653ZM52.3942 44.4267H8.61154C8.29136 44.4267 7.98429 44.2995 7.75789 44.0731C7.53149 43.8467 7.4043 43.5396 7.4043 43.2195C7.4043 42.8993 7.53149 42.5922 7.75789 42.3658C7.98429 42.1394 8.29136 42.0122 8.61154 42.0122H52.3942C52.7144 42.0122 53.0215 42.1394 53.2479 42.3658C53.4743 42.5922 53.6015 42.8993 53.6015 43.2195C53.6015 43.5396 53.4743 43.8467 53.2479 44.0731C53.0215 44.2995 52.7144 44.4267 52.3942 44.4267Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient id="paint0_linear_1704_23003" x1="4" y1="69.5" x2="57" y2="73" gradientUnits="userSpaceOnUse">
          <stop stopColor="#C8DAEF" />
          <stop offset="1" stopColor="#A5BCDB" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1704_23003"
          x1="0.999999"
          y1="31"
          x2="57.5"
          y2="57"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E8F2FB" />
          <stop offset="1" stopColor="#CBDDF0" />
        </linearGradient>
        <clipPath id="clip0_1704_23003">
          <rect width="80" height="80" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}
