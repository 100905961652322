import SvgIcon from '@mui/material/SvgIcon'
import React from 'react'

import { palette } from '@/theme/palette'

import { IconsTypes } from '@/types/baseTypes'

export const ClothIcon = ({ color = palette.ui.grey[600] }: IconsTypes) => {
  return (
    <SvgIcon width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.96761 2.36211C7.09652 1.49623 9.20054 0.919918 11.9993 0.939062C14.8101 0.958289 16.7841 1.55894 18.9897 2.3838C20.3326 2.886 21.3144 3.98277 21.8005 5.27128L23.9317 10.9215C24.007 11.1211 23.9937 11.3433 23.8952 11.5325C23.7967 11.7217 23.6222 11.86 23.4156 11.9128L19.0417 13.0302V20.2721C19.0417 21.7937 17.8061 23.026 16.2845 23.0221L11.9959 23.0109H11.9919L7.70486 23.0221C6.18328 23.026 4.94768 21.7937 4.94768 20.2721V13.0302L0.573827 11.9128C0.367142 11.86 0.192721 11.7217 0.0942135 11.5325C-0.00429352 11.3433 -0.0175646 11.1211 0.0577215 10.9215L2.18893 5.27128C2.67573 3.9807 3.65456 2.89616 4.96761 2.36211ZM4.94768 11.482V10.778L1.97725 10.0827L1.75691 10.6669L4.94768 11.482ZM2.51119 8.66715L4.94768 9.23743V6.92147C4.94768 6.50725 5.28346 6.17147 5.69768 6.17147C6.11189 6.17147 6.44768 6.50725 6.44768 6.92147V18.2725H17.5417V6.92147C17.5417 6.50725 17.8775 6.17147 18.2917 6.17147C18.7059 6.17147 19.0417 6.50725 19.0417 6.92147V9.23743L21.4782 8.66715L20.397 5.80066C20.0431 4.86253 19.3504 4.12015 18.4643 3.78876C16.3341 2.99213 14.5487 2.45654 11.989 2.43903C9.43654 2.42157 7.5307 2.93896 5.53275 3.75158C4.64139 4.11412 3.94549 4.8646 3.59241 5.80066L2.51119 8.66715ZM22.0121 10.0827L19.0417 10.778V11.482L22.2325 10.6669L22.0121 10.0827ZM17.5417 19.7725H6.44768V20.2721C6.44768 20.9637 7.00931 21.5239 7.70094 21.5221L11.9959 21.5109L16.2885 21.5221C16.9801 21.5239 17.5417 20.9637 17.5417 20.2721V19.7725Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5257 2.41357C9.5257 3.77925 10.6328 4.88635 11.9985 4.88635C13.3642 4.88635 14.4713 3.77925 14.4713 2.41357H15.9713C15.9713 4.60768 14.1926 6.38635 11.9985 6.38635C9.80437 6.38635 8.0257 4.60768 8.0257 2.41357H9.5257Z"
        fill={color}
      />
    </SvgIcon>
  )
}
