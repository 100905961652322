import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import React from 'react'

import * as S from '@/components/Form/SignInForm.styled'

import { MODAL_NAME } from '@/constants/modalName'
import { AUTH_PATHS } from '@/constants/routes'

import { useModalContext } from '@/context'

import { useLaptop } from '@/hooks'

import { ArrowIcon } from '@/icons'

import { useAuthVariablesStore } from '@/store'

import { FormConfirmHeader } from '../FormConfirmHeader/FormConfirmHeader'

export const SuccessPasswordForm = () => {
  const router = useRouter()
  const { t } = useTranslation()
  const { isOverLaptop } = useLaptop()
  const { onOpenModal } = useModalContext()

  const [clearEmail, clearCode] = useAuthVariablesStore(state => [state.clearEmail, state.clearCode])

  const handleClick = () => {
    clearEmail()
    clearCode()
    if (!isOverLaptop) {
      return router.push(AUTH_PATHS.signIn)
    } else {
      onOpenModal(MODAL_NAME.signIn)
    }
  }

  return (
    <S.FormWrapper>
      <FormConfirmHeader title={t('app.auth.success')} subTitle={t('app.auth.new_password_successfully')} />
      <S.ButtonFW onClick={handleClick} endIcon={<ArrowIcon />} sx={{ maxWidth: '278px', alignSelf: 'center' }}>
        {t('app.sign_in')}
      </S.ButtonFW>
    </S.FormWrapper>
  )
}
