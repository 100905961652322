import SvgIcon from '@mui/material/SvgIcon'
import React from 'react'

import { IconsTypes } from '@/types/baseTypes'

export const CloseIcon = ({ size = '18', color = 'white' }: IconsTypes) => {
  return (
    <SvgIcon
      width={size}
      height={size}
      style={{
        position: 'absolute',
        top: '16px',
        insetInlineEnd: '20px',
        zIndex: '1301',
        pointerEvents: 'none',
      }}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.999968 17L17 1" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
      <path d="M17 17L1 1" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
    </SvgIcon>
  )
}
