import { Box, styled } from '@mui/material'
import Link, { LinkProps } from 'next/link'
import { useRouter } from 'next/router'

export const NavLink = styled(Link)<LinkProps>(({ href, theme }) => {
  const { pathname } = useRouter()
  return {
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '22px',
    color: pathname !== href ? theme.palette.ui.grey[700] : theme.palette.ui.primary.main,
    textDecoration: 'none',
    [theme.breakpoints.down('lg')]: {
      lineHeight: '36px',
    },
  }
})

export const NavigationBlock = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '23px',
  justifyContent: 'space-between',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    width: '100%',
    minWidth: '260px',
    padding: '16px 20px 0 20px',
    gap: '0',
  },
}))
