import { ComponentsOverrides, ComponentsProps, ComponentsVariants } from '@mui/material'

import { palette } from '../palette'

export default {
  variants: [
    {
      props: { variant: 'determinate' },
      style: { height: '3px', background: palette.ui.grey[200], borderRadius: '30px' },
    },
  ],
} as {
  defaultProps?: ComponentsProps['MuiLinearProgress']
  styleOverrides?: ComponentsOverrides<unknown>['MuiLinearProgress']
  variants?: ComponentsVariants['MuiLinearProgress']
}
