import { useRouter } from 'next/router'
import React from 'react'

import { AuthLayout } from '@/components/Layout/AuthLayout'
import { MainLayout } from '@/components/Layout/MainLayout'

import { authIncludeLayout } from '@/constants/routes'

interface Props {
  children: React.ReactNode
}

export const LayoutProvider = ({ children }: Props) => {
  const { pathname } = useRouter()

  if (authIncludeLayout.includes(pathname)) {
    return <AuthLayout>{children}</AuthLayout>
  } else {
    return <MainLayout>{children}</MainLayout>
  }
}
