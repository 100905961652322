import { ComponentsOverrides, ComponentsProps, ComponentsVariants } from '@mui/material'

export default {
  styleOverrides: {
    root: {
      marginRight: 0,
      marginLeft: 0,
    },
  },
} as {
  defaultProps?: ComponentsProps['MuiFormControlLabel']
  styleOverrides?: ComponentsOverrides<unknown>['MuiFormControlLabel']
  variants?: ComponentsVariants['MuiFormControlLabel']
}
