import { ResponsiveStyleValue } from '@mui/system'
import React from 'react'

import { AlertType } from '@/types/baseTypes'

import * as S from './Alert.styled'

interface Props {
  type: AlertType
  children?: React.ReactNode
  mt?: ResponsiveStyleValue<string>
  mb?: ResponsiveStyleValue<string>
  props?: any
}

export const Alert = ({ type, children, mt, ...props }: Props) => {
  return (
    <S.Alert type={type} {...props} mt={mt}>
      {children}
    </S.Alert>
  )
}
