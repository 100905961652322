import { Box, Snackbar as MuiSnackbar, Typography } from '@mui/material'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import React from 'react'

import { useAlertContext } from '@/context'

export const Snackbar = () => {
  const { open, onCloseAlert, content } = useAlertContext()
  const { error, title, subTitle } = content || {}
  const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => (
    <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  ))

  return (
    <MuiSnackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      sx={{
        width: 'fit-content',
        '.MuiPaper-root': {
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          '& .MuiAlert-icon': { padding: 0, margin: 0 },
          '& .MuiAlert-action': {
            padding: 0,
            marginInlineEnd: '-5px',
          },
        },
      }}
      open={open}
      autoHideDuration={5000}
      onClose={onCloseAlert}
    >
      <Alert
        onClose={onCloseAlert}
        severity={error ? 'error' : 'success'}
        sx={{
          width: 'fit-content',
          maxWidth: '600px',
          background: error ? 'rgba(240,55,88,0.8)' : 'rgba(70,99,244,0.8)',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {title && <Typography variant="h6">{title}</Typography>}
          {subTitle && <Typography variant="body2">{subTitle}</Typography>}
        </Box>
      </Alert>
    </MuiSnackbar>
  )
}
