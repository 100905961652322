import { StateSelector } from 'zustand'
import shallow from 'zustand/shallow'

import { useAuthVariablesStore as useAuthVariablesStoreDef, useAuthVariablesStoreProps } from '@/store/authVariables'
import {
  useIsDisabledProductRowStore as useIsDisabledProductRowStoreDef,
  useIsDisabledProductRowStoreProps,
} from '@/store/isDisabledProductRow'

export const useAuthVariablesStore: <T>(selector: StateSelector<useAuthVariablesStoreProps, T>) => T = selector =>
  useAuthVariablesStoreDef(selector, shallow)

export const useIsDisabledProductRowStore: <T>(
  selector: StateSelector<useIsDisabledProductRowStoreProps, T>,
) => T = selector => useIsDisabledProductRowStoreDef(selector, shallow)
