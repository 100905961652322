import { ComponentsOverrides, ComponentsProps, ComponentsVariants } from '@mui/material'

import { palette } from '../palette'

export default {
  styleOverrides: {
    root: {
      padding: '0 30px',
      flexDirection: 'row-reverse',
      '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
      },
      gap: '12px',
      '& .MuiAccordionSummary-content': {
        margin: '24px 0',
      },
    },
  },
} as {
  defaultProps?: ComponentsProps['MuiAccordionSummary']
  styleOverrides?: ComponentsOverrides<unknown>['MuiAccordionSummary']
  variants?: ComponentsVariants['MuiAccordionSummary']
}
