import SvgIcon from '@mui/material/SvgIcon'
import React from 'react'

import { palette } from '@/theme/palette'

import { IconsTypes } from '@/types/baseTypes'

export const ProfileIcon = ({ size = '24', color = palette.ui.primary.main }: IconsTypes) => {
  return (
    <SvgIcon width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_0001222)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.990906 7.21241C0.990906 3.77637 3.77637 0.990906 7.21241 0.990906H16.7875C20.2236 0.990906 23.0091 3.77637 23.0091 7.21241V16.7875C23.0091 20.2236 20.2236 23.0091 16.7875 23.0091H7.21241C3.77637 23.0091 0.990906 20.2236 0.990906 16.7875V7.21241ZM7.21241 2.49091C4.6048 2.49091 2.49091 4.6048 2.49091 7.21241V16.7875C2.49091 19.3952 4.6048 21.5091 7.21241 21.5091H16.7875C19.3952 21.5091 21.5091 19.3952 21.5091 16.7875V7.21241C21.5091 4.6048 19.3952 2.49091 16.7875 2.49091H7.21241ZM12 6.59453C10.5255 6.59453 9.33029 7.7898 9.33029 9.26423C9.33029 10.7387 10.5255 11.9339 12 11.9339C13.4744 11.9339 14.6697 10.7387 14.6697 9.26423C14.6697 7.7898 13.4744 6.59453 12 6.59453ZM7.83029 9.26423C7.83029 6.96137 9.69712 5.09453 12 5.09453C14.3028 5.09453 16.1697 6.96137 16.1697 9.26423C16.1697 11.5671 14.3028 13.4339 12 13.4339C9.69712 13.4339 7.83029 11.5671 7.83029 9.26423ZM12.0043 16.3933C9.26363 16.3933 8.16073 16.9411 7.6166 17.5707C7.34577 17.8842 6.87215 17.9187 6.55874 17.6478C6.24534 17.377 6.21083 16.9034 6.48166 16.59C7.46556 15.4514 9.13978 14.8933 12.0043 14.8933C14.8997 14.8933 16.4753 15.4811 17.5279 16.5555C17.8178 16.8514 17.8129 17.3263 17.517 17.6161C17.2211 17.906 16.7463 17.9011 16.4564 17.6052C15.8085 16.9438 14.7139 16.3933 12.0043 16.3933Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_0001222">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}
