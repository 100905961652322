import React, { createContext, useContext } from 'react'

import { SITE_ID } from '@/constants/env'

interface Props {
  children: React.ReactNode
}

interface ValueT {
  siteId: number
  isDefaultWallet: boolean
}

export const SiteIdContext = createContext<ValueT>({
  siteId: 1,
  isDefaultWallet: true,
})

export const SiteIdProvider = ({ children }: Props) => {
  const value = {
    siteId: Number(SITE_ID) || 1,
    isDefaultWallet: '1',
  }

  return <SiteIdContext.Provider value={value as any}>{children}</SiteIdContext.Provider>
}

export const useSiteIdContext = () => {
  const siteIdContext = useContext(SiteIdContext)

  if (!siteIdContext) {
    throw new Error('useAlertContext must be used within a AlertProvider')
  }

  return siteIdContext
}
