import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { DefaultTFuncReturn } from 'i18next'
import { useTranslation } from 'next-i18next'
import React, { useEffect, useState } from 'react'
import ar from 'react-phone-input-2/lang/ar.json'

import { VerifyBadge } from '@/components/VerifyBadge/VerifyBadge'

import { useCurrentLanguage } from '@/hooks/useCurrentLanguage'

import { getCountryByLabel } from '@/utils'

import * as S from './PhoneInput.styled'

interface Props {
  value?: string
  verified?: boolean
  onChange?: (x: any) => void
  label?: string | DefaultTFuncReturn
  placeholder?: string | DefaultTFuncReturn
  country?: string
}

export const PhoneInput = ({ verified = false, label, onChange, placeholder = '', value = '', country }: Props) => {
  const { i18n } = useTranslation()
  const { currentLanguage } = useCurrentLanguage()
  const [isVisible, setIsVisible] = useState(true)

  useEffect(() => {
    setIsVisible(false)

    setTimeout(() => {
      setIsVisible(true)
    }, 1) // TODO: potential bug remove setTimeout
  }, [currentLanguage])

  return (
    <>
      <Typography component="p" sx={{ marginBottom: '6px' }} variant="body2">
        {label}
      </Typography>

      <Box sx={{ position: 'relative', height: '48px' }}>
        {isVisible && (
          <S.PhoneInput
            localization={currentLanguage == 'ar' ? ar : {}}
            countryCodeEditable={false}
            specialLabel=""
            placeholder={placeholder as string}
            value={value}
            onChange={onChange}
            disabled={verified}
            country={!value ? getCountryByLabel(country)?.code : undefined}
          />
        )}

        <S.BadgeWrapper
          sx={{
            cursor: 'default',
            transform: `translate(${i18n.dir() === 'ltr' ? '-14px' : '14px'}, 50%)`,
          }}
        >
          <VerifyBadge verify={verified} />
        </S.BadgeWrapper>
      </Box>
    </>
  )
}
