import * as yup from 'yup'

import { messages as M } from '@/constants/message'

import { getEnding } from '@/utils/getEnding'

export const forgotPasswordSchema = (isENLang: boolean) => {
  return yup.object().shape({
    email: yup.string().email(M.INVALID_MAIL[getEnding(isENLang)]).required(M.REQUIRED[getEnding(isENLang)]),
  })
}
