import { changeLanguage } from 'i18next'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import React, { createContext, useContext, useEffect, useState } from 'react'

import { arabianCountries } from '@/constants/constants'

import { getMyLocationInfo } from '@/utils/getMyLocationInfo'

interface Props {
  children: React.ReactNode
}

type ContextType = {
  currentCurrency: string
  handleCurrentCurrency: (x: string) => void
  show: boolean
}
// TODO: FILE NAME
export const IntlContext = createContext<ContextType>({
  currentCurrency: 'USD',
  handleCurrentCurrency: () => {},
  show: false,
})

export const useIntlContext = () => {
  const alertContext = useContext(IntlContext)

  if (!alertContext) {
    throw new Error('useAlertContext must be used within a AlertProvider')
  }
  return alertContext
}

export const IntlProvider = ({ children }: Props) => {
  const { userCountry } = getMyLocationInfo()
  const isArabianCountry = arabianCountries.includes(userCountry)
  const [currentCurrency, setCurrentCurrency] = useState('USD')
  const { ready } = useTranslation()
  const [show, setShow] = useState(false)
  const router = useRouter()
  const { t, i18n } = useTranslation()
  const currentLanguage = i18n.language

  useEffect(() => {
    // if (typeof window !== 'undefined') {
    //   const currency = localStorage.getItem('webmarket-currency')
    //   if (currency) {
    //     setCurrentCurrency(currency)
    //   } else if (isArabianCountry) {
    //     setCurrentCurrency('AED')
    //   } else {
    //     setCurrentCurrency('USD')
    //   }
    // }
  }, [])

  useEffect(() => {
    if (ready) {
      setShow(true)
    }
  }, [ready])

  useEffect(() => {
    document.documentElement.dir = i18n.dir()
    document.documentElement.lang = i18n.language
  }, [currentLanguage, i18n, t])

  useEffect(() => {
    changeLanguage(router.locale)
  }, [router.locale])

  useEffect(() => {
    localStorage.setItem('webmarket-currency', currentCurrency)
  }, [currentCurrency])

  const handleCurrentCurrency = (str: string) => {
    setCurrentCurrency(str)
  }

  const value = {
    currentCurrency,
    handleCurrentCurrency,
    show,
  }

  const data = ['/', '/product/[id]']
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isSsr = data.includes(router.pathname)

  return <IntlContext.Provider value={value}>{children}</IntlContext.Provider>
}
