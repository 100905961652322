import { useTranslation } from 'next-i18next'

// import { useRouter } from 'next/router'

export const useCurrentLanguage = () => {
  const { i18n } = useTranslation()

  // const { locale } = useRouter()
  // const isENLang = locale === 'en'
  const isENLang = true

  return { currentLanguage: i18n.language, isENLang }
}
