import { styled } from '@mui/material'
import Box from '@mui/material/Box'

export const Cart = styled(Box)(({ theme }) => ({
  paddingBottom: '150px',
  display: 'flex',
  flexDirection: 'column',
  minHeight: '87vh',
  [theme.breakpoints.down('lg')]: {
    paddingBottom: '80px',
  },
}))

export const FlexRow = styled(Box)(({ theme, gap }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '30px',
  marginBottom: '24px',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    gap: gap || '12px',
    marginTop: '24px',
    marginBottom: '20px',
  },
}))

export const Content = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  [theme.breakpoints.down('lg')]: {
    gap: '8px',
  },
}))
