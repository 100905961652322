import * as yup from 'yup'

import { messages as M } from '@/constants/message'

import { getEnding } from '@/utils/getEnding'

export const profileChangePasswordSchema = (isENLang: boolean) =>
  yup.object().shape({
    oldPassword: yup.string().required(M.REQUIRED[getEnding(isENLang)]),
    newPassword: yup.string().required(M.REQUIRED[getEnding(isENLang)]),
  })
