import { styled } from '@mui/material'
import Box from '@mui/material/Box'

export const TitleRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '& :nth-of-type(1)': {
    flex: '1 1 800px',
  },
  '& :nth-of-type(2)': {
    flex: '1 1 350px',
  },
  '& :nth-of-type(3)': {
    flex: '1 1 283px',
  },
  '& :nth-of-type(4)': {
    // flex: '1 1 149px',
    marginInlineEnd: '115px',
  },
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
}))
