import { FormLabel, IconButton, TextField as MuiTextField, Typography } from '@mui/material'
import { DefaultTFuncReturn } from 'i18next'
import React from 'react'

import { ClipboardCopyIcon } from '@/icons'

interface Props {
  label?: string | DefaultTFuncReturn
  value?: string | number
  size?: 'small' | 'medium'
  clickCopy?: () => void
  [x: string]: any
}

export const TextField = ({ label, value, size = 'small', clickCopy, ...props }: Props) => {
  const isSizeMedium = size !== 'small'

  const f = (str: string) => {
    if (!str) return { main: '', click: '' }
    const fp = str?.search(/[(]/)
    const lp = str?.search(/[)]/)

    return { main: str.slice(0, fp), click: str.slice(fp, lp + 1) }
  }

  type strObjT = {
    main: string
    click: string
  }
  const strObj: strObjT = f(String(label))

  return (
    <>
      {label ? (
        <FormLabel sx={{ marginBottom: isSizeMedium ? '6px' : '4px' }}>
          <Typography variant="caption">
            {strObj.main}
            {strObj.click && (
              <Typography
                component="span"
                variant="caption"
                color="text.secondary"
                sx={{ cursor: 'pointer' }}
                role="presentation"
                onClick={clickCopy}
              >
                {strObj.click}
              </Typography>
            )}
          </Typography>
        </FormLabel>
      ) : null}
      <MuiTextField
        size={size}
        value={value}
        {...props}
        InputProps={{
          endAdornment: (
            <IconButton size="medium" onClick={clickCopy} sx={{ marginInlineEnd: isSizeMedium ? '0' : '-14px' }}>
              <ClipboardCopyIcon />
            </IconButton>
          ),
        }}
      />
    </>
  )
}
