import { useTranslation } from 'next-i18next'

import { MODAL_NAME } from '@/constants/modalName'

import { useAlertContext, useAuthContext, useModalContext } from '@/context'

import { useGetCurrentUserQuery, useSendOtpMutation } from '@/graphql/generated'

import { ErrorMessage } from '@/types/baseTypes'

import { getErrorName } from '@/utils'

export const usePhoneVerify = () => {
  const { onOpenModal } = useModalContext()
  const { onOpenAlert } = useAlertContext()
  const { isAuth } = useAuthContext()
  const { t } = useTranslation()

  const { data: currentUserData } = useGetCurrentUserQuery({
    skip: !isAuth,
  })

  const phoneNumber = currentUserData?.getCurrentUser.phoneNumber
  const [sendOtpMutation] = useSendOtpMutation()

  const phoneVerify = (ph?: string) => {
    if (!phoneNumber && !ph) return
    if (currentUserData?.getCurrentUser.phoneIsVerified) {
      return onOpenAlert({
        subTitle: t`app.error.your_phone_is_authorized`,
      })
    }

    sendOtpMutation({
      variables: {
        phoneNumber: phoneNumber || ph || '',
      },
      onCompleted: () => {
        onOpenAlert({ subTitle: t`app.code_has_been_sent` })
        onOpenModal(MODAL_NAME.phoneVerification, { phoneNumber })
      },
      onError: error => {
        onOpenAlert({
          subTitle: getErrorName((error?.graphQLErrors[0]?.extensions?.exception as ErrorMessage)?.name),
          error: true,
        })
      },
    })
  }
  return { phoneVerify }
}
