import { Box, Button, Typography, styled } from '@mui/material'

export const ForgotPasswordTypography = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  insetInlineEnd: '0',
  top: '0',
  cursor: 'pointer',
  color: theme.palette.ui.primary.main,
  fontWeight: '300',
  fontSize: '15px',
  lineHeight: '22px',
  [theme.breakpoints.down('lg')]: {
    fontSize: '14px',
    lineHeight: '21px',
  },
}))

export const ButtonFW = styled(Button)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  [theme.breakpoints.down('lg')]: {},
}))

export const FormWrapper = styled(Box)(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    [theme.breakpoints.down('lg')]: {},
  }
})

export const Google = styled(Button)(({ theme }) => {
  return {
    width: '100%',
    borderColor: theme.palette.ui.grey[800],
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '22px',
    color: theme.palette.ui.grey[700],
    svg: {
      marginInlineEnd: '12px',
    },
    ':hover': {
      borderColor: theme.palette.ui.grey[800],
    },
    marginBottom: '20px',
    [theme.breakpoints.down('lg')]: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '21px',
      marginBottom: '16px',
      height: '42px',
      svg: {
        width: '20px',
        height: '20px',
      },
    },
  }
})

export const LinkTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.ui.primary.main,
  cursor: 'pointer',
  WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
  a: {
    color: theme.palette.ui.primary.main,
    cursor: 'pointer',
    WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
    textDecoration: 'none',
  },
}))

const isBrowser = typeof window !== 'undefined'

export const AuthCodeWrapper = styled(Box)(({ theme }) => {
  const isMacLike = isBrowser && /(Mac|iPhone|iPod|iPad)/i.test(navigator.userAgent)
  const isIOS = isBrowser && /(iPhone|iPod|iPad)/i.test(navigator.userAgent)

  return {
    width: '225px',
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    gap: '30px',
    '.containerClass': {
      display: 'flex',
      justifyContent: 'center',
      gap: '15px',
    },
    input: {
      width: '45px',
      height: '48px',
      borderRadius: '6px',
      border: `1px solid ${theme.palette.ui.grey[800]}`,

      textAlign: 'center',
      fontWeight: 300,
      fontSize: '14px',
      lineHeight: '21px',
      textTransform: 'uppercase',
      '&:focus-visible': {
        border: `1px solid ${theme.palette.ui.primary.main}`,
        outlineColor: theme.palette.ui.primary.main,
        outlineOffset: isMacLike || isIOS ? '-3px' : '0px',
        outlineStyle: 'auto',
        outlineWidth: '5px',
      },
    },
    [theme.breakpoints.down('lg')]: {
      gap: '24px',
      width: 'auto',
      input: {
        width: '46px',
        height: '42px',
      },
    },
  }
})

export const NavLink = styled(Typography)(({ theme }) => ({
  cursor: 'pointer',
  color: theme.palette.ui.primary.main,
  fontWeight: '300',
  fontSize: '15px',
  lineHeight: '22px',
  [theme.breakpoints.down('lg')]: {
    fontSize: '14px',
    lineHeight: '21px',
  },
}))
