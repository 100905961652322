import { Box, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { DefaultTFuncReturn } from 'i18next'
import React from 'react'

interface Props {
  title?: string | DefaultTFuncReturn
  subTitle?: string | DefaultTFuncReturn
}

export const FormHeader = ({ title = '', subTitle = '' }: Props) => {
  const theme = useTheme()
  return (
    <>
      <Box mb="8px">
        <Typography variant="h3">{title}</Typography>
      </Box>
      <Box mb="20px">
        <Typography color={theme.palette.ui.grey[600]} variant="body2">
          {subTitle}
        </Typography>
      </Box>
    </>
  )
}
