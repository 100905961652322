import { Box, Skeleton, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'next-i18next'
import React from 'react'

import { useIntlContext } from '@/context/IntlContext'

import { CartProductSchema } from '@/graphql/generated'

import { CurrencyType } from '@/types/baseTypes'

import { getResultTotalCart } from '@/utils'

interface Props {
  currency: string
  exchangeRatesData: any
  productData: CartProductSchema[]
  loading: boolean
}

export const TotalValueToCart = ({ currency, exchangeRatesData, productData, loading }: Props) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { currentCurrency } = useIntlContext()

  const total = getResultTotalCart(productData, currentCurrency as CurrencyType, exchangeRatesData)
  const totalText = String(total)

  return (
    <Box
      sx={{
        mt: 4,
        marginLeft: 'auto',
      }}
    >
      <Typography variant="body2" color={theme.palette.ui.grey[600]}>
        {t('app.cart.total')}
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Typography fontWeight="bold" component="span" variant="h6">
          {loading ? <Skeleton width={`${totalText.length}ch`} /> : totalText}
        </Typography>
        <Typography component="span" variant="h6">
          {currency}
        </Typography>
      </Box>
    </Box>
  )
}
