import { Box, TextField } from '@mui/material'
import React, { useState } from 'react'

import { CircularProgress } from '@/components'

import { countriesData } from '@/constants/constants'

import { useLaptop } from '@/hooks'

import { CloseDialogIcon, SearchIcon } from '@/icons'

import * as S from './MultiSearch.styled'
import { CountrySelect } from '../CountrySelect/CountrySelect'
import { Select } from '../Select/Select'

interface Props {
  loading: boolean
  textValue: string
  setTextValue: (str: string) => void
  countriesValue: string
  setCountriesValue: (str: string) => void
  placeholderTextField: string
  categoryFilterData: any
  setCategoryFilterData: (str: string) => void
  categoryFilterValue: string
}

export const MultiSearch = ({
  loading = false,
  textValue = '',
  setTextValue = () => {},
  countriesValue,
  setCountriesValue = () => {},
  placeholderTextField = 'Product search...',
  categoryFilterData = [],
  setCategoryFilterData,
  categoryFilterValue,
}: Props) => {
  const [focus, setFocus] = useState(false)
  const border = focus || !!textValue || !!countriesValue
  const { isOverLaptop } = useLaptop()

  const clearCountryField = () => {
    setCountriesValue('')
  }

  return isOverLaptop ? (
    <>
      <S.MultiSearch border={border ? 'border' : ''}>
        <TextField
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          placeholder={placeholderTextField}
          value={textValue}
          onChange={e => setTextValue(e.target.value)}
          fullWidth
          size="medium"
          InputProps={{
            startAdornment: <SearchIcon />,
          }}
          sx={{
            '.MuiOutlinedInput-notchedOutline': {
              border: 'none !important',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              border: 'none !important',
            },
          }}
        />
        <Box display="flex">
          <CircularProgress loading={loading} />
        </Box>
        <S.SelectBlock>
          <S.Divider />

          <CountrySelect
            options={countriesData}
            value={countriesValue}
            setValue={setCountriesValue}
            setFocus={setFocus}
          />

          {countriesValue && (
            <S.SelectClose onClick={clearCountryField}>
              <CloseDialogIcon size="18" />
            </S.SelectClose>
          )}
        </S.SelectBlock>
      </S.MultiSearch>
    </>
  ) : (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <S.SelectBlockMobile>
        <CountrySelect
          options={countriesData}
          value={countriesValue}
          setValue={setCountriesValue}
          setFocus={setFocus}
          border={true}
        />
        {countriesValue && (
          <S.SelectClose onClick={clearCountryField}>
            <CloseDialogIcon size="18" />
          </S.SelectClose>
        )}
      </S.SelectBlockMobile>
      <Select
        sx={{ marginBottom: '12px' }}
        option={categoryFilterData}
        setValue={setCategoryFilterData}
        value={categoryFilterValue}
      />
      <TextField
        size="small"
        value={textValue}
        onChange={e => setTextValue(e.target.value)}
        placeholder={placeholderTextField}
        sx={{ '.MuiInputBase-root': { height: '42px' } }}
        disabled={false}
        InputProps={{
          startAdornment: <SearchIcon />,
        }}
      />
    </Box>
  )
}
