import { CircularProgress as MuiCircularProgress } from '@mui/material'
import React from 'react'

import { palette } from '@/theme/palette'

interface Props {
  loading: boolean
  color?: string
  size?: string
}

export const CircularProgress = ({ loading, color = palette.ui.grey[600], size = '24px' }: Props) => {
  if (!loading) return null
  return <MuiCircularProgress style={{ color: color, width: size, height: size }} />
}
