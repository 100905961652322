import { Accordion as MuiAccordion } from '@mui/material'
import { DefaultTFuncReturn } from 'i18next'
import React from 'react'

import { ChevronDownIcon } from '@/icons'

import * as S from './Accordion.styled'

interface Props {
  defaultExpanded?: boolean
  title?: string | DefaultTFuncReturn
  subtitle?: string | DefaultTFuncReturn
  subtitle2?: string | DefaultTFuncReturn
}

export const Accordion = ({ defaultExpanded = false, title = '', subtitle = '', subtitle2 = '' }: Props) => {
  return (
    <>
      <MuiAccordion defaultExpanded={defaultExpanded} variant="elevation" disableGutters>
        <S.AccordionSummary expandIcon={<ChevronDownIcon sx={{ rotate: '-90deg' }} />}>
          <S.TitleTypography variant="h6">{title}</S.TitleTypography>
        </S.AccordionSummary>
        <S.AccordionDetails>
          <S.DescriptionTypography as="p" mb={1}>
            {subtitle}
          </S.DescriptionTypography>
          {subtitle2 && <S.DescriptionTypography as="p">{subtitle2}</S.DescriptionTypography>}
        </S.AccordionDetails>
      </MuiAccordion>
    </>
  )
}
