import SvgIcon from '@mui/material/SvgIcon'
import React from 'react'

import { palette } from '@/theme/palette'

import { IconsTypes } from '@/types/baseTypes'

export const CartIcon = ({ color = palette.ui.grey[600] }: IconsTypes) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_10_65)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.64106 5.19651C1.76129 2.8424 3.69845 0.990906 6.05307 0.990906H17.947C20.3016 0.990906 22.2387 2.8424 22.359 5.19651L23.0025 17.7968C23.1471 20.6283 20.8989 23.0091 18.0672 23.0091H5.93281C3.10116 23.0091 0.852899 20.6283 0.997511 17.7968L1.64106 5.19651ZM6.05307 2.49091C4.50235 2.49091 3.21887 3.71135 3.13911 5.27302L2.49556 17.8733C2.39443 19.8535 3.96571 21.5091 5.93281 21.5091H18.0672C20.0343 21.5091 21.6056 19.8535 21.5045 17.8733L20.8609 5.27302C20.7812 3.71135 19.4977 2.49091 17.947 2.49091H6.05307ZM7.2883 4.21994C7.70251 4.21994 8.0383 4.55573 8.0383 4.96994C8.0383 7.09253 9.79785 8.83863 12 8.83863C14.2022 8.83863 15.9617 7.09253 15.9617 4.96994C15.9617 4.55573 16.2975 4.21994 16.7117 4.21994C17.1259 4.21994 17.4617 4.55573 17.4617 4.96994C17.4617 7.94902 15.0023 10.3386 12 10.3386C8.99776 10.3386 6.5383 7.94902 6.5383 4.96994C6.5383 4.55573 6.87409 4.21994 7.2883 4.21994Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_10_65">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}
