import { Typography } from '@mui/material'
import { useTheme } from '@mui/material'
import { useTranslation } from 'next-i18next'
import React from 'react'

import * as S from './TableHeadRowMyOrder.styled'

export const TableHeadRowMyOrder = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  return (
    <S.TableHeadRowMyOrder>
      <Typography variant="body2" color={theme.palette.ui.grey[600]}>
        {t('app.cart.product')}
      </Typography>
      <Typography variant="body2" color={theme.palette.ui.grey[600]}>
        {t('app.cart.date')}
      </Typography>
      <Typography variant="body2" color={theme.palette.ui.grey[600]}>
        {t('app.cart.voucher_code')}
      </Typography>
    </S.TableHeadRowMyOrder>
  )
}
