import { SvgIcon, useTheme } from '@mui/material'
import React from 'react'

export const CheckedCheckBoxIcon = () => {
  const theme = useTheme()

  return (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="20" rx="6" fill={theme.palette.ui.primary.main} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0404 6.79289C14.431 7.18342 14.431 7.81658 14.0404 8.20711L9.4571 12.7904C9.26956 12.978 9.01521 13.0833 8.74999 13.0833C8.48477 13.0833 8.23042 12.978 8.04288 12.7904L5.95955 10.7071C5.56903 10.3166 5.56903 9.68342 5.95955 9.29289C6.35007 8.90237 6.98324 8.90237 7.37376 9.29289L8.74999 10.6691L12.6262 6.79289C13.0167 6.40237 13.6499 6.40237 14.0404 6.79289Z"
        fill="white"
      />
    </SvgIcon>
  )
}
