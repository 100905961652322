import { Box, Button, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import React from 'react'

import { PATHS } from '@/constants/routes'

import { CartEmptyIcon } from '@/icons'

import * as S from './EmptyCart.styled'

export const EmptyCart = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const router = useRouter()

  return (
    <S.EmptyCart>
      <Box sx={{ mb: '19px', textAlign: 'center' }}>
        <CartEmptyIcon />
      </Box>
      <Typography mb="10px" variant="h6">
        {t`app.cart.cart_is_empty`}
      </Typography>
      <Typography mb="20px" variant="body2" color={theme.palette.ui.grey[600]}>
        {t`app.cart.lets_get_to_shopping`}.
      </Typography>
      <Button onClick={() => router.push(PATHS.home)} sx={{ width: '100%' }}>
        {t`app.go_to_products`}
      </Button>
    </S.EmptyCart>
  )
}
