import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { createUploadLink } from 'apollo-upload-client'
import create from 'zustand'

import { API_URL, SITE_ID } from '@/constants/env'

import { getAuthToken, removeAuthToken } from '@/utils/authToken'

type ErrorType = {
  hasError: boolean
  error: any
  date: number | undefined
}

export const useErrorsStore = create<ErrorType>(() => ({
  hasError: false,
  error: undefined,
  date: undefined,
}))

const httpLink = createUploadLink({
  uri: API_URL,
})

const authLink = setContext((_, { headers }) => {
  let token

  if (typeof window !== 'undefined') {
    token = getAuthToken()
  }

  return {
    headers: {
      ...headers,
      'site-id': SITE_ID,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const errorLink = onError(({ networkError, graphQLErrors }) => {
  if (graphQLErrors) {
    for (const graphQLError of graphQLErrors) {
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.log('graphQLError: ', graphQLError)
      }

      if (graphQLError.message === 'Context creation failed: INVALID_TOKEN') {
        removeAuthToken()
      }

      useErrorsStore.setState({
        hasError: true,
        error: graphQLError,
        date: Date.now(),
      })
    }
  } else if (networkError) {
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.log('networkError: ', networkError)
    }

    useErrorsStore.setState({
      hasError: true,
      error: networkError,
      date: Date.now(),
    })
  }
})

export const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache(),
})
