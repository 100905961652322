import { Button, styled } from '@mui/material'
import Box from '@mui/material/Box'

export const BottomCartNavigate = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '80px',
  padding: '0 20px',
  position: 'fixed',
  left: 0,
  right: 0,
  bottom: 0,
  boxShadow: '0px -4px 32px rgba(150, 160, 181, 0.1)',
  background: '#FFF',
  [theme.breakpoints.down('lg')]: {
    height: '54px',
    padding: '0 8px',
  },
}))

export const InnerWrapper = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  maxWidth: '1200px',
}))

export const ButtonCart = styled(Button)(({ theme }) => ({
  height: '40px',

  [theme.breakpoints.down('lg')]: {
    fontSize: 0,
    width: '40px',
    minWidth: '40px',
    '.MuiButton-startIcon': {
      marginInlineEnd: 0,
      marginLeft: '0',
    },
    svg: {
      width: '20px',
      height: '20px',
    },
  },
}))
