import { Divider, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'next-i18next'

import * as S from './OrDivider.styled'

interface Props {
  [key: string]: any
}

export const OrDivider = ({ ...props }: Props) => {
  const theme = useTheme()

  const { t } = useTranslation()
  return (
    <S.OrDivider {...props}>
      <Divider sx={{ width: '100%' }} />
      <Typography variant="body2" color={theme.palette.ui.grey[600]}>
        {t('app.auth.or')}
      </Typography>
      <Divider sx={{ width: '100%' }} />
    </S.OrDivider>
  )
}
