import { Typography, styled } from '@mui/material'
import Box from '@mui/material/Box'
import Link, { LinkProps } from 'next/link'
import { useRouter } from 'next/router'

export const ProfileLayout = styled(Box)(({ theme }) => {
  return {
    display: 'flex',
    flex: 'auto',
    position: 'relative',
    maxWidth: '1240px',
    width: '100%',
    alignSelf: 'center',
    [theme.breakpoints.down('lg')]: {},
  }
})

export const Aside = styled(Box)(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '276px',
    borderInlineEnd: `1px solid ${theme.palette.ui.grey[200]}`,
    a: {
      marginBottom: '28px',
    },
    padding: '60px 0 30px 0',
    paddingInlineEnd: '30px',
    minHeight: '700px',
    [theme.breakpoints.down('lg')]: {
      minHeight: 'initial',
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      height: '60px',
      zIndex: 1000,
      background: theme.palette.base.white,
      flexDirection: 'row',
      width: 'auto',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0 20px',
      boxShadow: '0px -4px 32px rgba(150, 160, 181, 0.1)',
      a: {
        marginBottom: '0',
      },
    },
  }
})

export const ProfileNavLink = styled(Link)<LinkProps>(({ href, theme }) => {
  const { pathname } = useRouter()
  return {
    display: 'inline-flex',
    alignItems: 'center',
    gap: '16px',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '20px',
    color: pathname !== href ? theme.palette.ui.grey[700] : theme.palette.ui.primary.main,
    textDecoration: 'none',
    letterSpacing: '-0.01em',
    WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
    svg: {
      path: {
        fill: pathname !== href ? theme.palette.ui.grey[700] : theme.palette.ui.primary.main,
      },
    },
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
      gap: '1px',
      fontWeight: 400,
      fontSize: '11px',
      color: pathname !== href ? theme.palette.ui.grey[600] : theme.palette.ui.primary.main,
      lineHeight: '20px',
      letterSpacing: '-0.01em',
      svg: {
        width: '20px',
        height: '20px',
        path: {
          fill: pathname !== href ? theme.palette.ui.grey[600] : theme.palette.ui.primary.main,
        },
      },
    },
  }
})

export const LinkTypography = styled(Typography)(({ theme }) => {
  return {
    display: 'inline-flex',
    alignItems: 'center',
    gap: '16px',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '20px',
    textDecoration: 'none',
    letterSpacing: '-0.01em',
    WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
    cursor: 'pointer',

    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
      gap: '1px',
      fontWeight: 400,
      fontSize: '11px',
      lineHeight: '20px',
      letterSpacing: '-0.01em',
      svg: {
        width: '20px',
        height: '20px',
      },
    },
  }
})

export const Content = styled(Box)(({ theme }) => ({
  paddingTop: '60px',
  paddingInlineStart: '60px',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  overflow: 'hidden',
  [theme.breakpoints.down('lg')]: {
    padding: '24px 0 87px 0',
  },
}))
