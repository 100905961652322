import { useTheme } from '@mui/material'
import Image from 'next/image'
import React from 'react'

import { BASE_URL } from '@/constants/env'

import { Maybe, ProductEntity } from '@/graphql/generated'

import { useCurrentLanguage } from '@/hooks/useCurrentLanguage'

import * as S from './CardItem.styled'

interface Props {
  data: ProductEntity
  onClick: (val: string) => void
  favoriteClick?: (val: React.MouseEvent) => void
  badge: boolean
  imgToken?: Maybe<string>
}

export const CardItem = ({ data, onClick = () => {}, badge = false, imgToken }: Props) => {
  const { isENLang } = useCurrentLanguage()
  const theme = useTheme()

  return (
    <S.CardItem onClick={() => onClick(data?.id)} role="presentation" component="article">
      <S.ProductBlock>
        {imgToken && (
          <Image
            alt=""
            src={`${BASE_URL}/uploads/images/products/${imgToken}`}
            width={1}
            height={1}
            priority={true}
            sizes="auto"
          />
        )}
      </S.ProductBlock>
      <S.DescriptionBlock>
        <S.DescriptionTypography variant="subtitle1">{isENLang ? data.nameEN : data.nameAR}</S.DescriptionTypography>
        <S.DescriptionTypography variant="body2" color={theme.palette.ui.grey[600]}>
          {isENLang ? data.shortDescriptionEN : data.shortDescriptionAR}
        </S.DescriptionTypography>
      </S.DescriptionBlock>

      {/*! WHEN YOU NEED THE CURRENT COMPONENT JUST UNCOMMENT THIS CODE */}
      {/*<FavoriteButton sx={{ position: 'absolute' }} onClick={favoriteClick}>*/}
      {/*  <FavoriteIcon />*/}
      {/*</FavoriteButton>*/}
      {badge ? <S.BadgeItem /> : null}
    </S.CardItem>
  )
}
