import { KycLevelsSchema } from '@/graphql/generated'

export const getAllValue = (myKycLvl: any, kycData?: KycLevelsSchema) => {
  if (!kycData) return 0
  switch (myKycLvl) {
    case 0:
      return kycData.limitLevel1
    case 1:
      return kycData.limitLevel2
    case 2:
      return kycData.limitLevel3
    default:
      return kycData.limitLevel1
  }
}
