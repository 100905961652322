import { SvgIcon } from '@mui/material'
import React from 'react'

import { palette } from '@/theme/palette'

export const RollIcon = () => {
  return (
    <SvgIcon width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.6663 3.33334L32.333 3.33334C31.4489 3.33334 30.6011 3.68453 29.976 4.30965C29.3509 4.93477 28.9997 5.78262 28.9997 6.66667L28.9997 36.6667L22.333 32.5L16.6663 36.6667L10.9997 32.5L7.66634 34.5833L7.66634 7.33334C7.66634 5.1242 9.4572 3.33334 11.6663 3.33334Z"
        fill="white"
      />
      <path
        d="M32.3327 3.33334L7.66601 3.33334C6.78196 3.33334 5.93411 3.68453 5.30899 4.30965C4.68387 4.93477 4.33268 5.78262 4.33268 6.66667L4.33268 36.6667L10.9993 32.5L16.666 36.6667L22.3327 32.5L28.9993 36.6667L28.9993 6.66667C28.9993 5.78262 29.3505 4.93477 29.9757 4.30965C30.6008 3.68453 31.4486 3.33334 32.3327 3.33334ZM32.3327 3.33334C33.2167 3.33334 34.0646 3.68453 34.6897 4.30965C35.3148 4.93477 35.666 5.78261 35.666 6.66667L35.666 21.6667C35.666 23.5 34.166 25 32.3327 25L28.9993 25"
        stroke={palette.ui.grey[600]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 16L23 16"
        stroke={palette.ui.grey[600]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 10L23 10"
        stroke={palette.ui.grey[600]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}
