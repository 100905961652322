import { Box, IconButton } from '@mui/material'
import React from 'react'

import {
  ConfirmEmailForm,
  ConfirmPasswordForm,
  DonePhoneForm,
  ForgotPasswordForm,
  NewPasswordForm,
  PhoneVerificationForm,
  SignInForm,
  SignUpForm,
  SuccessForm,
  SuccessPasswordForm,
} from '@/components/Form'

import { MODAL_NAME } from '@/constants/modalName'

import { useModalContext } from '@/context'

import { CloseDialogIcon } from '@/icons'

import * as S from './ModalContent.styled'

// PHASE_2: don't even wanna think about this now

export const ModalContent = () => {
  const { openModal, onCloseModal } = useModalContext()
  if (!openModal.name) return null

  return (
    <S.Dialog open={openModal.open} onClose={() => onCloseModal()}>
      <S.FormWrapper>
        <TypeModal />
      </S.FormWrapper>
      <S.IconButtonWrapper>
        <IconButton onClick={onCloseModal}>
          <CloseDialogIcon />
        </IconButton>
      </S.IconButtonWrapper>
    </S.Dialog>
  )
}

const TypeModal = () => {
  const { openModal } = useModalContext()

  switch (openModal.name) {
    case MODAL_NAME.signIn:
      return <SignInForm />
    case MODAL_NAME.signUp:
      return <SignUpForm />
    case MODAL_NAME.confirmEmail:
      return <ConfirmEmailForm />
    case MODAL_NAME.success:
      return <SuccessForm />
    case MODAL_NAME.forgotPassword:
      return <ForgotPasswordForm />
    case MODAL_NAME.confirmPasswordForm:
      return <ConfirmPasswordForm />
    case MODAL_NAME.newPassword:
      return <NewPasswordForm />
    case MODAL_NAME.successPassword:
      return <SuccessPasswordForm />
    case MODAL_NAME.phoneVerification:
      return <PhoneVerificationForm />
    case MODAL_NAME.donePhone:
      return <DonePhoneForm />
    default:
      return <Box />
  }
}
