import { Divider, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import React from 'react'

import { DoneSIcon } from '@/icons'

import * as S from './ConfirmationsBlock.styled'

interface Props {
  children: React.ReactNode
  length: number
  index: number
}

export const ConfirmationsBlock = ({ children, length, index }: Props) => {
  return (
    <Box mb="12px">
      <S.ConfirmationsBlock>
        <DoneSIcon />
        <Typography variant="subtitle2">{children}</Typography>
      </S.ConfirmationsBlock>
      {index !== length - 1 && <Divider />}
    </Box>
  )
}
