import SvgIcon from '@mui/material/SvgIcon'
import React from 'react'

import { palette } from '@/theme/palette'

export const BurgerIcon = () => {
  return (
    <SvgIcon width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21 1H1" stroke={palette.ui.grey[600]} strokeWidth="1.5" strokeLinecap="round" />
      <path d="M21 9H5" stroke={palette.ui.grey[600]} strokeWidth="1.5" strokeLinecap="round" />
      <path d="M21 17H1" stroke={palette.ui.grey[600]} strokeWidth="1.5" strokeLinecap="round" />
    </SvgIcon>
  )
}
