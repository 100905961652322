import SvgIcon from '@mui/material/SvgIcon'
import React from 'react'

import { palette } from '@/theme/palette'

export const CoinsIcon = () => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2.75C5.10051 2.75 2.75 5.10051 2.75 8C2.75 10.8995 5.10051 13.25 8 13.25C10.8995 13.25 13.25 10.8995 13.25 8C13.25 5.10051 10.8995 2.75 8 2.75ZM1.25 8C1.25 4.27208 4.27208 1.25 8 1.25C11.7279 1.25 14.75 4.27208 14.75 8C14.75 11.7279 11.7279 14.75 8 14.75C4.27208 14.75 1.25 11.7279 1.25 8ZM6.25 6C6.25 5.58579 6.58579 5.25 7 5.25H8C8.41421 5.25 8.75 5.58579 8.75 6V10C8.75 10.4142 8.41421 10.75 8 10.75C7.58579 10.75 7.25 10.4142 7.25 10V6.75H7C6.58579 6.75 6.25 6.41421 6.25 6ZM17.3872 10.108C17.5319 9.71988 17.9639 9.52255 18.352 9.66725C19.4155 10.0637 20.3618 10.722 21.1035 11.5811C21.8451 12.4402 22.3582 13.4725 22.5952 14.5825C22.8322 15.6924 22.7855 16.8442 22.4594 17.9313C22.1333 19.0185 21.5384 20.0058 20.7296 20.8021C19.9208 21.5983 18.9243 22.1778 17.8322 22.4869C16.7402 22.796 15.5878 22.8247 14.4816 22.5704C13.3755 22.3161 12.3514 21.787 11.5039 21.032C10.6565 20.277 10.0131 19.3205 9.63325 18.251C9.49462 17.8607 9.69867 17.4319 10.089 17.2932C10.4793 17.1546 10.9081 17.3587 11.0468 17.749C11.3422 18.5809 11.8426 19.3248 12.5017 19.912C13.1609 20.4992 13.9574 20.9107 14.8177 21.1085C15.678 21.3063 16.5743 21.284 17.4237 21.0436C18.2731 20.8032 19.0482 20.3525 19.6773 19.7332C20.3063 19.1138 20.769 18.3459 21.0227 17.5004C21.2763 16.6548 21.3126 15.759 21.1283 14.8957C20.9439 14.0324 20.5449 13.2295 19.968 12.5613C19.3912 11.8931 18.6551 11.3811 17.828 11.0728C17.4399 10.9281 17.2426 10.4961 17.3872 10.108ZM16.1834 13.3459C16.4784 13.0551 16.9533 13.0585 17.2441 13.3534L17.9441 14.0634C18.2334 14.3569 18.2318 14.8289 17.9403 15.1203L15.1203 17.9403C14.8274 18.2332 14.3526 18.2332 14.0597 17.9403C13.7668 17.6474 13.7668 17.1726 14.0597 16.8797L16.3531 14.5863L16.1759 14.4066C15.8851 14.1116 15.8885 13.6367 16.1834 13.3459Z"
        fill={palette.ui.grey[600]}
      />
    </SvgIcon>
  )
}
