import { Box, Divider, IconButton, Skeleton, Typography, useTheme } from '@mui/material'
import { DefaultTFuncReturn } from 'i18next'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import React, { useCallback, useEffect } from 'react'

import { BASE_URL } from '@/constants/env'

import { useAlertContext } from '@/context'

import { Maybe, useRemoveProductFromCartMutation, useUpdateProductQuantityMutation } from '@/graphql/generated'
import { GET_ALL_PRODUCT_FROM_CART } from '@/graphql/queries'

import { useLaptop } from '@/hooks'

import { RedRemoveIcon } from '@/icons'

import { getAmount } from '@/utils'

import * as S from './CartProductRow.styled'
import { CartProductQuantity } from '../CartProductQuantity/CartProductQuantity'
import { LoaderWrapper } from '../LoaderWrapper/LoaderWrapper'

// TODO: add product type instead of defining all the properties again
interface Props {
  imgSrc?: Maybe<string>
  name: string | DefaultTFuncReturn
  description: string | DefaultTFuncReturn | null
  pricePerUnit: number
  currency: Maybe<string>
  id: string
  quantity: number
  idForRemoveProductIfIsAuth?: string
  refetchingCartData: boolean
  setLoadingsUpdateQuantity: React.Dispatch<React.SetStateAction<number>>
}

export const CartProductRow = ({
  imgSrc,
  name,
  description,
  pricePerUnit,
  currency,
  id,
  quantity,
  refetchingCartData,
  setLoadingsUpdateQuantity,
}: Props) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { isOverLaptop } = useLaptop()
  const { onOpenAlert } = useAlertContext()
  const [updateProductQuantityMutation, { loading: loadingUpdateQuantity }] = useUpdateProductQuantityMutation()
  const [removeProductFromCartMutation, { loading: loadingRemoveProduct }] = useRemoveProductFromCartMutation()

  const handleRemove = (id: string) => {
    if (refetchingCartData) return

    removeProductFromCartMutation({
      variables: { skuId: id },
      refetchQueries: [GET_ALL_PRODUCT_FROM_CART],
      onCompleted: () => onOpenAlert({ subTitle: t`app.product_has_been_removed_from_you_cart` }),
    })
  }

  const handleUpdateQuantity = useCallback(
    (quantity: number, onError: () => void) =>
      updateProductQuantityMutation({
        variables: { skuId: id, input: { quantity } },
        onError,
      }),
    [updateProductQuantityMutation, id],
  )

  useEffect(() => {
    setLoadingsUpdateQuantity(count => {
      if (!loadingUpdateQuantity && count === 0) return count

      return loadingUpdateQuantity ? count + 1 : count - 1
    })
  }, [loadingUpdateQuantity, setLoadingsUpdateQuantity])

  const priceText = String(getAmount(pricePerUnit, quantity))

  return (
    <S.ProductRow>
      <S.ProductCell>
        <S.ProductImageBlock>
          {imgSrc && (
            <Image
              alt=""
              src={`${BASE_URL}/uploads/images/products/${imgSrc}`}
              width={1}
              height={1}
              priority={true}
              unoptimized={true}
            />
          )}
        </S.ProductImageBlock>
        <S.DescriptionBlock>
          <Typography variant="subtitle1">{name}</Typography>
          <S.DescriptionTypography color={theme.palette.ui.grey[600]} variant="body2">
            {description}
          </S.DescriptionTypography>
          {!isOverLaptop && (
            <Box mt="6px">
              <CartProductQuantity
                handleUpdateQuantity={handleUpdateQuantity}
                height={30}
                width={95}
                quantity={quantity}
                id={id}
              />
            </Box>
          )}
        </S.DescriptionBlock>
      </S.ProductCell>
      {!isOverLaptop && <Divider sx={{ width: '100%' }} />}
      {isOverLaptop ? (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography variant="body1">{Number(pricePerUnit).toFixed(2)} </Typography>
            <Typography variant="body1" sx={{ fontWeight: 300 }}>
              {currency}
            </Typography>
          </Box>
          <Box>
            <CartProductQuantity
              handleUpdateQuantity={handleUpdateQuantity}
              maxWidth={113}
              height={40}
              quantity={quantity}
              id={id}
            />
          </Box>
          <S.PriceWrapper>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography minWidth={24} variant="body1">
                {loadingUpdateQuantity ? <Skeleton width={`${priceText.length}ch`} variant="text" /> : priceText}
              </Typography>

              <Typography variant="body1" sx={{ fontWeight: 300 }} component="span">
                {currency}
              </Typography>
            </Box>
            <Divider orientation="vertical" sx={{ height: '30px' }} />
            <LoaderWrapper loading={loadingRemoveProduct} size={30}>
              <IconButton
                sx={{ width: 30, height: 30 }}
                onClick={() => handleRemove(id)}
                disabled={loadingRemoveProduct}
              >
                <RedRemoveIcon />
              </IconButton>
            </LoaderWrapper>
          </S.PriceWrapper>
        </>
      ) : (
        <S.MobileFooterCardWrapper>
          <S.MobileFooterCardBlock>
            <Typography variant="caption" color={theme.palette.ui.grey[600]}>
              {t('app.cart.price_per_unit')}
            </Typography>
            <Typography variant="body2">
              {pricePerUnit.toFixed(2)}
              <Typography variant="body2" sx={{ fontWeight: 300 }} component="span">
                {currency}
              </Typography>
            </Typography>
          </S.MobileFooterCardBlock>
          <S.MobileFooterCardBlock textAlign="end">
            <Typography variant="caption" color={theme.palette.ui.grey[600]}>
              {t('app.cart.price')}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography minWidth={24} variant="body1">
                {loadingUpdateQuantity ? <Skeleton width={`${priceText.length}ch`} variant="text" /> : priceText}
              </Typography>
              <Typography variant="body2" sx={{ fontWeight: 300 }} component="span">
                {currency}
              </Typography>
            </Box>
          </S.MobileFooterCardBlock>

          <Box sx={{ position: 'absolute', insetInlineEnd: '8px', top: '8px' }}>
            <LoaderWrapper loading={loadingRemoveProduct} size={30}>
              <IconButton onClick={() => handleRemove(id)} disabled={loadingRemoveProduct} size="small">
                <RedRemoveIcon />
              </IconButton>
            </LoaderWrapper>
          </Box>
        </S.MobileFooterCardWrapper>
      )}
    </S.ProductRow>
  )
}
