import { ExchangeRateEntity } from '@/graphql/generated'

import { CurrencyType } from '@/types/baseTypes'

type getTargetEntityType = (target: CurrencyType, source: CurrencyType, exchangeRates: ExchangeRateEntity[]) => number

export const newPriceWithExchangeRates = (
  price: any,
  baseCurrency: CurrencyType, // какую валюту конвертируем
  outCurrency: CurrencyType, // в какую конвертировать
  exchangeRates: ExchangeRateEntity[],
) => {
  // функия для поика ставки валюты
  const getTargetEntity: getTargetEntityType = (target, source, eRates) => {
    const targetObjectWithRate = eRates.find(exRate => exRate.target === target && exRate.source === source)
    return targetObjectWithRate ? targetObjectWithRate.rate : 0
  }

  // функия для расчета PRICE в зависимсти от того, есть или нет этого объекта в exchangeRates массиве
  const getFinishPrice = (price: number, rate: number, notAvailable = false): number => {
    if (notAvailable) {
      return +(price * (1 / rate)).toFixed(2)
    }
    return +(price * rate).toFixed(2)
  }

  if (baseCurrency === outCurrency) {
    return price
  }
  // * если входящая валюта AED
  if (baseCurrency === 'AED') {
    if (outCurrency === 'USD') {
      const rate = getTargetEntity('USD', 'AED', exchangeRates)
      return getFinishPrice(price, rate)
    }
    if (outCurrency === 'EUR') {
      const rate = getTargetEntity('EUR', 'AED', exchangeRates)
      return getFinishPrice(price, rate)
    }
  }
  // * если входящая валюта USD
  if (baseCurrency === 'USD') {
    if (outCurrency === 'EUR') {
      const rate = getTargetEntity('EUR', 'USD', exchangeRates)
      return getFinishPrice(price, rate)
    }
    if (outCurrency === 'AED') {
      const rate = getTargetEntity('USD', 'AED', exchangeRates)
      return getFinishPrice(price, rate, true)
    }
  }

  // * если входящая валюта EUR
  if (baseCurrency === 'EUR') {
    if (outCurrency === 'USD') {
      const rate = getTargetEntity('EUR', 'USD', exchangeRates)
      return getFinishPrice(price, rate, true)
    }
    if (outCurrency === 'AED') {
      const rate = getTargetEntity('EUR', 'AED', exchangeRates)
      return getFinishPrice(price, rate, true)
    }
  }
}
