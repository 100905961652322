import { Typography } from '@mui/material'
import { useTranslation } from 'next-i18next'
import React from 'react'

import { KycLevelBlock } from '@/components'

import { PATHS } from '@/constants/routes'

import { useAuthContext } from '@/context'

import { useLaptop } from '@/hooks'

import { GeneralProfileMobileIcon, LockIcon, LogoutIcon, MyOrdersMobileNavIcon } from '@/icons'

import * as S from './ProfileLayout.styled'

interface Props {
  children: React.ReactNode
}

export const ProfileLayout = ({ children }: Props) => {
  const { isOverLaptop } = useLaptop()
  const { onLogout } = useAuthContext()

  const { t } = useTranslation()

  return (
    <S.ProfileLayout>
      <S.Aside component="aside">
        {isOverLaptop && (
          <Typography variant="h4" mb="30px">
            {t('app.profile')}
          </Typography>
        )}
        <S.ProfileNavLink href={PATHS.profile}>
          <GeneralProfileMobileIcon /> {t('app.general')}
        </S.ProfileNavLink>
        <S.ProfileNavLink href={PATHS.profileMyOrders}>
          <MyOrdersMobileNavIcon /> {t('app.my_orders')}
        </S.ProfileNavLink>
        <S.ProfileNavLink href={PATHS.profilePassword}>
          <LockIcon /> {t('app.password')}
        </S.ProfileNavLink>
        {isOverLaptop && (
          <S.ProfileNavLink href={PATHS.home} onClick={() => onLogout}>
            <LogoutIcon size="24" /> {t('app.logout')}
          </S.ProfileNavLink>
        )}
        {isOverLaptop && <KycLevelBlock sx={{ marginTop: 'auto' }} />}
      </S.Aside>
      <S.Content>{children}</S.Content>
    </S.ProfileLayout>
  )
}
