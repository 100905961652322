import { CircularProgress } from '@mui/material'
import React from 'react'

import * as S from './LoaderWrapper.styles'

type Props = {
  loading?: boolean
  isDisableCirculate?: boolean
  children: React.ReactNode
  size?: number
}

export const LoaderWrapper = ({ loading = false, children, isDisableCirculate, size = 48 }: Props) => {
  return loading ? (
    <S.Center>{!isDisableCirculate ? <CircularProgress size={`${size}px`} /> : ''}</S.Center>
  ) : (
    <>{children}</>
  )
}
