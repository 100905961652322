import { useRouter } from 'next/router'
import React from 'react'

import { Footer, Header } from '@/components'

import { PATHS, pathIncludesLayout, pathIncludesProfileLayout } from '@/constants/routes'

import { useLaptop } from '@/hooks'

import * as S from './Layout.styled'

interface Props {
  children: React.ReactNode
}

export const MainLayout = ({ children }: Props) => {
  const { pathname } = useRouter()
  const isDisabledContainer = pathIncludesLayout.includes(pathname)
  const isProfilePage = pathIncludesProfileLayout.includes(pathname)
  const isCartPage = pathname === PATHS.cart

  const { isOverLaptop } = useLaptop()

  return (
    <S.MainLayout>
      <Header />
      <S.Container isdisabledcontainer={isDisabledContainer ? 'true' : 'false'}>{children}</S.Container>
      {!(isCartPage || (isProfilePage && !isOverLaptop)) ? <Footer /> : null}
    </S.MainLayout>
  )
}
