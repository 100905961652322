import { useTranslation } from 'next-i18next'
import React, { useEffect } from 'react'

import { RECAPTCHA_SITE_KEY } from '@/constants/env'

export const useCaptchaV3 = () => {
  const containerId = 'recaptcha-container'
  const scriptId = 'recaptcha-script'
  const { i18n } = useTranslation()

  const renderContainer = () => <div id={containerId} />

  const executeRender = () => window.grecaptcha.render(containerId, { sitekey: RECAPTCHA_SITE_KEY, size: 'invisible' })

  const getToken = () => window.grecaptcha.execute(RECAPTCHA_SITE_KEY, { action: 'login' })

  const handleCaptchaLoaded = () => window.grecaptcha.ready(executeRender)

  // Add reCaptcha
  useEffect(() => {
    let script: HTMLScriptElement | null

    script = document.getElementById(scriptId) as HTMLScriptElement | null

    if (script) {
      if (script.dataset.hf !== i18n.language) {
        script.remove()
      } else {
        try {
          executeRender()
        } catch {
          /* empty */
        }

        return // continue using current
      }
    }

    script = document.createElement('script')
    script.id = scriptId
    script.dataset.hf = i18n.language
    script.src = `https://www.google.com/recaptcha/api.js?trustedtypes=true&render=${RECAPTCHA_SITE_KEY}&hl=${i18n.language}`
    script.addEventListener('load', handleCaptchaLoaded)
    document.body.appendChild(script)

    return () => document.querySelector('.grecaptcha-badge')?.parentElement?.remove()
  }, [i18n.language])
  return { getToken, renderContainer }
}
