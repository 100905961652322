import * as process from 'process'

import { countriesDataTypes } from '@/types/optionsTypes'

import countries from './countries.json'

export const phoneNumber = ''

export const GOOGLE_AUTH_CLIENT_KEY = '94285690083-gdqj28vn2j0uvgld19ur9oj3kcphovic.apps.googleusercontent.com'

export const countriesData: countriesDataTypes[] = countries

export const arabianCountries = [
  'Egypt',
  'Algeria',
  'Sudan',
  'Iraq',
  'Morocco',
  'Saudi Arabia',
  'Yemen',
  'Syria',
  'Somalia',
  'Tunisia',
  'Jordan',
  'United Arab Emirates',
  'Lebanon',
  'Libya',
  'Palestine',
  'Oman',
  'Mauritania',
  'Kuwait',
  'Qatar',
  'Bahrain',
  'Djibouti',
  'Comoros',
]
