import { ApolloProvider } from '@apollo/client'
import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/system'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { appWithTranslation } from 'next-i18next'
import type { AppProps } from 'next/app'
import { Poppins } from 'next/font/google'
import React from 'react'

import { LayoutProvider, Meta } from '@/components'

import { GOOGLE_AUTH_CLIENT_KEY } from '@/constants/constants'

import { AlertProvider, AuthProvider, CartProvider, ModalProvider } from '@/context'
import { IntlProvider } from '@/context/IntlContext'
import { SiteIdProvider } from '@/context/SiteIdContext'

import { client } from '@/graphql/client'

import { theme } from '@/theme'

import '@/utils/i18n'

const font = Poppins({
  weight: ['100', '200', '300', '400', '500', '600', '700', '800', '900'],
  subsets: ['latin'],
  display: 'swap',
})

const App = ({ Component, pageProps }: AppProps) => (
  <>
    <style jsx global>{`
      html {
        font-family: ${font.style.fontFamily};
      }
    `}</style>

    <Meta {...pageProps}>
      <GoogleOAuthProvider clientId={GOOGLE_AUTH_CLIENT_KEY}>
        <ApolloProvider client={client}>
          <ThemeProvider theme={theme}>
            <CssBaseline enableColorScheme />
            <SiteIdProvider>
              <AuthProvider>
                <IntlProvider>
                  <CartProvider>
                    <ModalProvider>
                      <AlertProvider>
                        <LayoutProvider>
                          <Component {...pageProps} />
                        </LayoutProvider>
                      </AlertProvider>
                    </ModalProvider>
                  </CartProvider>
                </IntlProvider>
              </AuthProvider>
            </SiteIdProvider>
          </ThemeProvider>
        </ApolloProvider>
      </GoogleOAuthProvider>
    </Meta>
  </>
)

export default appWithTranslation(App)
