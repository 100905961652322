import { Box, Dialog as MuiDialog, styled } from '@mui/material'

export const Dialog = styled(MuiDialog)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    '.MuiPaper-root': {
      margin: 0,
      width: '100%',
      minWidth: '100vw',
      height: '100%',
      minHeight: '100vh',
      borderRadius: 0,
      paddingTop: '221px',
      '> .MuiBox-root:first-of-type': {
        padding: '0 45px',
      },
    },
  },
}))

export const FormWrapper = styled(Box)(() => ({
  display: 'flex',
  padding: '40px 50px 40px 50px',
}))

export const IconButtonWrapper = styled(Box)(({ theme }) => {
  const isIOS = /(iPhone|iPod|iPad)/i.test(navigator.userAgent)
  return {
    position: 'absolute',
    top: '20px',
    insetInlineEnd: '20px',
    zIndex: '13001',
    [theme.breakpoints.down('lg')]: {
      top: isIOS ? '80px' : '40px',
    },
  }
})
