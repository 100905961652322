import create from 'zustand'
import { immer } from 'zustand/middleware/immer'

export type useIsDisabledProductRowStoreProps = {
  dataIds: any[]
  setDataIds: (id: string) => void
  removeId: (id: string) => void
}

export const useIsDisabledProductRowStore = create<useIsDisabledProductRowStoreProps>()(
  immer((setState, getState) => ({
    dataIds: [],
    setDataIds: id => {
      if (!id) return
      if (getState().dataIds.some(i => i.id === id)) return
      setState(state => {
        state.dataIds = [...state.dataIds, { id: id }]
      })
    },
    removeId: id => {
      setState(state => {
        state.dataIds = state.dataIds.filter(i => i.id !== id)
      })
    },
  })),
)
