import {
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Typography,
  styled,
} from '@mui/material'

export const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    padding: '0 15px',
    gap: '10px',
    '& .MuiAccordionSummary-content': {
      margin: '12px',
    },
  },
}))

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    padding: '15px 15px 17px 15px',
  },
}))

export const TitleTypography = styled(Typography)(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}))

export const DescriptionTypography = styled(Typography)(({ theme }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontWeight: 300,
  fontSize: '15px',
  lineHeight: '22px',
  margin: '0',
  whiteSpace: 'break-spaces',
  [theme.breakpoints.down('lg')]: {
    fontSize: '14px',
    lineHeight: '21px',
  },
}))
