import { Box, BoxProps, styled } from '@mui/material'

interface Props extends BoxProps {
  haserror?: string
}

export const TextFieldFormik = styled(Box)<Props>(({ theme, haserror }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: haserror === 'true' ? '10px' : '24px',
    [theme.breakpoints.down('lg')]: {
      marginBottom: haserror === 'true' ? '10px' : '16px',
    },
  }
})

export const ErrorMessage = styled(Box)(({ theme }) => {
  return {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.ui.error.main,
    marginTop: '4px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
      lineHeight: '16px',
      marginTop: '5px',
    },
  }
})
