import { SvgIcon, SvgIconProps, useTheme } from '@mui/material'
import React from 'react'

import { IconsTypes } from '@/types/baseTypes'

export const Rotate24Icon = ({ ...props }: IconsTypes & SvgIconProps) => {
  const theme = useTheme()

  return (
    <SvgIcon width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 3.99976C12.9543 3.99976 4 12.9541 4 23.9998C4 28.7426 5.64964 33.0974 8.40728 36.5256C8.75344 36.9559 8.6852 37.5854 8.25486 37.9316C7.82452 38.2777 7.19505 38.2095 6.84889 37.7792C3.81583 34.0085 2 29.2148 2 23.9998C2 11.8495 11.8497 1.99976 24 1.99976C25.1415 1.99976 26.2632 2.08677 27.3589 2.2547C27.7422 2.31344 28.057 2.58848 28.1667 2.96044C28.2763 3.33239 28.161 3.73419 27.8708 3.99144L22.2716 8.95536C21.8584 9.32174 21.2264 9.28373 20.86 8.87046C20.4936 8.4572 20.5316 7.82517 20.9449 7.4588L24.8277 4.01655C24.5532 4.00539 24.2773 3.99976 24 3.99976ZM39.7668 10.0924C40.1976 9.7469 40.827 9.81612 41.1725 10.247C44.1927 14.0135 46 18.797 46 23.9998C46 36.15 36.1503 45.9998 24 45.9998C22.7614 45.9998 21.5459 45.8973 20.3619 45.7002C19.9908 45.6384 19.6858 45.3737 19.5725 45.015C19.4592 44.6563 19.5568 44.2644 19.825 44.0007L25.0485 38.865C25.4423 38.4778 26.0754 38.4831 26.4626 38.877C26.8498 39.2708 26.8445 39.9039 26.4506 40.2911L22.7196 43.9595C23.1429 43.9862 23.5698 43.9998 24 43.9998C35.0457 43.9998 44 35.0455 44 23.9998C44 19.2681 42.3581 14.9226 39.6122 11.4981C39.2667 11.0673 39.3359 10.4379 39.7668 10.0924Z"
        fill={theme.palette.ui.primary.main}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.4188 16.2415C21.6077 17.165 22.4018 18.5863 22.444 20.4438C22.5145 23.5428 20.4807 25.2083 18.5025 26.6942C18.343 26.814 18.1827 26.9334 18.022 27.0532C16.519 28.1731 14.9848 29.3163 13.7756 30.9998H22C22.5523 30.9998 23 31.4475 23 31.9998C23 32.552 22.5523 32.9998 22 32.9998H12.0022C11.6548 32.9998 11.3323 32.8195 11.1504 32.5235C10.9684 32.2276 10.9531 31.8584 11.1099 31.5484C12.6121 28.5783 14.925 26.8609 16.8006 25.4682C16.9715 25.3414 17.1387 25.2172 17.3013 25.095C19.3162 23.5816 20.4896 22.4734 20.4445 20.4892C20.4159 19.2279 19.9014 18.3721 19.1919 17.821C18.4567 17.25 17.4533 16.9647 16.4344 17.0032C15.4132 17.0417 14.4743 17.4012 13.8496 17.9933C13.2547 18.5574 12.8796 19.3891 13.0352 20.5757C13.107 21.1233 12.7212 21.6254 12.1737 21.6972C11.6261 21.769 11.1239 21.3833 11.0521 20.8357C10.8156 19.0314 11.4017 17.5581 12.4737 16.5419C13.5159 15.5538 14.9497 15.0578 16.359 15.0046C17.7705 14.9513 19.2555 15.3379 20.4188 16.2415Z"
        fill={theme.palette.ui.primary.main}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.9301 15.058C34.328 15.2002 34.5937 15.5772 34.5937 15.9997V26.7151H36C36.5523 26.7151 37 27.1628 37 27.7151C37 28.2674 36.5523 28.7151 36 28.7151H34.5937V31.9997C34.5937 32.552 34.146 32.9997 33.5937 32.9997C33.0414 32.9997 32.5937 32.552 32.5937 31.9997V28.7151H24C23.6135 28.7151 23.2617 28.4924 23.0962 28.1432C22.9308 27.7939 22.9815 27.3806 23.2263 27.0816L32.82 15.3662C33.0877 15.0392 33.5321 14.9159 33.9301 15.058ZM32.5937 26.7151V18.7993L26.1114 26.7151H32.5937Z"
        fill={theme.palette.ui.primary.main}
      />
    </SvgIcon>
  )
}
