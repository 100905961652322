import { Divider, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import React from 'react'

import { PATHS } from '@/constants/routes'

import { LogoImage } from '@/icons'

import * as S from './Footer.styled'

export const Footer = () => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <S.Footer>
      <S.TopPart>
        <S.LeftColumn>
          <LogoImage />
          <Typography
            component="p"
            variant="body1"
            sx={{
              mt: 2,
              mb: { xs: '16px', lg: '20px' },
              textAlign: { xs: 'center', lg: 'start' },
            }}
          >
            {t('app.footer.webmarket_today')}
          </Typography>
        </S.LeftColumn>
      </S.TopPart>
      <Divider />
      <S.BottomPart>
        <Typography variant="caption" sx={{ fontSize: { xs: '12px', lg: '14px' } }}>
          ©{new Date().getFullYear()} {t('app.footer.all_rights_reserved')}
        </Typography>
        <S.LinksRow>
          {[
            { href: PATHS.termsOfUse, label: 'Terms of Use' },
            { href: PATHS.privacyPolicy, label: 'Privacy Policy' },
            { href: PATHS.cookies, label: 'Cookies' },
            { href: PATHS.shippingPolicy, label: 'Shipping Policy' },
          ].map(({ href, label }) => (
            <Link key={href} href={href}>
              <Typography
                variant="caption"
                sx={{
                  fontSize: { xs: '12px', lg: '14px' },
                  color: { xs: theme.palette.ui.grey[700] },
                }}
              >
                {label}
              </Typography>
            </Link>
          ))}
        </S.LinksRow>
      </S.BottomPart>
    </S.Footer>
  )
}
