import { gql } from '@apollo/client'

export const GET_MY_ORDERS = gql`
  query GetMyOrders {
    getMyOrders {
      orderSkus {
        id
        upayIsDisplayed
        voucherCodes {
          orderSkuId
          id
          code
          orderSku {
            sku {
              product {
                termsEN
                termsAR
                nameEN
                nameAR
                id
                shortDescriptionEN
                shortDescriptionAR
                image
                createdAt
                updatedAt
              }
            }
            orderId
          }
        }
      }
      status
      createdAt
      updatedAt
      id
      paymentTransactionId
    }
  }
`
