import { ComponentsOverrides, ComponentsProps, ComponentsVariants } from '@mui/material'

import { palette } from '../palette'

export default {
  defaultProps: {
    variant: 'outlined',
    size: 'small',
  },
  variants: [
    {
      props: { size: 'small' },
      style: {
        height: '40px',
        borderRadius: '6px',
        overflow: 'hidden',
        border: `1px solid ${palette.ui.border.default}`,
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
        color: palette.ui.grey[700],
        fontWeight: 300,
        lineHeight: '22px',
        '& .MuiSelect-select': {
          // padding: '8px 10px',
        },
        '& .MuiBox-root': {
          display: 'flex',
          alignItems: 'center',
          // marginInlineEnd: '8px',
          // '& :first-of-type': {
          //   marginInlineEnd: '8px',
          // },
        },
        '& .MuiSelect-icon': {
          insetInlineEnd: '15px',
        },
      },
    },
    {
      props: { size: 'medium' },
      style: {
        '& .MuiSelect-select': {
          paddingTop: '12px',
          paddingBottom: '12px',
        },
        overflow: 'hidden',
        height: '48px',
        borderRadius: '6px',
        border: `1px solid ${palette.ui.border.default}`,
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
        color: palette.ui.grey[700],
        fontWeight: 300,
        lineHeight: '22px',
        '& .MuiSelect-icon': {
          insetInlineEnd: '20px',
          // insetInlineStart: '15px',
        },
      },
    },
    {
      props: { size: 'large' },
      style: {
        height: '56px',
        borderRadius: '6px',
        border: `1px solid ${palette.ui.border.default}`,
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
        color: palette.ui.grey[700],
        fontWeight: 300,
        lineHeight: '22px',
      },
    },
  ] as any,
  styleOverrides: {
    select: {
      '&.Mui-disabled': {
        background: palette.ui.grey[300],
      },
    },
  },
} as {
  defaultProps?: ComponentsProps['MuiSelect']
  styleOverrides?: ComponentsOverrides<unknown>['MuiSelect']
  variants?: ComponentsVariants['MuiSelect']
}
