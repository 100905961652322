import { ComponentsOverrides, ComponentsProps, ComponentsVariants } from '@mui/material'

import { palette } from '../palette'

export default {
  variants: [
    {
      props: { variant: 'text' },
      style: {
        // '.Mui-selected': {
        //   background: palette.ui.primary.main,
      },
    },
  ],
} as {
  defaultProps?: ComponentsProps['MuiPagination']
  styleOverrides?: ComponentsOverrides<unknown>['MuiPagination']
  variants?: ComponentsVariants['MuiPagination']
}
