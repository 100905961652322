import { ComponentsOverrides, ComponentsProps, ComponentsVariants } from '@mui/material'

import { palette } from '../palette'

export default {
  styleOverrides: {
    root: {
      padding: '24px 30px',
      borderTop: `1px solid ${palette.ui.grey[800]}`,
    },
  },
} as {
  defaultProps?: ComponentsProps['MuiAccordionDetails']
  styleOverrides?: ComponentsOverrides<unknown>['MuiAccordionDetails']
  variants?: ComponentsVariants['MuiAccordionDetails']
}
