import { gql } from '@apollo/client'

export const GET_CATEGORIES = gql`
  query GetCategories($input: GetCategoriesInput!) {
    getCategories(input: $input) {
      categories {
        id
        nameEN
        nameAR
        icon
        svgCode
        sorting
      }
      count
    }
  }
`
