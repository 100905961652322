import { SvgIcon } from '@mui/material'
import React from 'react'

import { palette } from '@/theme/palette'

export const HeartIcon = () => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.4134 4.58753C19.9121 4.08434 19.3164 3.68508 18.6606 3.41266C18.0047 3.14023 17.3015 3 16.5914 3C15.8812 3 15.178 3.14023 14.5222 3.41266C13.8663 3.68508 13.2707 4.08434 12.7694 4.58753L12 5.36717L11.2306 4.58753C10.7293 4.08434 10.1337 3.68508 9.47781 3.41266C8.82195 3.14023 8.11878 3 7.40863 3C6.69847 3 5.9953 3.14023 5.33944 3.41266C4.68358 3.68508 4.08793 4.08434 3.58665 4.58753C1.46832 6.70656 1.33842 10.2849 4.00631 13.0037L12 21L19.9937 13.0037C22.6616 10.2849 22.5317 6.70656 20.4134 4.58753Z"
        stroke={palette.ui.grey[600]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}
