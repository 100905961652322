import { Box, IconButton, Typography, styled } from '@mui/material'

import { Badge } from '@/components'

import placeholder from '@/icons/background/placeholder.png'

export const CardItem = styled(Box)(({ theme }) => ({
  // display: 'flex',
  // flexDirection: 'column',
  // flex: '0 1 calc(33.333% - 14px)',
  height: '226px',
  boxShadow: '0px 30px 48px -10px rgba(150, 160, 181, 0.2)',
  borderRadius: '8px',
  cursor: 'pointer',
  position: 'relative',
  [theme.breakpoints.down('lg')]: {
    display: 'flex',
    flex: '0 1 calc(33.333% - 14px)',
    boxShadow: '0px 8px 24px rgba(150, 160, 181, 0.2)',
    padding: '8px',
    flexDirection: 'row',
  },
}))

export const DescriptionBlock = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '10px 18px',
  height: '66px',
  justifyContent: 'space-between',
  [theme.breakpoints.down('lg')]: {
    padding: '0',
    height: 'auto',
    width: 'fit-content',
    margin: '10px 0',
    overflow: 'hidden',
  },
}))

export const ProductBlock = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundImage: `url(${placeholder.src})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  borderRadius: '8px 8px 0px 0px',
  height: '160px',
  overflow: 'hidden',
  position: 'relative',
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  [theme.breakpoints.down('lg')]: {
    borderRadius: '2px',
    width: '96px',
    minWidth: '96px',
    height: '62px',
    marginInlineEnd: '12px',
  },
}))

export const FavoriteButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  display: 'flex',
  insetInlineEnd: '11px',
  top: '9px',
  padding: '5px',
  zIndex: '100',
  width: 'fit-content',
  [theme.breakpoints.down('lg')]: {
    insetInlineStart: '5px',
    bottom: '-12px',
  },
}))

export const BadgeItem = styled(Badge)(({ theme }) => ({
  position: 'absolute',
  insetInlineStart: '18px',
  top: '18px',
  [theme.breakpoints.down('lg')]: {
    top: '8px',
    insetInlineStart: '8px',
    borderRadius: '2px',
  },
}))

export const DescriptionTypography = styled(Typography)(({ theme }) => ({
  display: '-webkit-box',
  WebkitLineClamp: 1,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  [theme.breakpoints.down('lg')]: {},
}))
