import { Box, styled } from '@mui/material'
import Link, { LinkProps } from 'next/link'
import { useRouter } from 'next/router'

export const Footer = styled(Box)(({ theme }) => ({
  marginTop: 'auto',
  background: theme.palette.ui.grey[300],
  display: 'flex',
  flexDirection: 'column',
}))

export const TopPart = styled(Box)(({ theme }) => {
  return {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '50px 20px 48px 20px',
    maxWidth: '1240px',
    alignSelf: 'center',
    width: '100%',
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'center',
      padding: '30px 24px 22px',
    },
  }
})
export const BottomPart = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '35px 20px 32px 20px',
  maxWidth: '1240px',
  alignSelf: 'center',
  width: '100%',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    alignItems: 'center',
    padding: '16px 0',
    gap: '4px',
  },
}))
export const LeftColumn = styled(Box)(({ theme }) => {
  return {
    width: '100%',
    maxWidth: '275px',
    [theme.breakpoints.down('lg')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  }
})

export const RightColumn = styled(Box)({
  display: 'flex',
})

export const ResourcesColumn = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  marginInlineEnd: '131px',
})
export const CompanyColumn = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
})
export const LinksRow = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '20px',
  a: {
    textDecoration: 'none',
  },
})

export const CustomLink = styled(Link)<LinkProps>(({ theme, href }) => {
  const { pathname } = useRouter()
  return {
    textDecoration: 'none',
    span: {
      color: pathname === href ? theme.palette.ui.primary.main : '',
    },
  }
})
