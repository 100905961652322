import SvgIcon from '@mui/material/SvgIcon'
import React from 'react'

import { palette } from '@/theme/palette'

export const CardDivider = () => {
  return (
    <SvgIcon width="515" height="2" viewBox="0 0 515 2" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.5" d="M0 1H515" stroke={palette.ui.grey[600]} strokeWidth="2" strokeDasharray="4 4" />
    </SvgIcon>
  )
}
