import { SvgIcon, useTheme } from '@mui/material'
import React from 'react'

import { IconsTypes } from '@/types/baseTypes'

const FavoriteIcon = ({ size = '28' }: IconsTypes) => {
  const theme = useTheme()

  return (
    <SvgIcon width={size} height={size} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.2" cx="14" cy="14" r="14" fill={theme.palette.ui.grey[700]} />
      <g clipPath="url(#clip0_1080_10728)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.72812 8.0624C10.2326 7.85294 10.7734 7.74512 11.3196 7.74512C11.8658 7.74512 12.4066 7.85294 12.911 8.0624C13.3068 8.22671 13.674 8.45103 14 8.72686C14.326 8.45103 14.6932 8.22671 15.089 8.0624C15.5934 7.85294 16.1342 7.74512 16.6804 7.74512C17.2266 7.74512 17.7674 7.85294 18.2719 8.0624C18.7763 8.27186 19.2345 8.57883 19.62 8.96572L18.9117 9.6716L19.6188 8.96449C21.2811 10.6268 21.2837 13.3447 19.3803 15.2838L19.3738 15.2904L14.7071 19.957C14.5196 20.1446 14.2652 20.2499 14 20.2499C13.7348 20.2499 13.4804 20.1446 13.2929 19.957L8.61967 15.2838C6.71646 13.345 6.71893 10.6275 8.38061 8.96511C8.76604 8.57851 9.22395 8.27174 9.72812 8.0624ZM12.841 10.376C12.6416 10.1762 12.4048 10.0177 12.1441 9.90949C11.8827 9.80098 11.6026 9.74512 11.3196 9.74512C11.0366 9.74512 10.7564 9.80098 10.4951 9.90949C10.2338 10.018 9.99641 10.177 9.79667 10.3775L9.79544 10.3787C8.98509 11.1891 8.83566 12.646 10.0439 13.8796L14 17.8357L17.9561 13.8796C19.1643 12.646 19.0149 11.1891 18.2046 10.3787L18.2033 10.3775C18.0036 10.177 17.7662 10.018 17.5049 9.90949C17.2436 9.80098 16.9634 9.74512 16.6804 9.74512C16.3974 9.74512 16.1173 9.80098 15.8559 9.90949C15.5952 10.0177 15.3584 10.1763 15.159 10.376L14.7117 10.8291C14.5238 11.0195 14.2675 11.1266 14 11.1266C13.7325 11.1266 13.4762 11.0195 13.2883 10.8291L12.841 10.376Z"
          fill="white"
          fillOpacity="0.7"
        />
      </g>
      <defs>
        <clipPath id="clip0_1080_10728">
          <rect width="14" height="14" fill="white" transform="translate(7 7)" />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}

export default FavoriteIcon
