import { Typography, useMediaQuery, useTheme } from '@mui/material'
import { useTranslation } from 'next-i18next'
import React from 'react'

import { Select } from '@/components/uiKit'

import { useSiteIdContext } from '@/context/SiteIdContext'

import { CoinsIcon, LogoImage } from '@/icons'
import { GlobeIcon } from '@/icons'

import * as S from './NavigationComponent.styled'

type NavigationComponentT = {
  currentLang?: any
  setCurrentLang?: any
  currentCurrency?: any
  setCurrentCurrency?: any
  optionLang?: any
  optionCoins?: any
}

export const NavigationComponent = ({
  // currentLang,
  // setCurrentLang,
  currentCurrency,
  setCurrentCurrency,
  // optionLang,
  optionCoins,
}: NavigationComponentT) => {
  const theme = useTheme()
  const isOverLaptop = useMediaQuery(theme.breakpoints.up('lg'))
  const { t } = useTranslation()
  const { isDefaultWallet } = useSiteIdContext()

  return (
    <>
      <S.NavigationBlock component="nav" role="navigation">
        {!isOverLaptop && (
          <>
            <LogoImage width="70" style={{ marginBottom: '36px' }} />
            <Typography variant="caption" color="text.secondary" letterSpacing="0.06em" mb="9px">
              {t('app.menu')}
            </Typography>
          </>
        )}
        <S.NavLink href="/">{t('app.buy_gift_cards')}</S.NavLink>
        {!isOverLaptop && (
          <>
            {
              isDefaultWallet && ''
              // <Select
              //   sx={{ mt: '10px', mb: '20px' }}
              //   option={optionLang}
              //   icon={<GlobeIcon />}
              //   value={currentLang}
              //   setValue={setCurrentLang}
              // />
            }
            {/* <Select option={optionCoins} icon={<CoinsIcon />} value={currentCurrency} setValue={setCurrentCurrency} /> */}
          </>
        )}
      </S.NavigationBlock>
    </>
  )
}
