import { Box, styled } from '@mui/material'

import { AlertType } from '@/types/baseTypes'

type Props = {
  type: AlertType
}

// TODO: change getter functions to config objects

export const Alert = styled(Box)<Props>(({ theme, type }) => {
  const getColor = (type: string) => {
    switch (type) {
      case 'error':
        return theme.palette.error.main
      case 'information':
        return theme.palette.ui.information.main
      case 'success':
        return theme.palette.ui.success.main
      default:
        return theme.palette.ui.grey[600]
    }
  }
  const getBackground = (type: string) => {
    switch (type) {
      case 'error':
        return theme.palette.ui.error.light
      case 'information':
        return theme.palette.ui.information.light
      case 'success':
        return theme.palette.ui.success.light
      default:
        return theme.palette.ui.grey[300]
    }
  }
  const getBorderColor = (type: string) => {
    switch (type) {
      case 'error':
        return theme.palette.ui.error.main
      case 'information':
        return theme.palette.ui.information.main
      case 'success':
        return theme.palette.ui.success.main
      default:
        return theme.palette.ui.grey[200]
    }
  }

  return {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '6px',
    background: getBackground(type),
    border: '1px solid',
    borderColor: getBorderColor(type),
    color: getColor(type),
    fontWeight: 300,
    fontSize: '15px',
    lineHeight: '22px',
    padding: '12px 20px',
    [theme?.breakpoints.down('lg')]: {
      padding: '8.5px 12px',
      fontSize: '14px',
      lineHeight: '21px',
    },
  }
})
