import { SvgIcon, SvgIconProps, useTheme } from '@mui/material'
import React from 'react'

import { IconsTypes } from '@/types/baseTypes'

export const ShieldIcon = ({ ...props }: IconsTypes & SvgIconProps) => {
  const theme = useTheme()

  return (
    <SvgIcon width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.9617 4.31994C20.5156 4.15564 20.0256 4.15562 19.5795 4.31987L5.30897 9.57414C4.52251 9.86371 4 10.6129 4 11.451L4 23.8692C4 36.1031 13.1205 41.2997 19.648 43.7158C20.051 43.8649 20.4909 43.8647 20.8952 43.715C24.5365 42.367 29.0107 40.1353 32.2346 36.3005C32.5899 35.8778 33.2208 35.8232 33.6435 36.1786C34.0662 36.534 34.1208 37.1648 33.7654 37.5875C30.2169 41.8086 25.366 44.1925 21.5896 45.5906C20.7386 45.9057 19.8061 45.9069 18.9537 45.5914C12.2298 43.1026 2 37.4285 2 23.8692L2 11.451C2 9.77483 3.04503 8.27645 4.61794 7.69732L18.8884 2.44305C19.7807 2.11454 20.7607 2.11459 21.6529 2.44318L33.1657 6.68331C33.6839 6.87418 33.9493 7.44904 33.7584 7.96729C33.5676 8.48555 32.9927 8.75094 32.4745 8.56007L20.9617 4.31994Z"
        fill={theme.palette.ui.primary.main}
      />
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.2585 3.99976C41.3205 3.99976 40.5169 4.78696 40.5169 5.81192C40.5169 6.83688 41.3205 7.62408 42.2585 7.62408C43.1965 7.62408 44 6.83688 44 5.81192C44 4.78696 43.1965 3.99976 42.2585 3.99976ZM38.5169 5.81192C38.5169 3.73065 40.1682 1.99976 42.2585 1.99976C44.3487 1.99976 46 3.73065 46 5.81192C46 7.89319 44.3487 9.62408 42.2585 9.62408C41.8256 9.62408 41.4115 9.54985 41.0271 9.41364L34.8594 18.5589C34.6735 18.8345 34.3628 18.9998 34.0303 18.9998H24C23.4477 18.9998 23 18.552 23 17.9998C23 17.4475 23.4477 16.9998 24 16.9998H33.4986L39.3918 8.26147C38.8442 7.5956 38.5169 6.73848 38.5169 5.81192Z"
        fill={theme.palette.ui.primary.main}
      />
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.2585 43.9998C41.3205 43.9998 40.5169 43.2126 40.5169 42.1876C40.5169 41.1626 41.3205 40.3754 42.2585 40.3754C43.1965 40.3754 44 41.1626 44 42.1876C44 43.2126 43.1965 43.9998 42.2585 43.9998ZM38.5169 42.1876C38.5169 44.2689 40.1682 45.9998 42.2585 45.9998C44.3487 45.9998 46 44.2689 46 42.1876C46 40.1063 44.3487 38.3754 42.2585 38.3754C41.8256 38.3754 41.4115 38.4497 41.0271 38.5859L34.8594 29.4406C34.6735 29.165 34.3628 28.9998 34.0303 28.9998H24C23.4477 28.9998 23 29.4475 23 29.9998C23 30.552 23.4477 30.9998 24 30.9998H33.4986L39.3918 39.738C38.8442 40.4039 38.5169 41.261 38.5169 42.1876Z"
        fill={theme.palette.ui.primary.main}
      />
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.2585 25.9998C41.3795 25.9998 40.5169 25.191 40.5169 23.9998C40.5169 22.8085 41.3795 21.9998 42.2585 21.9998C43.1374 21.9998 44 22.8085 44 23.9998C44 25.191 43.1374 25.9998 42.2585 25.9998ZM38.6389 25.0092C39.0498 26.6834 40.4589 27.9998 42.2585 27.9998C44.4077 27.9998 46 26.1222 46 23.9998C46 21.8773 44.4077 19.9998 42.2585 19.9998C40.4521 19.9998 39.0392 21.326 38.6343 23.0092H24C23.4477 23.0092 23 23.4569 23 24.0092C23 24.5615 23.4477 25.0092 24 25.0092H38.6389Z"
        fill={theme.palette.ui.primary.main}
      />
    </SvgIcon>
  )
}
