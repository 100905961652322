import { FetchResult } from '@apollo/client'
import { BoxProps } from '@mui/material/Box'
import React, { useEffect, useRef, useState } from 'react'

import { UpdateProductQuantityMutation } from '@/graphql/generated'

import { useDebouncedValue } from '@/hooks/useDebouncedValue'

import { MinusIcon, PlusIcon } from '@/icons'

import * as S from './CartProductQuantity.styled'

interface Props extends BoxProps {
  quantity: number
  id: string
  handleUpdateQuantity: (quantity: number, onError: () => void) => Promise<FetchResult<UpdateProductQuantityMutation>>
}

export const CartProductQuantity = ({ quantity, id, handleUpdateQuantity, ...props }: Props) => {
  const [localQuantity, setLocalQuantity] = useState(quantity)

  const debouncedQuantity = useDebouncedValue(localQuantity, 500)

  const prevIdRef = useRef<string | null>(null)

  const handleCount = (action: 'add' | 'subtract') => setLocalQuantity(prev => (action === 'add' ? prev + 1 : prev - 1))

  useEffect(() => {
    if (prevIdRef.current && prevIdRef.current !== id) {
      setLocalQuantity(quantity)
      prevIdRef.current = id
      return
    }

    prevIdRef.current = id

    if (debouncedQuantity === quantity) return

    handleUpdateQuantity(debouncedQuantity, () => setLocalQuantity(quantity))
  }, [debouncedQuantity, quantity, id, handleUpdateQuantity])

  return (
    <S.AmountBtnGroup {...props}>
      <S.AmountBtn disabled={localQuantity === 1} onClick={() => handleCount('subtract')}>
        <MinusIcon />
      </S.AmountBtn>
      <S.AmountCountField>{localQuantity}</S.AmountCountField>
      <S.AmountBtn onClick={() => handleCount('add')}>
        <PlusIcon />
      </S.AmountBtn>
    </S.AmountBtnGroup>
  )
}
