import { useRouter } from 'next/router'
import React from 'react'

import { CardItem, NotFoundProduct } from '@/components'
import { LoaderWrapper } from '@/components/LoaderWrapper/LoaderWrapper'

import { ProductsWithCountSchema } from '@/graphql/generated'

import * as S from './Catalog.styled'

interface Props {
  data: ProductsWithCountSchema
  loading: boolean
}

export const Catalog = ({ data, loading = true }: Props) => {
  const router = useRouter()

  return (
    <LoaderWrapper loading={loading}>
      {data?.products?.length ? (
        <S.CardsBlock component="section">
          {data.products.map((item, i) => (
            <CardItem
              key={i}
              data={item}
              onClick={(value: string) => router.push(`/product/${value}`)}
              favoriteClick={(e: React.MouseEvent) => e.stopPropagation()}
              badge={false}
              imgToken={item.image}
            />
          ))}
        </S.CardsBlock>
      ) : (
        <S.Center>
          <NotFoundProduct />
        </S.Center>
      )}
    </LoaderWrapper>
  )
}
