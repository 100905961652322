import Box from '@mui/material/Box'
import { useTranslation } from 'next-i18next'
import React from 'react'

import * as S from '@/components/Form/SignInForm.styled'

import { useModalContext } from '@/context'

import { DoneIcon } from '@/icons'

import { FormConfirmHeader } from '../FormConfirmHeader/FormConfirmHeader'

export const DonePhoneForm = () => {
  const { onCloseModal } = useModalContext()

  const { t } = useTranslation()

  const handleOk = () => {
    onCloseModal()
  }

  return (
    <S.FormWrapper>
      <Box textAlign="center" mb="24px">
        <DoneIcon />
      </Box>
      <FormConfirmHeader
        sxSubTitle={{
          padding: 0,
          marginBottom: '24px',
        }}
        title={t('app.profilePage.thanks')}
        subTitle={t('app.profilePage.number_success')}
      />
      <S.AuthCodeWrapper>
        <S.ButtonFW
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            textTransform: 'uppercase',
            minWidth: '141px',
          }}
          onClick={handleOk}
          size="medium"
        >
          {t('app.ok')}
        </S.ButtonFW>
      </S.AuthCodeWrapper>
    </S.FormWrapper>
  )
}
