import { Typography, useMediaQuery, useTheme } from '@mui/material'
import { useTranslation } from 'next-i18next'
import React from 'react'

import { NotFoundProductIcon } from '@/icons'

import * as S from './NotFoundProduct.styled'

export const NotFoundProduct = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  return (
    <S.NotFoundProduct>
      <NotFoundProductIcon fontSize="large" style={{ marginBottom: '12px' }} />
      <Typography variant="h4" mb="4px">
        {t('app.not_found_product.no_results')}
      </Typography>
      <Typography variant="body2" color={theme.palette.ui.grey[600]} mb="5px">
        {t('app.not_found_product.search_queries')}
      </Typography>
      <Typography variant="body2" color={theme.palette.ui.primary.main}>
        {t('app.not_found_product.x_s_p')}
      </Typography>
    </S.NotFoundProduct>
  )
}
