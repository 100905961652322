import SvgIcon from '@mui/material/SvgIcon'
import React from 'react'

export const ArrowIcon = () => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4697 7.46967C14.7626 7.17678 15.2374 7.17678 15.5303 7.46967L19.5303 11.4697C19.8232 11.7626 19.8232 12.2374 19.5303 12.5303L15.5303 16.5303C15.2374 16.8232 14.7626 16.8232 14.4697 16.5303C14.1768 16.2374 14.1768 15.7626 14.4697 15.4697L17.1893 12.75H5C4.58579 12.75 4.25 12.4142 4.25 12C4.25 11.5858 4.58579 11.25 5 11.25H17.1893L14.4697 8.53033C14.1768 8.23744 14.1768 7.76256 14.4697 7.46967Z"
        fill="white"
      />
    </SvgIcon>
  )
}
