import { useEffect, useState } from 'react'

export const useDebounce = (value: string, delay = 400) => {
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(() => {
    const delayCallback = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)
    return () => clearTimeout(delayCallback)
  }, [delay, value])
  return debouncedValue
}
