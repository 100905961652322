import { useRouter } from 'next/router'
import React, { useContext, useEffect, useState } from 'react'

import { PATHS } from '@/constants/routes'

import { useErrorsStore } from '@/graphql/client'

import { hasAuthToken, removeAuthToken } from '@/utils/authToken'

interface Props {
  children: React.ReactNode
}

export const AuthContext = React.createContext({
  isAuth: false,
  onAuth: () => {},
  onLogout: () => {},
})

export const useAuthContext = () => {
  const authContext = useContext(AuthContext)

  if (!authContext) {
    throw new Error('useAuthContext must be used within a AuthProvider')
  }

  return authContext
}

export const AuthProvider = ({ children }: Props) => {
  const router = useRouter()
  const [isAuth, setIsAuth] = useState(false)

  const error = useErrorsStore()

  const handleLogout = () => {
    removeAuthToken()
    setIsAuth(false)
    router.push(PATHS.home)
  }

  useEffect(() => {
    if (error && error?.error?.extensions?.code === 'UNAUTHENTICATED') return handleLogout()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, error?.error?.extensions?.code])

  useEffect(() => {
    if (isAuth) return

    setIsAuth(hasAuthToken())
  }, [isAuth])

  const value = {
    isAuth,
    onAuth: () => setIsAuth(true),
    onLogout: handleLogout,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
