import { Box, styled } from '@mui/material'

export const BadgeWrapper = styled(Box)(({ theme }) => ({
  border: `3px solid ${theme.palette.base.white}`,
  display: 'flex',
  position: 'absolute',
  left: '12px',
  top: '-12px',
  borderRadius: '4px',
}))

export const Badge = styled(Box)(({ theme }) => ({
  background: theme.palette.ui.primary.main,
  color: theme.palette.base.white,
  borderRadius: '4px',
  height: '18px',
  minWidth: '18px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: 500,
  fontSize: '10px',
  lineHeight: '13px',
}))
