import { Box, styled } from '@mui/material'

export const Badge = styled(Box)(({ theme }) => ({
  width: '37px',
  height: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 6px',
  textTransform: 'uppercase',
  background: theme.palette.ui.error.main,
  boxShadow: `4px 8px 16px ${theme.palette.ui.error.light}`,
  borderRadius: '6px',
  fontWeight: 500,
  fontSize: '10px',
  lineHeight: '12px',
  color: theme.palette.base.white,
}))
