import { Box, Button, Typography, useTheme } from '@mui/material'
import { Form, Formik } from 'formik'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import React, { useState } from 'react'

import { Alert, CircularProgress } from '@/components'
import { ButtonFW, FormWrapper, LinkTypography } from '@/components/Form/SignInForm.styled'
import { CountrySelectFormik } from '@/components/uiKit'
import { CheckboxFormik } from '@/components/uiKit/CheckBoxFormik/CheckboxFormik'
import { TextFieldFormik } from '@/components/uiKit/TextFieldFormik/TextFieldFormik'

import { countriesData } from '@/constants/constants'
import { MODAL_NAME } from '@/constants/modalName'
import { AUTH_PATHS, PATHS } from '@/constants/routes'

import { useCartContext, useModalContext } from '@/context'
import { useSiteIdContext } from '@/context/SiteIdContext'

import { UserLanguagesEnum, useSignUpMutation } from '@/graphql/generated'

import { useLaptop } from '@/hooks'
import { useCaptchaV3 } from '@/hooks/useCaptchaV3'
import { useCurrentLanguage } from '@/hooks/useCurrentLanguage'

import { ArrowBackIcon, ArrowIcon } from '@/icons'

import { useAuthVariablesStore } from '@/store'

import { ErrorMessage } from '@/types/baseTypes'

import { getErrorName } from '@/utils'

import { signUpSchema } from '@/validation/signUp.schema'

import { FormHeader } from '../FormHeader/FormHeader'

export const SignUpForm = () => {
  const { isOverLaptop } = useLaptop()
  const { openModal, onOpenModal, onCloseModal } = useModalContext()
  const { t } = useTranslation()
  const { isENLang } = useCurrentLanguage()
  const { siteId } = useSiteIdContext()
  const router = useRouter()
  const captcha = useCaptchaV3()

  const [setEmail] = useAuthVariablesStore(state => [state.setEmail])

  const [signUpMutation, { data, error }] = useSignUpMutation()

  const [isSubmitting, setSubmitting] = useState(false)

  const theme = useTheme()

  const handleSubmit = async (values: any) => {
    if (data || isSubmitting) {
      return
    }
    setSubmitting(true)

    const captchaToken = await captcha.getToken()

    signUpMutation({
      variables: {
        input: {
          email: values.email,
          password: values.password,
          country: values.countrySelect,
          captcha: captchaToken,
          language: isENLang ? UserLanguagesEnum.En : UserLanguagesEnum.Ar,
          siteId: siteId,
        },
      },
      onCompleted: () => {
        setSubmitting(false)
        setEmail(values.email)

        if (openModal) {
          onCloseModal()
        }

        if (!isOverLaptop) {
          router.push(AUTH_PATHS.confirmEmail)
        } else {
          onOpenModal(MODAL_NAME.confirmEmail)
        }
      },
      onError: () => setSubmitting(false),
    })
  }

  const handleNavigateToSignInPage = () => {
    if (openModal) {
      onCloseModal()
    }
    if (!isOverLaptop) {
      router.push('/sign-in')
    } else {
      onOpenModal(MODAL_NAME.signIn)
    }
  }

  const goBack = () => {
    if (router.query?.from) {
      router.back()
    } else {
      router.push(PATHS.home)
    }
  }

  const getErrorMessage = () => {
    const exception = error?.graphQLErrors[0]?.extensions?.exception as ErrorMessage | undefined

    if (exception?.name) {
      return getErrorName(exception.name)
    }

    if (error?.message) {
      return error.message
    }

    return getErrorName('')
  }

  return (
    <FormWrapper>
      {isOverLaptop && !openModal?.open && (
        <Button
          sx={{ marginBottom: '40px' }}
          startIcon={<ArrowBackIcon />}
          variant="back"
          size="medium"
          onClick={goBack}
        >
          {t('app.back')}
        </Button>
      )}
      <FormHeader title={t('app.sign_up')} subTitle={t('app.auth.please_enter_your_details')} />

      <Formik
        initialValues={{
          email: '',
          password: '',
          repeatPassword: '',
          countrySelect: '',
          confirm: false,
        }}
        validationSchema={signUpSchema(isENLang)}
        onSubmit={handleSubmit}
      >
        {() => {
          return (
            <Form>
              <TextFieldFormik
                name="email"
                label={t('app.auth.email_address')}
                placeholder={t('app.auth.ey_email_address')}
              />
              <TextFieldFormik
                name="password"
                label={t('app.auth.password')}
                placeholder={t('app.auth.ey_password')}
                type="password"
              />
              <TextFieldFormik
                name="repeatPassword"
                label={t('app.auth.repeat_password')}
                placeholder={t('app.auth.ey_password')}
                type="password"
              />
              <CountrySelectFormik options={countriesData} name="countrySelect" label="Country" />

              <Box
                sx={{
                  display: 'flex',
                  paddingTop: '8px',
                }}
              >
                <CheckboxFormik
                  name="confirm"
                  label={
                    <>
                      {t('app.auth.i_declare_agree')}
                      <LinkTypography as="span" color={theme.palette.ui.primary.main}>
                        <a
                          href={`/${isENLang ? 'en' : 'ar'}${PATHS.privacyPolicy}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          &nbsp;{t('app.auth.terms_of_use')}
                        </a>
                      </LinkTypography>
                    </>
                  }
                />
              </Box>

              {captcha.renderContainer()}

              <ButtonFW
                type="submit"
                endIcon={
                  isSubmitting ? <CircularProgress loading={true} color={theme.palette.base.white} /> : <ArrowIcon />
                }
                size="medium"
              >
                {t('app.sign_up')}
              </ButtonFW>
            </Form>
          )
        }}
      </Formik>

      {error ? (
        <Alert type="error" mt="20px">
          {getErrorMessage()}
        </Alert>
      ) : null}

      <Typography mt="24px" variant="body2">
        {t('app.auth.already_have_an_account')}?{' '}
        <LinkTypography as="span" color={theme.palette.ui.primary.main} onClick={handleNavigateToSignInPage}>
          {t('app.sign_in')}
        </LinkTypography>
      </Typography>
    </FormWrapper>
  )
}
