import { BreakpointsOptions } from '@mui/system'

// TODO: Get rid of custom breakpoints, assign custom values to the default

export const breakpoints: BreakpointsOptions = {
  values: {
    xs: 0,
    sm: 460, // mobile
    md: 640, // tablet
    lg: 1100, // laptop
    xl: 1440, // desktop
  },
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true
    sm: true
    md: true
    lg: true
    xl: true
  }
}
