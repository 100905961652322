import SvgIcon from '@mui/material/SvgIcon'
import React from 'react'

import { palette } from '@/theme/palette'

export const NoCheckedCheckBoxIcon = () => {
  return (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="20" rx="6" fill="white" />
      <rect
        fill="none"
        x="0.5"
        y="0.5"
        width="19"
        height="19"
        rx="5.5"
        stroke={palette.ui.grey[600]}
        strokeOpacity="0.4"
      />
    </SvgIcon>
  )
}
