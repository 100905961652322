import { Box, BoxProps, styled } from '@mui/material'

import { AliasBoolTypes } from '@/types/baseTypes'

interface Props extends BoxProps {
  haserror: AliasBoolTypes
}

export const CheckboxFormik = styled(Box)<Props>(({ theme, haserror }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '24px',
    svg: {
      border: haserror === 'true' && '1px solid red',
      borderRadius: '6px',
    },
    p: {
      fontWeight: 300,
      fontSize: '15px',
      lineHeight: '19px',
    },
    [theme.breakpoints.up('lg')]: {
      p: {
        fontSize: '14px',
      },
    },
  }
})

export const ErrorMessage = styled(Box)(({ theme }) => {
  return {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.ui.error.main,
    marginTop: '4px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
      lineHeight: '16px',
      marginTop: '5px',
    },
  }
})
