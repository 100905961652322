import { CartProductSchema } from '@/graphql/generated'

export const getDataCartToCreateFiatOrder = (productData: CartProductSchema[]) =>
  productData.map(i => {
    return {
      productId: +i.product.id,
      quantity: i.quantity,
      skuId: +i.sku.id,
    }
  })
