import { ComponentsOverrides, ComponentsProps, ComponentsVariants } from '@mui/material'

import { palette } from '../palette'

export default {
  defaultProps: {
    variant: 'contained',
    size: 'large',
  },
  variants: [
    {
      props: {
        variant: 'contained',
      },
      style: {
        position: 'relative',
        // background: palette.ui.primary.main,
        padding: '8px 20px',
        gap: '4px',
        boxShadow: '0px 8px 16px rgba(70, 99, 244, 0.15)',
        color: palette.base.white,
        ':hover': {
          // background: palette.ui.primary.dark,
        },
        '&:disabled': {
          backgroundColor: palette.ui.grey[600],
          opacity: 0.6,
          color: palette.base.white,
        },
      },
    },
    {
      props: {
        variant: 'outlined',
      },
      style: {
        position: 'relative',
        border: `1px solid ${palette.ui.grey[600]}`,
        color: palette.ui.primary.main,
        fontWeight: 300,
        ':hover': {
          borderColor: palette.ui.primary.main,
        },
      },
    },
    {
      props: {
        variant: 'back',
      },
      style: {
        position: 'relative',
        border: `1px solid ${palette.ui.border.default}`,
        color: palette.ui.grey[700],
        fontWeight: 300,
        ':hover': {
          borderColor: palette.ui.border.default,
        },
      },
    },
    {
      props: {
        variant: 'tab',
      },
      style: {
        position: 'relative',
        border: 'none',
        background: palette.base.white,
        color: palette.ui.grey[700],
        fontWeight: 300,
        fontSize: '13px',
        lineHeight: '20px',
        ':hover': {
          background: palette.base.white,
        },
      },
    },
  ],
  styleOverrides: {
    root: {
      '&:disabled': {
        cursor: 'not-allowed',
        pointerEvents: 'auto',
      },
      textTransform: 'none',
      fontSize: '15px',
      lineHeight: '22px',
      borderRadius: '6px',
      whiteSpace: 'nowrap',
      width: 'fit-content',
      '.MuiButton-endIcon': {
        marginLeft: 0,
        marginInlineStart: '14px',
      },
      '.MuiButton-startIcon': {
        marginRight: 0,
        marginInlineEnd: '8px',
      },
      svg: {
        pointerEvents: 'none',
      },
    },
    sizeLarge: {
      height: '48px',
      fontWeight: 500,
    },
    sizeMedium: {
      height: '40px',
      fontWeight: 400,
    },
  },
} as {
  defaultProps?: ComponentsProps['MuiButton']
  styleOverrides?: ComponentsOverrides<unknown>['MuiButton']
  variants?: ComponentsVariants['MuiButton']
}
